export enum ProductTypes {
  SurveysWall = 0,
  NativeSurveys = 1,
  SupplierApi = 2,
  Adhoc = 3,
}

export enum DropGender {
  male = 1,
  female = 2,
}

export enum BrokenSurveyReason {
  INAPPROPRIATE_QUESTIONS = 0,
  LONG_SURVEY = 1,
  BROKEN_SURVEY = 2,
  BAD_MOOD = 3,
  OTHER = 4,
}
