import { useEffect } from "react";
import * as c from "./QrModal.module.css";
import Button from "./Button";

interface OfferModalProps {
  show: boolean;
  onClose: () => void;
  qrCode: string;
}

const QrModal = ({ show, onClose, qrCode }: OfferModalProps) => {
  useEffect(() => {
    if (show) {
      document.documentElement.classList.add(c["no-scroll"]);
    } else {
      document.documentElement.classList.remove(c["no-scroll"]);
    }
  }, [show]);

  if (!show) return null;

  return (
    <div className={c["modal-overlay"]}>
      <div className={c.modal}>
        <span>Scan with your phone's camera to get started!</span>
        <div className={c["qr-code-container"]}>
          <div className={c["qr-code-outer-wrapper"]}>
            <div className={c["qr-code-inner"]}>
              <img src={qrCode} alt="QR Code" />
            </div>
          </div>
        </div>
        <Button text={"Back to Offer"} onClick={onClose} disabled={true} isLoading={false} />
      </div>
    </div>
  );
};

export default QrModal;
