import { requestToken } from "api/auth";
import axios, { AxiosError, AxiosRequestConfig, InternalAxiosRequestConfig } from "axios";
import axiosRetry from "axios-retry";
import { JwtToken } from "interfaces/Token";
import {
  destroyToken,
  getCachedConfiguration,
  getToken,
  saveToken
} from "utils/functions/configurationUtil";

const axiosTokenInterceptor = axios.create();
axiosRetry(axiosTokenInterceptor, { retries: 3 });

const WHITELISTED_URLS = [process.env.AUTH_URL];

const shouldIntercept = (url: string) => {
  return !WHITELISTED_URLS.some((whitelistedUrl) => url.includes(whitelistedUrl as string));
};

const addToken = (req: AxiosRequestConfig, token: JwtToken | null) => {
  if (token !== null) {
    req.headers = {
      ...req.headers,
      Authorization: `Bearer ${token.access_token}`,
    };
  }
  return req as InternalAxiosRequestConfig;
};

function addPanelistAppUid(newReq: AxiosRequestConfig<any>) {
  const configuration = getCachedConfiguration();

  if (configuration?.publisher_app_uid) {
    newReq.headers = {
      ...newReq.headers,
      "x-inbrain-panelist-app-uid": configuration?.publisher_app_uid
    };
  }

  return newReq as InternalAxiosRequestConfig;
}

axiosTokenInterceptor.interceptors.request.use(
  (req) => {
    if (shouldIntercept(req.url as string)) {
      let newReq = addToken(req, getToken());
      return addPanelistAppUid(newReq);
    }

    return req;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const refetchToken = async (): Promise<JwtToken> => {
  const currentConfig = getCachedConfiguration();

  if (!currentConfig) {
    throw new Error("No configuration found for token refresh");
  }

  const configurationParams = {
    params: currentConfig.params || "",
    link: window.location.href,
  };

  const token = await requestToken(currentConfig);
  saveToken(token);
  return token;
};

axiosTokenInterceptor.interceptors.response.use(
  (response) => response,
  async (error: AxiosError) => {
    const originalRequest = error.config as InternalAxiosRequestConfig;

    if (error.response?.status === 401 && !originalRequest._retry) {
      console.log("token was expired...trying to get new token");
      originalRequest._retry = true;
      try {
        const newToken = await refetchToken();
        addToken(originalRequest, newToken);
        return axiosTokenInterceptor(originalRequest);
      } catch (err) {
        destroyToken();
        return Promise.reject(err);
      }
    }
    return Promise.reject(error);
  }
);

export default axiosTokenInterceptor;
