import axiosTokenInterceptor from "interceptors/tokenInterceptor";
import { Configuration } from "interfaces/Configuration";
import { JwtToken } from "interfaces/Token";
import { saveToken } from "utils/functions/configurationUtil";

export const requestToken = async (configuration: Configuration) => {
  let body: URLSearchParams = new URLSearchParams();
  let headers: { [key: string]: string } = {
    "Content-Type": "application/x-www-form-urlencoded",
  };

  if (configuration.client_id && configuration.client_secret) {
    body = new URLSearchParams({
      client_id: configuration.client_id || "",
      client_secret: configuration.client_secret,
      grant_type: "client_credentials",
      scope: process.env.SCOPE as string,
    });
  }

  if (configuration.params) {
    body = new URLSearchParams({
      client_id: "webview-link",
      grant_type: "link_credentials",
      scope: process.env.SCOPE as string,
      params: configuration.params,
    });

    headers["x-inBrain-referrer"] = configuration.referrer || "";
  }

  if (!body) {
    throw new Error("Request body is null");
  }

  const response = await axiosTokenInterceptor.post(
    `${process.env.AUTH_URL as string}/connect/token`,
    body.toString(),
    {
      headers: headers,
    }
  );
  console.log("Token received, saving it.");

  saveToken(response.data);
  return response.data as JwtToken;
};

export const requestSupplierApiToken = async (link: string) => {
  let body: URLSearchParams = new URLSearchParams();

  body.append("client_id", encodeURIComponent("supplier-api"));
  body.append("grant_type", "supplier_credentials");
  body.append("scope", process.env.SCOPE as string);

  const response = await axiosTokenInterceptor.post(
    `${process.env.AUTH_URL as string}/connect/token`,
    body.toString(),
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-inBrain-referrer": link,
      },
    }
  );

  console.log("Supplier API token received, saving it.");
  saveToken(response.data);
  return response.data as JwtToken;
};
