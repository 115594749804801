// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".guG9TSUGW_EAm5OVt8lc {\n  display: inline-block;\n  width: 100%;\n  font-family: Poppins;\n  font-size: 18px !important;\n  font-weight: 600;\n  text-align: center;\n  margin-bottom: 20px;\n}\n\n.Aii9vtINxdjKKxr2XtWC input {\n  border: none;\n  padding-left: 16px;\n  outline: none;\n  box-shadow: none;\n  background: #f5f5f5;\n  margin: auto;\n  text-align: left;\n  width: 100%;\n  border-radius: 13px;\n  height: 79px;\n  font-weight: 600;\n  font-size: 16px;\n  color: #000;\n  line-height: 20px;\n  letter-spacing: 0.48px;\n  font-family: Avenir;\n  -moz-box-sizing: border-box;\n       box-sizing: border-box;\n  display: block;\n  overflow: hidden;\n}\n", "",{"version":3,"sources":["webpack://./src/components/profiler/questionTypes/Text.module.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,WAAW;EACX,oBAAoB;EACpB,0BAA0B;EAC1B,gBAAgB;EAChB,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,aAAa;EACb,gBAAgB;EAChB,mBAAmB;EACnB,YAAY;EACZ,gBAAgB;EAChB,WAAW;EACX,mBAAmB;EACnB,YAAY;EACZ,gBAAgB;EAChB,eAAe;EACf,WAAW;EACX,iBAAiB;EACjB,sBAAsB;EACtB,mBAAmB;EACnB,2BAAsB;OAAtB,sBAAsB;EACtB,cAAc;EACd,gBAAgB;AAClB","sourcesContent":[".question-text {\n  display: inline-block;\n  width: 100%;\n  font-family: Poppins;\n  font-size: 18px !important;\n  font-weight: 600;\n  text-align: center;\n  margin-bottom: 20px;\n}\n\n.question-type-container input {\n  border: none;\n  padding-left: 16px;\n  outline: none;\n  box-shadow: none;\n  background: #f5f5f5;\n  margin: auto;\n  text-align: left;\n  width: 100%;\n  border-radius: 13px;\n  height: 79px;\n  font-weight: 600;\n  font-size: 16px;\n  color: #000;\n  line-height: 20px;\n  letter-spacing: 0.48px;\n  font-family: Avenir;\n  box-sizing: border-box;\n  display: block;\n  overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
var _1 = "guG9TSUGW_EAm5OVt8lc";
export { _1 as "question-text" };
var _2 = "Aii9vtINxdjKKxr2XtWC";
export { _2 as "question-type-container" };
export default ___CSS_LOADER_EXPORT___;
