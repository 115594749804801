import { useCallback, useEffect, useState } from "react";
import { AxiosError } from "axios";
import { getOffers } from "api/offers";
import { useErrorHandler } from "contexts/errorContext";
import { getCachedConfiguration, setCacheConfiguration } from "utils/functions/configurationUtil";
import { getPanelistConfiguration, setPanelistState } from "api/panelist";
import { Offer } from "interfaces/Offer";
import { PanelistConfig } from "interfaces/PanelistConfig";
import { PanelistState } from "interfaces/PanelistState";
import OfferCardItem from "../../components/offers/OfferCardItem";
import Modal from "../../components/ui/Modal";
import QrModal from "components/ui/QrModal";
import Spinner from "components/ui/Spinner";
import Alert from "components/ui/Alert";
import OfferModalContent from "../../components/offers/OfferModal/OfferModalContent";
import OfferFilter from "components/offers/OfferFilter";
import OfferDeviceSelector from "components/offers/OfferDeviceSelector";
import MainFooter from "pages/SurveyWall/MainFooter/MainFooter";
import * as c from "./OffersPage.module.css";

function OffersPage() {
  const handleError = useErrorHandler();
  const [modalOpen, setModalOpen] = useState(false);
  const [qrModalOpen, setQrModalOpen] = useState(false);
  const [qrCode, setQrCode] = useState("");
  const [offers, setOffers] = useState<any>([]);
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdatingOffers, setIsUpdatingOffers] = useState(false);
  const [activeFilters, setActiveFilters] = useState<any>([]);
  const [modalContent, setModalContent] = useState<{
    goals?: Offer["goals"];
    description?: string[];
    platforms: string[];
    operatingSystems?: string[];
    title: string;
    rewardIac: number;
    thumbnailUrl?: string;
    offerId?: number;
  }>({ platforms: [], title: "", rewardIac: 0 });
  const [showOfferOnboardingWidget, setShowOfferOnboardingWidget] = useState(false);
  const configuration = getCachedConfiguration();

  const openModal = (offer: Offer) => {
    document.body.style.overflow = "hidden";
    setModalContent({
      goals: offer.goals,
      description: offer.description,
      platforms: offer.platforms,
      operatingSystems: offer.operatingSystems,
      title: offer.title,
      rewardIac: offer.rewardIac,
      thumbnailUrl: offer.thumbnailUrl,
      offerId: offer.id,
    });
    setModalOpen(true);
  };
  const closeModal = () => {
    document.body.style.overflow = "";
    setModalOpen(false);
  };

  const closeQrModal = () => {
    document.body.style.overflow = "";
    setQrModalOpen(false);
    setModalOpen(true);
  };

  const openQrModal = (qrCode: string) => {
    setQrCode(qrCode);

    setModalOpen(false);
    setQrModalOpen(true);
  };

  const LIMIT = 30;

  const loadOffers = useCallback(
    async (reset = false) => {
      if (!reset && !hasMore) return;

      setIsLoading(true);
      const currentOffset = reset ? 0 : offset;
      const filters = reset ? activeFilters : activeFilters;

      try {
        const newOffers = await getOffers(
          configuration!.hashed_publisher_app_uid!,
          detectMobileOS(),
          currentOffset,
          LIMIT,
          filters,
        );

        if (reset) {
          setOffers(newOffers);
        } else {
          setOffers((prevOffers: any) => [...prevOffers, ...newOffers]);
        }

        if (newOffers.length < LIMIT) setHasMore(false);
        setOffset((prevOffset) => (reset ? LIMIT : prevOffset + LIMIT));
      } catch (error) {
        handleError(error as AxiosError);
      } finally {
        setIsLoading(false);
      }
    },
    [offset, hasMore, isLoading, activeFilters],
  );

  const handleScroll = useCallback(() => {
    const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    const scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
    const clientHeight = document.documentElement.clientHeight || window.innerHeight;

    if (scrollHeight - scrollTop <= clientHeight + 100 && !isLoading && hasMore) {
      loadOffers();
    }
  }, [isLoading, hasMore, loadOffers]);

  const setFilters = (filters: string[]) => setActiveFilters(filters);

  useEffect(() => {
    const resetOffersOnFilterChange = async () => {
      setOffers([]);
      setHasMore(true);
      setOffset(0);
      await loadOffers(true);
    };

    resetOffersOnFilterChange();
  }, [activeFilters]);

  useEffect(() => {
    setActiveFilters(configuration!.offerDesktopDeviceTypesFilter);
    setShowOfferOnboardingWidget(
      configuration!.is_first_offer_attempted && !configuration!.is_offer_onboarding_widget_closed,
    );
  }, []);

  useEffect(() => {
    if (isLoading) {
      document.body.classList.add(c["no-scroll"]);
    } else {
      document.body.classList.remove(c["no-scroll"]);
    }
  }, [isLoading]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isLoading, hasMore, offset]);

  const detectMobileOS = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) return "iOS";
    if (/android/i.test(userAgent)) return "Android";
    return "";
  };

  const closeOfferOnboardingWidget = () => {
    setShowOfferOnboardingWidget(false);

    setPanelistState(configuration!.hashed_publisher_app_uid ?? "", {
      isOfferOnboardingWidgetClosed: true,
    })
      .then(({ isFirstOfferAttempted, isOfferOnboardingWidgetClosed }: PanelistState) => {
        configuration!.is_first_offer_attempted = isFirstOfferAttempted;
        configuration!.is_offer_onboarding_widget_closed = isOfferOnboardingWidgetClosed;

        setCacheConfiguration(configuration!);
      })
      .catch((error) => {
        console.error("Error updateing panelist state:", error);
        handleError(error);
      });
  };

  const handleOfferAttempt = () => {
    if (configuration!.is_first_offer_attempted) {
      return;
    }

    getPanelistConfiguration(
      configuration?.hashed_publisher_app_uid ?? "",
      configuration?.publisher_app_uid ?? "",
      configuration?.device_id ?? "",
      configuration?.session_uid ?? "",
      configuration?.language,
    )
      .then(({ isFirstOfferAttempted, isOfferOnboardingWidgetClosed }: PanelistConfig) => {
        configuration!.is_first_offer_attempted = isFirstOfferAttempted;
        configuration!.is_offer_onboarding_widget_closed = isOfferOnboardingWidgetClosed;

        setCacheConfiguration(configuration!);
        setShowOfferOnboardingWidget(
          configuration!.is_first_offer_attempted &&
            !configuration!.is_offer_onboarding_widget_closed,
        );
      })
      .catch((error) => {
        console.error("Error fetching user configuration:", error);
        handleError(error);
      });
  };

  return (
    <>
      {isLoading && (
        <div className={c["spinner-overlay"]}>
          <Spinner size="normal" />
        </div>
      )}
      {!isLoading && showOfferOnboardingWidget && (
        <div className={c["offers-alert-container"]}>
          <Alert
            severity="danger"
            dismissible={true}
            onClose={closeOfferOnboardingWidget}
            className={c["offers-alert"]}
          >
            Great job! You started your first offer. Offer rewards may not be instant, if you
            completed the offer it may take up to 7 days before you are credited.
          </Alert>
        </div>
      )}
      <div className={c["offers-page"]}>
        {detectMobileOS() === "" && activeFilters && activeFilters.length > 0 && (
          <div className={c["offers-filter-container"]}>
            <OfferFilter onDeviceSelection={setFilters} onStartSpinner={() => setIsLoading(true)} />
          </div>
        )}
        {detectMobileOS() === "" &&
          !isUpdatingOffers &&
          activeFilters &&
          activeFilters.length == 0 && (
            <div className={c["offers-device-selector-container"]}>
              <OfferDeviceSelector
                onDeviceSelection={setFilters}
                setIsUpdatingOffers={setIsUpdatingOffers}
                onStartSpinner={() => setIsLoading(true)}
              />
            </div>
          )}
        <div className={`${c["offers-container"]} ${offers.length == 0 ? c["no-offers"] : ""}`}>
          {offers && offers.length > 0
            ? offers.map((offer: Offer) => (
                <OfferCardItem
                  key={offer.id}
                  onModalOpen={() => openModal(offer)}
                  offer={offer}
                  currencyName={configuration!.currency_name!}
                />
              ))
            : !isUpdatingOffers &&
              !isLoading && <p className={c["no-offers-message"]}>No Offers Available</p>}
        </div>
      </div>
      <MainFooter style={{ marginTop: 0 }} />
      <Modal show={modalOpen} onClose={closeModal}>
        <OfferModalContent
          modalContent={modalContent}
          onOpenQrModal={openQrModal}
          onOfferAttempt={handleOfferAttempt}
        />
      </Modal>
      <QrModal show={qrModalOpen} qrCode={qrCode} onClose={closeQrModal} />
    </>
  );
}

export default OffersPage;
