import { Goal } from "interfaces/Goal";
import { transformCurrency } from "utils/functions/currencyModUtil";
import * as c from "./OfferModalGoalTask.module.css";

interface OfferModalGoalTaskProps {
  goal: Goal;
  currencyName: string;
  index: number;
}

const OfferModalGoalTask = ({ goal, currencyName, index }: OfferModalGoalTaskProps) => {
  const { id, title, description, rewardIac, isCompleted, isPending } = goal;
  const currency = transformCurrency(currencyName, rewardIac);

  return (
    <div className={c.task}>
      <span className={c["task-number"]}>{index + 1}</span>
      <div className={c["task-content-wrapper"]}>
        <span>{title}</span>
        <span className={c["task-currency"]}>{currency}</span>
      </div>
    </div>
  );
};

export default OfferModalGoalTask;
