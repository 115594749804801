import React, { createContext, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { AxiosError } from "axios";
import { getCachedConfiguration } from "utils/functions/configurationUtil";
import { ProductTypes } from "utils/constants/enums";

interface ErrorHandlerContextProps {
  handleError: (error: AxiosError) => void;
}

const ErrorHandlerContext = createContext<ErrorHandlerContextProps | undefined>(undefined);

export const useErrorHandler = () => {
  const context = useContext(ErrorHandlerContext);
  if (!context) {
    throw new Error("useErrorHandler must be used within an ErrorHandlerProvider");
  }
  return context.handleError;
};

export const ErrorHandlerProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const configuration = getCachedConfiguration();

  const handleError = (error: AxiosError) => {
    const status = error.response?.status;
    const customApiCode = error.response?.data?.code ?? 'Unknown Code';
    console.log(status);

    const isPreSurveyRoute = location.pathname.includes("/pre-survey");
    const isNativeSurveys = configuration?.product_type == ProductTypes.NativeSurveys;

    const handleErrorNavigation = (message: string, redirectUrl?: string) => {
      navigate("/error", {
        state: { message, redirectUrl },
      });
    };

    const notJsonFileError = (errorResponse: AxiosError) => {
      if (errorResponse.response && errorResponse.response.data) {
        const url = errorResponse.response.config.url;
        return url!.indexOf(".json") === -1;
      }
      return true;
    };

    console.log("error response output: ");
    console.log(error.response);

    if (error.code === 'ERR_NETWORK'){
      handleErrorNavigation("Network Error", "/configuration?load_existing_config=true");
    }
    
    if (isPreSurveyRoute){
      switch (true){
        case status == 400 && customApiCode == "C1162":
          handleErrorNavigation("Survey was no longer available");
          return;
        case status == 400 && customApiCode == "C1128":
          handleErrorNavigation("Unable to start survey");
          return;
        case status == 403:
          handleErrorNavigation("Survey limit reached for the day");
          return;
        default:
          break;
      }
    }

    switch (true) {
      case status === 400:
        handleErrorNavigation("Bad Request", "/configuration?load_existing_config=true");
        break;
      case status === 401:
        handleErrorNavigation("Invalid Token", "/configuration?load_existing_config=true");
        break;
      case status === 404 && notJsonFileError(error):
        handleErrorNavigation(error.response?.data?.description);
        break;
      case status! >= 500 && status! < 600:
        handleErrorNavigation("Internal Server Error", "/configuration?load_existing_config=true");
        break;
      case status === 0:
        handleErrorNavigation("Error Connecting to Server", "/configuration?load_existing_config=true");
        break;
      case customApiCode === "C1124": // User Banned error code
        handleErrorNavigation("Surveys Unavailable", "/configuration?load_existing_config=true");
        break;
      default:
        handleErrorNavigation(error.response?.data?.description ?? "Unkown Error has occured", "/configuration?load_existing_config=true");
        break;
    }
    console.error("An error occurred:", error);
  };

  return (
    <ErrorHandlerContext.Provider value={{ handleError }}>{children}</ErrorHandlerContext.Provider>
  );
};
