import { useEffect, useState } from "react";
import SelectOneItem from "./SelectOneItem";

import * as c from "./SelectMoreItem.module.css";

type Option = {
  id: number;
  value: string;
  isRequired: boolean;
  groupId: number | null;
  groupName: string;
};

interface SelectMoreItemProps {
  groupName: string;
  groupOptions: Option[];
  selectedOption: string | null;
  onSelect: (value: string, id: number) => void;
  openDropdown: string | null;
  onDropdownToggle: (groupName: string) => void;
}

function SelectMoreItem({
  groupName,
  groupOptions,
  selectedOption,
  onSelect,
  openDropdown,
  onDropdownToggle,
}: SelectMoreItemProps) {
  const [isSelected, setIsSelected] = useState(false);

  const handleOptionSelect = (value: string, id: number) => {
    onDropdownToggle(groupName);
    onSelect(value, id);
  };

  const handleDropdown = () => {
    onDropdownToggle(groupName);
  };

  useEffect(() => {
    setIsSelected(groupOptions.some((option) => option.value === selectedOption));
  }, [selectedOption]);

  return (
    <>
      <div className={`${c["select-one-container"]} ${isSelected ? c["selected"] : ""}`}>
        <input
          type="radio"
          id={`checkbox_for_${groupName}`}
          checked={isSelected}
          onClick={handleDropdown}
          readOnly
        />
        <label htmlFor={`checkbox_for_${groupName}`}>
          {groupOptions.some((option) => option.value === selectedOption)
            ? selectedOption
            : groupName}
        </label>

        <div className={c["dropdown-options-container"]}>
          {openDropdown === groupName &&
            groupOptions.map((option) => (
              <SelectOneItem
                key={option.id}
                value={option.value}
                isSelected={selectedOption === option.value}
                onSelect={() => handleOptionSelect(option.value, option.id)}
                usedInMore={true}
              />
            ))}
        </div>
      </div>
    </>
  );
}

export default SelectMoreItem;
