import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
    setCacheConfiguration,
    setCurrentSurvey,
    savePublisherFlags,
    saveColors,
    saveToken
} from "utils/functions/configurationUtil";

function IFrameConfigurationPage() {

  useEffect(() => {
    function handleMessage(event:any) {
    console.log("Message from iFrame Configuration: ", event);
      if (event.origin !== window.location.origin || !event.data || !event.data.configuration) {
        return;
      }

      window.removeEventListener('message', handleMessage);

      setCacheConfiguration(event.data.configuration);
      savePublisherFlags(event.data.publisherFlags);
      saveColors(event.data.publisherColors);
      saveToken(event.data.jwtToken);
      window.location.href = event.data.surveyLink;
    }

    window.addEventListener('message', handleMessage);
    window.opener.postMessage('frame-ready');
  }, []);


  return (
    <>
        <p>Loading...</p>
    </>
  );
}

export default IFrameConfigurationPage;
