import axiosTokenInterceptor from "interceptors/tokenInterceptor";
import { ProductTypes } from "utils/constants/enums";

export const getPublisherConfig = async (
  productType: ProductTypes,
  app_uid: string
) => {
  const request = `${process.env.API_URL}/external-panelist/publisher/config`;

  const params = new URLSearchParams({
    productType: productType.toString(),
    appUserId: app_uid,
  });

  const response = await axiosTokenInterceptor.get(request, { params });

  return response.data;
};
