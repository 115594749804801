import { TbStarFilled } from "react-icons/tb";
import * as c from "./FiveStarRating.module.css";

function FiveStarRating({
	rating,
	starSize = 10,
	starColor,
}: {
	rating: number;
	starColor: string;
	starSize?: number;
}) {
	if (rating < 0 || rating > 5) {
		throw new Error("Rating must be between 0 and 5");
	}

	const emptyStarColor = "#e3e3e3";

	return (
		<div className={c["star-container"]}>
			{[...Array(5)].map((_, index) => (
				<TbStarFilled
					key={index}
					size={starSize}
					color={index < rating ? starColor : emptyStarColor}
				/>
			))}
		</div>
	);
}

export default FiveStarRating;
