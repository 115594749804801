interface SurveysTabIconProps {
  color?: string;
}

function SurveysTabIcon(props: SurveysTabIconProps) {
  const color = props.color || "white";

  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.9375 0H8.4375C8.95527 0 9.375 0.479687 9.375 1.07143V8.21429C9.375 8.80603 8.95527 9.28571 8.4375 9.28571H0.9375C0.419727 9.28571 0 8.80603 0 8.21429V1.07143C0 0.479687 0.419727 0 0.9375 0ZM19.0625 0H11.5625C11.0447 0 10.625 0.479687 10.625 1.07143V8.21429C10.625 8.80603 11.0447 9.28571 11.5625 9.28571H19.0625C19.5803 9.28571 20 8.80603 20 8.21429V1.07143C20 0.479687 19.5803 0 19.0625 0ZM0 11.7857V18.9286C0 19.5203 0.419727 20 0.9375 20H8.4375C8.95527 20 9.375 19.5203 9.375 18.9286V11.7857C9.375 11.194 8.95527 10.7143 8.4375 10.7143H0.9375C0.419727 10.7143 0 11.194 0 11.7857ZM19.0625 20H11.5625C11.0447 20 10.625 19.5203 10.625 18.9286V11.7857C10.625 11.194 11.0447 10.7143 11.5625 10.7143H19.0625C19.5803 10.7143 20 11.194 20 11.7857V18.9286C20 19.5203 19.5803 20 19.0625 20Z"
        fill={color}
      />
    </svg>
  );
}

export default SurveysTabIcon;
