import { useState } from "react";
import { Navigate } from "react-router-dom";
import { isGetMoreSurveysEnabled } from "utils/functions/configurationUtil";
import GetMoreSurveysQuestions from "./GetMoreSurveysQuestions/GetMoreSurveysQuestions";
import GetMoreSurveysStart from "./GetMoreSurveysStart/GetMoreSurveysStart";
import GetMoreSurveysSuccess from "./GetMoreSurveysSuccess/GetMoreSurveysSuccess";

function GetMoreSurveysPage() {
  const [currentStep, setCurrentStep] = useState(0);

  if (!isGetMoreSurveysEnabled()) {
    return <Navigate to="/surveys" />;
  }

  switch (currentStep) {
    case 0:
      return <GetMoreSurveysStart onSuccess={() => setCurrentStep(currentStep + 1)} />;
    case 1:
      return <GetMoreSurveysQuestions onSuccess={() => setCurrentStep(currentStep + 1)} />;
    case 2:
      return <GetMoreSurveysSuccess />;
  }
}

export default GetMoreSurveysPage;
