import { Tag } from "./Tag";
import { OfferCreative } from "./OfferCreative";
import { Goal } from "./Goal";

export interface Offer {
  id: number;
  title: string;
  previewUrl: string;
  rewardIac: number;
  popularityScore: number;
  thumbnailUrl: string;
  earningsPerClick: number;
  description: string[];
  platforms: string[];
  operatingSystems: string[];
  tags: Tag[];
  offerCreatives: OfferCreative[];
  createdAt: Date | null;
  attemptedAt: string;
  goals: Goal[];
  isCompleted?: boolean;
  status: OfferStatuses
}

export enum OfferStatuses {
  Open = 'Open',
  Closed = 'Closed',
}
