import { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useErrorHandler } from "contexts/errorContext";
import { SurveyQuestion } from "interfaces/SurveyQuestion";
import { ProfilerAnswer } from "interfaces/ProfilerAnswer";
import { createProfilerAnswer } from "api/profiler";
import { getCachedConfiguration } from "utils/functions/configurationUtil";
import SelectOne from "./questionTypes/SelectOne";
import SelectMany from "./questionTypes/SelectMany";
import SelectDate from "./questionTypes/SelectDate";
import Gender from "./questionTypes/Gender";
import Text from "./questionTypes/Text";
import TextArea from "./questionTypes/TextArea";
import Button from "components/ui/Button";

import * as c from "./ProfilerSurveyForm.module.css";

interface SurveyFormProps {
  questions: SurveyQuestion[] | undefined;
  onSurveyFinished: (value: boolean) => void;
}

function ProfilerSurveyForm({ questions, onSurveyFinished }: SurveyFormProps): JSX.Element {
  const [canContinue, setCanContinue] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [currentValue, setCurrentValue] = useState<any>();
  const [progressWidth, setProgressWidth] = useState<number>((1 / questions!.length) * 100);
  const handleError = useErrorHandler();
  const { t } = useTranslation();

  const selectOneRef = useRef<any>(null);

  const handleNext = () => {
    setIsLoading(true);
    const configuration = getCachedConfiguration();
    const answer: ProfilerAnswer = {
      profilerQuestionId: questions![currentIndex].id,
    };

    if (typeof currentValue === "string") {
      answer.value = currentValue as string;
    } else if (typeof currentValue === "number") {
      answer.selectedOptionId = currentValue as number;
    }

    createProfilerAnswer(configuration?.hashed_publisher_app_uid ?? "", answer)
      .then(() => {
        setCurrentIndex(currentIndex + 1);
        setCanContinue(false);
        setIsLoading(false);
        if (selectOneRef.current) {
          selectOneRef.current.resetSelection();
        }
      })
      .catch((error) => {
        handleError(error);
      });
  };

  useEffect(() => {
    if (currentValue && currentValue !== null) {
      setCanContinue(true);
    } else if (currentValue == null) {
      setCanContinue(false);
    }
  }, [currentValue]);

  useEffect(() => {
    const baseWidth = (1 / questions!.length) * 100;
    const newWidth = baseWidth + (currentIndex / (questions!.length - 1)) * (100 - baseWidth);

    setProgressWidth(newWidth);
    if (questions!.length === currentIndex) {
      onSurveyFinished(true);
      return;
    }
  }, [currentIndex]);

  const renderQuestion = () => {
    if (questions === undefined || questions!.length === currentIndex) return;

    const question = questions[currentIndex];
    switch (question.type) {
      case "select-one":
        return (
          <SelectOne
            id={question.id}
            question={question.name}
            options={question.options}
            onSetValue={setCurrentValue}
            ref={selectOneRef}
          />
        );
      case "select-many":
        return <SelectMany id={question.id} question={question.name} options={question.options} />;
      case "date":
        return (
          <SelectDate id={question.id} question={question.name} onSetValue={setCurrentValue} />
        );
      case "gender":
        return (
          <Gender
            id={question.id}
            question={question.name}
            options={question.options}
            onSetValue={setCurrentValue}
          />
        );
      case "text":
        return (
          <Text
            id={question.id}
            questionName={question.name}
            questionValidator={question.validator}
            exampleLabel={question.example}
            onSetValue={setCurrentValue}
          />
        );
      case "text-area":
        return <TextArea id={question.id} question={question.name} options={question.options} />;

      default:
        return null;
    }
  };

  return (
    <>
      <div className={c["progress-bar-container"]}>
        <span className={c["progress-bar"]} style={{ width: `${progressWidth}%` }}></span>
      </div>
      <div className={c["content-container"]}>{renderQuestion()}</div>
      <div className={c["button-container"]}>
        <Button
          text={t("CONTINUE_MODULE.CONTINUE")}
          onClick={handleNext}
          disabled={canContinue}
          isLoading={isLoading}
        />
      </div>
    </>
  );
}

export default ProfilerSurveyForm;
