import { useEffect, useState } from "react";
import { PurifyText } from "utils/functions/purifyUtil";

import * as c from "./SelectDate.module.css";

interface SelectDateProps {
  id: number;
  question: string;
  onSetValue: (value: string) => void;
}

function SelectDate({ id, question, onSetValue }: SelectDateProps) {
  const [selectedYear, setSelectedYear] = useState<number | null>(null);
  const [selectedMonth, setSelectedMonth] = useState<number | null>(null);
  const [selectedDate, setSelectedDate] = useState<number | null>(null);
  const [yearList, setYearList] = useState<number[]>([]);
  const [monthList] = useState<number[]>(Array.from({ length: 12 }, (_, index) => index + 1));
  const [dateList] = useState<number[]>(Array.from({ length: 31 }, (_, index) => index + 1));
  const [age, setAge] = useState<number | null>(null);

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const startYear = currentYear - 100;
    const endYear = currentYear - 14;
    setYearList(Array.from({ length: endYear - startYear + 1 }, (_, index) => endYear - index));
  }, []);

  const isBirthdaySelected = !!selectedYear && !!selectedMonth && !!selectedDate;

  const onChangeBirthday = () => {
    if (selectedYear && selectedMonth && selectedDate) {      
      setAge(CalculateAge(selectedYear, selectedMonth, selectedDate));
    }
  };

  useEffect(() => {
    onChangeBirthday();
    if (isBirthdaySelected) {
      const date = new Date(selectedYear, selectedMonth, selectedDate, 23, 0, 0, 0);
      const dateString = date.toISOString();
      onSetValue(dateString);
    }
  }, [selectedYear, selectedMonth, selectedDate]);

  const handleYearChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedYear(parseInt(event.target.value, 10));
  };

  const handleMonthChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedMonth(parseInt(event.target.value, 10));
  };

  const handleDateChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedDate(parseInt(event.target.value, 10));
  };

  return (
    <>
      <span className={c["question-text"]} dangerouslySetInnerHTML={{ __html: PurifyText(question) }}></span>
      <div className={c["select-container"]}>
        <div className={c["select-wrapper"]}>
          <select
            value={selectedDate ?? ""}
            onChange={handleDateChange}
            data-has-value={!!selectedDate}
          >
            <option value="">Day</option>
            {dateList.map((date) => (
              <option key={date} value={date}>
                {date}
              </option>
            ))}
          </select>
          <span className={c["select-label"]}>Day</span>
        </div>
        <div className={c["select-wrapper"]}>
          <select
            value={selectedMonth ?? ""}
            onChange={handleMonthChange}
            data-has-value={!!selectedMonth}
          >
            <option value="">Month</option>
            {monthList.map((month) => (
              <option key={month} value={month}>
                {month}
              </option>
            ))}
          </select>
          <span className={c["select-label"]}>Month</span>
        </div>
        <div className={c["select-wrapper"]}>
          <select
            value={selectedYear ?? ""}
            onChange={handleYearChange}
            data-has-value={!!selectedYear}
          >
            <option value="">Year</option>
            {yearList.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
          <span className={c["select-label"]}>Year</span>
        </div>
      </div>
      {isBirthdaySelected && <p className={c["age-indicator"]}>{`You are ${age} years old`}</p>}
    </>
  );
}

function CalculateAge(selectedYear: number, selectedMonth: number, selectedDate: number){
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1; // Months are 0-based in JavaScript
  const currentDay = currentDate.getDate();

  let age = currentYear - selectedYear;

  // Check if the current date is before the birth date in the current year
  if (currentMonth < selectedMonth || (currentMonth === selectedMonth && currentDay < selectedDate)) {
    age -= 1;
  }

  return age;
}

export default SelectDate;
