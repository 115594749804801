import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BrokenSurveyReason, ProductTypes } from "utils/constants/enums";
import { getPublisherFlags, getCachedConfiguration } from "utils/functions/configurationUtil";
import { notifyNativeSurveyClosed } from "utils/functions/systemNotificationUtil";
import SurveyNotFit from "../../assets/images/survey-not-fit.svg";
import * as c from "./BrokenSurveyPage.module.css";

function BrokenSurveyPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const configuration = getCachedConfiguration();
  const optionButtons = [
    {
      reason: BrokenSurveyReason.INAPPROPRIATE_QUESTIONS,
      label: t("BROKEN_SURVEY_PAGE.ANSWER_OPTIONS.INAPPROPRIATE_QUESTIONS"),
    },
    {
      reason: BrokenSurveyReason.LONG_SURVEY,
      label: t("BROKEN_SURVEY_PAGE.ANSWER_OPTIONS.LONG_SURVEY"),
    },
    {
      reason: BrokenSurveyReason.BROKEN_SURVEY,
      label: t("BROKEN_SURVEY_PAGE.ANSWER_OPTIONS.BROKEN_SURVEY"),
    },
    { reason: BrokenSurveyReason.BAD_MOOD, label: t("BROKEN_SURVEY_PAGE.ANSWER_OPTIONS.BAD_MOOD") },
    { reason: BrokenSurveyReason.OTHER, label: t("BROKEN_SURVEY_PAGE.ANSWER_OPTIONS.OTHER") },
  ];

  const postReason = (reason: number) => {
    if (reason === 2) {
      navigate("/feedback/collect-info");
    } else {
      const { isNativeSurveysInAppRoutingEnabled } = getPublisherFlags() || {};
      const isNativeSurvey = configuration?.product_type == ProductTypes.NativeSurveys;

      if (isNativeSurveysInAppRoutingEnabled && isNativeSurvey) {
        notifyNativeSurveyClosed();
        return;
      }

      navigate("/surveys");
    }
  };

  return (
    <div className={c["profiler-container"]}>
      <div className={c["inbrain-top-header"]}></div>
      <div className={c["page-content"]}>
      <h1>{t("BROKEN_SURVEY_PAGE.HEADER")}</h1>
      <div className={c["broken-survey-content"]}>
        <img src={SurveyNotFit} />

        <h3 className={c["mb-10"]}>{t("BROKEN_SURVEY_PAGE.QUESTION")}</h3>

        {optionButtons.sort(() => Math.random() - 0.5).map((item) => (
          <button key={item.label} onClick={() => postReason(item.reason)}>
            <h3>{item.label}</h3>
          </button>
        ))}
      </div>
    </div>
    </div>
  );
}

export default BrokenSurveyPage;
