import * as c from "./Spinner.module.css";

interface SpinnerProps {
  size: string;
}

function Spinner({ size }: SpinnerProps) {
  return (
    <div className={c["loading-spinner"]}>
      <div className={`${c.spinner} ${c[size]}`}></div>
    </div>
  );
}

export default Spinner;
