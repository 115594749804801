import axiosTokenInterceptor from "interceptors/tokenInterceptor";
import { getCachedConfiguration } from "utils/functions/configurationUtil";

export const getNativeSurveyLink = async (
  surveyId: string,
  searchId?: string
) => {
  const configuration = getCachedConfiguration();

  const params = new URLSearchParams({
    surveyId: encodeURIComponent(surveyId),
    language: encodeURIComponent(configuration?.language ?? ""),
    sessionId: encodeURIComponent(configuration?.session_uid ?? ""),
  });

  if (configuration?.placement_id) {
    params.append("placementId", configuration.placement_id);
  }

  if (searchId) {
    params.append("searchId", searchId);
  }
  const request = `${
    process.env.API_URL
  }/external-panelist/${configuration?.hashed_publisher_app_uid ?? ""}/${configuration?.device_id ?? ""}/native-survey-link`;

  const response = await axiosTokenInterceptor.get(request, { params });

  return response.data;
};
