import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import { DEFAULT_LANG } from "utils/constants/keys";
import { getCachedConfiguration } from "utils/functions/configurationUtil";

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    debug: false,
    fallbackLng: DEFAULT_LANG,
    lng: DEFAULT_LANG,
    lowerCaseLng: true,
    resources: {},
  })
  .then(() => {
    const configuration = getCachedConfiguration();
    const storedLanguage = configuration?.language || DEFAULT_LANG;
    loadTranslationResource(storedLanguage);
  });

export const loadTranslationResource = (language: string) => {
  const normalizedLanguage = language.toLowerCase();

  return import(`./assets/locales/${normalizedLanguage}.json`).then((translation) => {
    i18n.addResourceBundle(normalizedLanguage, "translation", translation.default);
    i18n.changeLanguage(normalizedLanguage);
  });
};

export default i18n;
