import { createContext, useContext, useState, ReactNode } from "react";
import { getColors } from "utils/functions/configurationUtil";

interface ColorContextType {
  primaryColorCtx: string;
  secondaryColorCtx: string;
  setPrimaryColorCtx: (color: string) => void;
  setSecondaryColorCtx: (color: string) => void;
}

const ColorContext = createContext<ColorContextType | undefined>(undefined);

interface ColorProviderProps {
  children: ReactNode;
}

export const ColorProvider: React.FC<ColorProviderProps> = ({ children }) => {
  const { primaryColor, secondaryColor } = getColors();
  const [primaryColorCtx, setPrimaryColorCtx] = useState<string>(primaryColor ?? "#00a5ed");
  const [secondaryColorCtx, setSecondaryColorCtx] = useState<string>(secondaryColor ?? "#f27536");

  document.body.style.setProperty("--primary-color", primaryColorCtx);
  document.body.style.setProperty("--secondary-color", secondaryColorCtx);

  return (
    <ColorContext.Provider
      value={{ primaryColorCtx, secondaryColorCtx, setPrimaryColorCtx, setSecondaryColorCtx }}
    >
      {children}
    </ColorContext.Provider>
  );
};

export const useColorContext = (): ColorContextType => {
  const context = useContext(ColorContext);
  if (!context) {
    throw new Error("useColorContext must be used within a ColorProvider");
  }
  return context;
};
