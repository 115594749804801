export const CONFIGURATION_KEY = "configuration";
export const TOKEN_KEY = "jwt-token";
export const PUBLISHER_FLAGS_KEY = "app-publisher-flags";
export const COLORS_KEY = "app-colors";
export const CURRENCYSALES_KEY = "app-currency-sale";
export const NATIVE_SURVEY_KEY = "app-native-survey";
export const SURVEY_LINK_KEY = "survey-link";
export const CURRENT_SURVEY_KEY = "app-current-survey";
export const PLACEMENT_KEY = "app-placement";
export const FRAME_ORIGIN_KEY = "frame-origin";
export const DEFAULT_LANG = "en-us";
export const ALL_LANGUAGES = [
  "en-us",
  "es-us",
  "es-es",
  "es-mx",
  "en-in",
  "fr-fr",
  "fr-ca",
  "en-ca",
  "en-gb",
  "en-au",
  "pt-br",
  "de-de",
  "nl-nl",
  "it-it",
  "sv-se",
  "nb-no",
  "en-nz",
  "da-dk",
  "tr-tr",
  "pt-pt",
  "en-ie",
  "zh-cn",
  "zh-sg",
  "ja-jp",
  "pl-pl",
];

export const SUPPLIER_SURVEY_KEYS = {
  LANGUAGE: "supplier-lang",
  THEME: "supplier-theme",
  CURRENCY: "supplier-currency",
  PUBLISHER_FLAGS: "supplier-publisher-flags",
};
