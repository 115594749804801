import exclusiveText from "assets/images/exclusive.svg";
import moreSurveysIcon from "assets/images/more-surveys.svg";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as c from "./GetMoreSurveysStart.module.css";

function GetMoreSurveysStart({ onSuccess }: { onSuccess: () => void }) {
  const { t } = useTranslation();
  const [animateButton, setAnimateButton] = useState(false);

  useEffect(() => {}, []);

  function handleContinue() {
    setAnimateButton(true);
    setTimeout(() => {
      onSuccess();
    }, 300);
  }

  return (
    <div className={c["get-more-surveys-page"]}>
      <div className={c["get-more-surveys-container"]}>
        <div className={`${c["get-more-surveys-content"]} ${c["animated"]}`}>
          <img src={moreSurveysIcon} />
          <div className={c["title"]}>{t("GET_MORE_SURVEYS_START.TITLE")}</div>
          <div className={c["description"]}>{t("GET_MORE_SURVEYS_START.SURVEYS_DESCRIPTION")}</div>
          <div className={c["sub-description"]}>
            {t("GET_MORE_SURVEYS_START.SURVEYS_SUB_DESCRIPTION")}
            <img src={exclusiveText} alt="exclusive" />
            {t("GET_MORE_SURVEYS_START.BADGE")}
          </div>
        </div>
        <div className={c["get-more-surveys-cta-container"]}>
          <button
            className={`${c["get-more-surveys-cta"]} ${animateButton ? c["animated"] : ""}`}
            onClick={handleContinue}
          >
            {t("CONTINUE_MODULE.CONTINUE")}
          </button>
        </div>
      </div>
    </div>
  );
}

export default GetMoreSurveysStart;
