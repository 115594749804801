export const SUPPLIER_CONTEXT_KEY = "supplier-context";

export interface SupplierContext {
  readonly entryLink: string;
  readonly isSupplierSurveyMode: boolean;
  readonly isLocalhost: boolean;
}

export function initSupplierContext(entryLink: string) {
  if (!entryLink) {
    return;
  }

  const isSupplierSurveyMode = entryLink.split("/").includes("supplier-surveys");
  const isLocalhost = !!entryLink.split("/").find((value) => value.includes("localhost"));

  const context = { entryLink, isSupplierSurveyMode, isLocalhost } as SupplierContext;

  window.localStorage.setItem(SUPPLIER_CONTEXT_KEY, JSON.stringify(context));
}

export function getContext() {
  const supplierContextJson = window.localStorage.getItem(SUPPLIER_CONTEXT_KEY);

  if (supplierContextJson === null) {
    return null;
  }

  return JSON.parse(supplierContextJson) as SupplierContext;
}

export function isSupplierSurveyMode(): boolean {
  const context = getContext();

  return !!context && context.isSupplierSurveyMode;
}

export function isLocalhost(): boolean {
  const context = getContext();

  return !!context && context.isLocalhost;
}
