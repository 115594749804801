export const ES_ES_JSONtext = {
  PRIVACY_POLICY: `
  <h2>INBRAIN, LLC</h2>
  <h2>POLÍTICA DE PRIVACIDAD</h2>
    <ol>
    <div class='terms-header' ngClass.lt-md='terms-header-mobile' fxLayoutAlign='center center'>
      <p>Última actualización: <strong><u>enero 04, 2021</u></strong></p>
    </div>
    <p class='introduction'>inBrain, LLC (“inBrain“,“nosotros“ o “nuestro“) está comprometido a proteger su (“usted“, “su“, o “suyo“) privacidad y empoderarlo con esta política de privacidad (esta “Política de Privacidad“). Esta Política de Privacidad explica cómo recopilamos, usamos, divulgamos y aplicamos la información recopilada cuando usted utiliza nuestro sitio web (<a href='https://www.inbrain.ai/'>https://www.inbrain.ai/</a>) o utiliza cualquiera de los otros servicios que podemos poner a su disposición (colectivamente,“Servicios“). Según corresponda a usted, nuestros Términos de Servicio de Survey Creator, nuestros Términos de Servicio del Editor y nuestros Términos de Servicio de Survey Taker (colectivamente, los “Términos“) se incorporan expresamente aquí por referencia y son una parte integral del mismo. Puede encontrar los Términos aquí:  <a href='https://www.inbrain.ai/terms-and-policies/terms'>https://www.inbrain.ai/terms-and-policies/terms</a>.<br/>Al utilizar los Servicios o interactuar con nosotros, usted está aceptando esta Política de Privacidad. Si no está de acuerdo con los términos de esta Política de Privacidad, no acceda ni utilice los Servicios. Lea atentamente lo siguiente para comprender cómo recopilamos, usamos y mantenemos su información personal. A continuación, también se describen sus opciones de uso, acceso y corrección de su información personal.</p>
    <div class='changes-to-this-privacy-policy'>
      <li class='list-title'>
        <h3>Cambios en esta Política de Privacidad</h3>
      </li>
      <p>De vez en cuando, podemos cambiar esta Política de Privacidad, a nuestra entera discreción de acuerdo con la ley aplicable. En caso de que realicemos cambios, le notificaremos revisando la fecha de “Última actualización“ en la parte superior de esta Política de Privacidad. Si hay cambios significativos en esta Política de Privacidad, intentaremos notificarle directamente por correo electrónico o proporcionar información a través de nuestra página de inicio antes de que dichos cambios entren en vigor. Le recomendamos que revise nuestra Política de Privacidad siempre que utilice los Servicios para mantenerse informado de las formas en que puede proteger su privacidad.</p>
    </div>

    <div class='informations-we-collect-and-receive'>
      <li class='list-title'>
        <h3>Información que recopilamos y recibimos</h3>
      </li>
      <p>Recopilamos información para entregar los Servicios que solicita, para ayudar a mejorar su experiencia y para apoyar nuestras funciones empresariales. Recopilamos información suya de diversas maneras cuando interactúa con los Servicios. A continuación se proporciona más información sobre las categorías y las fuentes de información.</p>
      <ol>
        <li><strong>Información que nos proporcionó</strong>. Recopilamos información que usted decide compartir con nosotros. A veces, podemos requerir que proporcione cierta información para utilizar ciertas partes de nuestro Sitio, satisfacer sus solicitudes o proporcionarle ciertos Servicios.</li>
        <ol class='info-provided-to-us'>
          <li><strong>Registro de una cuenta</strong>. Si crea una cuenta con nosotros, es posible que le pidamos que proporcione su nombre, dirección de correo electrónico, sexo, código postal, estado civil, estado laboral, ingresos, raza y etnia, cumpleaños y una contraseña para crear su cuenta. También podemos preguntarle si está registrado para votar, el número de personas que viven con usted, los ingresos aproximados de sus empresas, las industrias en las que trabajan en su hogar, quién en su hogar toma ciertas decisiones y el nivel más alto de educación que ha completado.</li>
          <li><strong>Información de la encuesta</strong>. Cuando participa en una encuesta, podemos recopilar la información que envía en una encuesta y en relación con el recibo y canje de recompensas e incentivos.</li>
          <li><strong>Información de contenido</strong>. También puede optar por enviarnos información personal en un correo electrónico que contenga consultas sobre los Servicios y utilizaremos esta información para ayudarle a responder a su consulta. También puede optar por enviarnos información a través de otros métodos, incluyendo: (i) en respuesta al marketing u otras comunicaciones, (ii) a través de redes sociales o foros en línea, (iii) a través de la participación en una oferta, programa o promoción, o (iv) en relación con una relación comercial real o potencial con nosotros.</li>
        </ol>
        <li class='no-list-style'><strong>2.2.  Información que obtenemos de otros</strong>. Podemos obtener información sobre usted de terceros que nos ayudan a proporcionar nuestros Servicios, sistemas de información disponibles públicamente y servicios de información comercial. También podemos obtener cierta información de nuestros socios de marketing con el fin de informarle sobre productos, nuevas encuestas o servicios que creemos que podría interesarle. Por último, podemos obtener información que no lo identifique directamente, pero que más adelante se utilice de una manera que en el futuro, puede identificarle directamente. Podemos combinar esa información general con su información para proporcionarle servicios relacionados.
        </li>
        <li class='no-list-style'><strong>2.3.  Información que recopilamos automáticamente</strong>. Recopilamos automáticamente cierta información de usted cuando utiliza los Servicios, incluida la información de actividad de internet u otra red (como se describe a continuación), identificadores únicos de dispositivos, historial de navegación y búsqueda (incluido el contenido que ha visto en los Servicios) y cookies y otras tecnologías (tal como se especifica en la Sección 3). La información recopilada automáticamente incluye</li>
        <ol>
          <li class='no-list-style'><strong>2.3.1.  Información del dispositivo</strong>. Recopilamos información técnica cuando visita nuestros Servicios. Esto incluye información como el tipo de dispositivo o navegador que usa, el sistema operativo de su dispositivo, su proveedor de servicios de Internet, la configuración regional y de idioma de su dispositivo e identificadores de dispositivo, como la dirección IP y el ID de anuncio. Cuando visita e interactúa con los Servicios, podemos recopilar cierta información automáticamente a través de cookies u otras tecnologías, incluyendo, pero no limitado al tipo de computadora o dispositivo móvil que utiliza, el ID de dispositivo único de su dispositivo móvil, la dirección IP de su computadora o dispositivo móvil, su sistema operativo, el tipo(s) de navegador(es) de Internet que utiliza e información sobre la forma en que utiliza los Servicios.</li>
          <li class='no-list-style'><strong>2.3.2.  Información de uso</strong>. Recopilamos información sobre su uso de los Servicios, incluidos los datos sobre las funciones que utiliza, las páginas que visita, los correos electrónicos y anuncios que ve, partes de los Servicios con los que ve e interactúa, la hora del día que navega y sus páginas de referencia y salida.</li>
          <li class='no-list-style'><strong>2.3.3.  Información de bloqueo y error</strong>. Si alguno de los Servicios se bloquea o lanza un error, podemos recopilar datos para determinar la causa del error. La información de bloqueo o error recopilada puede incluir lo siguiente: dirección IP, nombre del dispositivo, versión del sistema operativo, configuraciones de aplicaciones, la hora, la fecha y otras estadísticas.</li>
        </ol>
      </ol>
    </div>

    <div class='cookies-and-other-technologies'>
      <li class='list-title'>
        <h3>Cookies y otras tecnologías</h3>
      </li>
      <p>
      Al igual que muchos otros sitios web, nuestros Servicios pueden utilizar “Cookies“ u “Otras tecnologías“ (como “etiquetas de píxeles“, “balizas web“, “GIF transparentes“, enlaces en correos electrónicos, JavaScript, ID de dispositivos asignados por Google o Apple, o tecnologías similares) para recopilar información y apoyar ciertas funciones de nuestros Servicios. Las cookies y otras tecnologías nos permiten a nosotros y a terceros obtener información sobre sus visitas a nuestros Servicios, incluido el análisis de sus patrones de visita. Aunque no está obligado a aceptar cookies cuando visita nuestros Servicios, es posible que no pueda utilizar toda la funcionalidad de nuestros Servicios si su navegador restringe nuestras Cookies. Utilizamos esta información para procesar sus solicitudes y para entregar anuncios en línea y móviles, mensajes y contenido para nosotros y otros que son específicos de sus intereses. La información que recopilamos de su navegador web y de Cookies y Otras Tecnologías no lo identifica personalmente, pero en algunos casos, podemos vincularla a información de identificación personal.
      </p>
      <ol>
        <li><strong>Cookies</strong>. A continuación, explicamos los diferentes tipos de Cookies que se pueden utilizar en los Servicios.
        </li>
        <ol>
          <li><strong>Cookies necesarias</strong>. Las Cookies necesarias son esenciales para permitir su uso de los Servicios e identificar y prevenir riesgos de seguridad, como almacenar la información de su sesión para evitar que otros cambien su contraseña sin su nombre de usuario y contraseña.</li>
          <li><strong>Cookies preferentes</strong>. Utilizamos estas Cookies para recordar su configuración y preferencias y para mejorar su experiencia en nuestros Servicios. Por ejemplo, podemos utilizar estas Cookies para recordar sus preferencias de idioma.</li>
          <li><strong>Cookies de sesión</strong> Estos tipos de Cookies se almacenan solo temporalmente durante una sesión de navegación y se eliminan de su dispositivo cuando cierra el navegador. Utilizamos cookies de sesión para apoyar la funcionalidad de los Servicios y para comprender su uso de los Servicios, es decir, qué páginas visita, qué enlaces utiliza y cuánto tiempo permanece en cada página.</li>
          <li>strong>Cookies de estadísticas</strong>. Las cookies de estadísticas ayudan a recopilar información sobre su uso de los Servicios y nos permiten mejorar su funcionamiento. Estas Cookies nos dan información agregada que utilizamos para supervisar el rendimiento de los Servicios, contar las visitas a la página, detectar errores técnicos, ver cómo los usuarios llegan a los Servicios y medir la eficacia del marketing (incluidos los correos electrónicos).</li>
          <li><strong>Cookies de marketing</strong>. Las cookies de marketing se utilizan para mostrar a los usuarios anuncios en todos los sitios web. La intención es mostrar anuncios relevantes y atractivos para el usuario individual y, por lo tanto, más valiosos para editores y anunciantes externos. No mostramos anuncios para ningún servicio además del nuestro.</li>
          <li><strong>Cookies no clasificadas</strong>. Las Cookies no clasificadas son Cookies que estamos en proceso de clasificación, junto con los proveedores de cookies individuales.</li>
        </ol>
        <li class="no-list-style"><strong>3.2.  Otras Tecnologías</strong>. Además de las Cookies, podemos utilizar Otras Tecnologías, como etiquetas de píxeles (también conocidas como balizas web y GIF transparentes), para, entre otras cosas, realizar un seguimiento de las acciones de los usuarios de los Servicios (sobre el uso, incluidos los destinatarios de correo electrónico), medir el éxito de nuestras campañas de marketing y compilar estadísticas de los Servicios y las tasas de respuesta.</li>
      </ol>
    </div>

    <div class='how-we-use-your-information'>
      <li class='list-title'>
        <h3>Cómo usamos su información</h3>
      </li>
      <ol>
        <li>
        Podemos utilizar la información recopilada con el propósito limitado de proporcionar los Servicios y funcionalidades y servicios relacionados, como se describe en los Términos y en esta Política de Privacidad, y según lo permita las leyes aplicables. Estos propósitos limitados incluyen circunstancias en las que es necesario proporcionar o cumplir los Servicios solicitados por o para usted o cuando usted nos haya dado su consentimiento expreso. Podemos utilizar la información que obtenemos sobre usted para:
        </li>
        <ol>
          <li>Proporcionar la información, los productos y los Servicios que solicite;</li>
          <li>Atender su cuenta y proporcionarle, si usted es un usuario registrado, un servicio al cliente eficaz;</li>
          <li>Comprender mejor sus necesidades e intereses, y proporcionarle una experiencia personalizada cuando utilice nuestros Servicios;</li>
          <li>Ponernos en contacto con usted con ofertas especiales y otra información que creemos que le interesará (de acuerdo con cualquier preferencia de privacidad que nos haya expresado);</li>
          <li>Ponernos en contacto con usted con información y avisos relacionados con su uso de nuestros Servicios;</li>
          <li>Invitar a participar en encuestas y a proporcionarnos comentarios (de acuerdo con cualquier preferencia de privacidad que nos haya expresado);</li>
          <li>Mejorar nuestros productos y servicios y desarrollar nuevos productos y servicios, mejorar nuestros esfuerzos de marketing y promoción, y mejorar el contenido, la funcionalidad y la usabilidad de los Servicios;</li>
          <li>Hacer cumplir nuestros Términos;</li>
          <li>Promover la seguridad y proteger y prevenir el fraude, reclamaciones y otras responsabilidades;</li>
          <li>Verificar la información que nos proporciona, así como las representaciones y garantías que nos hace en los Términos o en los Servicios;</li>
          <li>Cumplir con nuestras obligaciones de auditoría interna y externa;</li>
          <li>Enviarle oportunidades de encuesta a usted y notificarle estas oportunidades;</li>
          <li>Administrar y gestionar nuestros programas de recompensas e incentivos y satisfacer sus solicitudes de recompensas e incentivos; y</li>
          <li>Utilizar para cualquier otro propósito que le hagamos saber al momento de la recolección.</li>
        </ol>
        <li>Podemos agregar y/o desidentificar la información recopilada a través de los Servicios y de otras fuentes para que dicha información ya no pueda estar vinculada a usted o a su dispositivo (“Información agregada/no identificada“). Podemos utilizar información agregada/no identificada para cualquier propósito, incluyendo, sin limitación, con fines de investigación y marketing.</li>
      </ol>
    </div>

    <div class='how-we-use-and-disclose-info'>
      <li class='list-title'>
        <h3>Cómo compartimos y divulgamos información</h3>
      </li>
      <ol>
        <li><strong>No vendemos información personal</strong>. No vendemos ni divulgamos de otro modo información personal que recopilamos sobre usted, excepto como se describe en este documento o se le divulga de otro modo en el momento en que se recopilan los datos. Compartimos información para realizar las actividades que usted solicite. </li>
        <li><strong>Proveedores de servicios y socios comerciales</strong>. También compartimos información con proveedores con los que tenemos una relación contractual y que están prestando servicios, incluidos, entre otros, servicios de integración de datos, servicios de validación de datos, servicios de detección y/o prevención de fraudes, servicios de coincidencia de bases de datos, servicios de codificación, servicios de segmentación de datos y servicios relacionados con recompensas e incentivos, así como socios de investigación de mercado que proporcionan encuestas.</li>
        <li><strong>Su consentimiento.</strong>. Podemos compartir información para otros fines de acuerdo con su consentimiento o con su autorización adicional.</li>
        <li><strong>Aggregate/De-Identified Data</strong>. We may share Aggregate/De-Identified Information with any third parties, including advertisers, promotional partners, business and channel partners, sponsors, and/or others.
        </li>
        <li><strong>Razones legales</strong>. También podemos divulgar información sobre usted cuando, de buena fe, creemos que la divulgación es apropiada para cumplir con la ley, una orden judicial o una citación. También podemos divulgar información sobre usted para prevenir o investigar un posible delito, como fraude o robo de identidad; para proteger la seguridad de nuestros Servicios; para hacer cumplir o aplicar nuestros Términos u otros acuerdos; o para proteger nuestros propios derechos o propiedades o los derechos, propiedad o seguridad de nuestros usuarios u otros. Intentaremos notificar a nuestros usuarios sobre las demandas legales de su información personal cuando sea apropiado en nuestra sentencia a menos que esté prohibida por la ley o la orden judicial o cuando la solicitud sea una emergencia. Podemos impugnar tales demandas cuando creemos, a nuestra discreción, que las solicitudes son exageradas, vagas o carecen de la autoridad adecuada.</li>
        <li><strong>Venta, fusión u otra transferencia comercial</strong>. También nos reservamos el derecho de transferir información sobre usted a una organización sucesora si transferimos la propiedad u operación de uno de nuestros sitios web a otra organización o si nos fusionamos con otra organización. Si se produce dicha transferencia, haremos todos los esfuerzos razonables para ordenar al cesionario que utilice la información personal que nos haya proporcionado de manera coherente con esta Política de Privacidad. Tras dicha venta o transferencia, puede ponerse en contacto con la entidad a la que transferimos su información personal para cualquier consulta relativa al tratamiento de dicha información.</li>
      </ol>
    </div>

    <div class='your-choices'>
      <li class='list-title'>
        <h3>Sus opciones</h3>
      </li>
      <ol>
        <li>Puede gestionar cookies a través de su navegador web. La mayoría de los navegadores le dirán cómo dejar de aceptar nuevas Cookies, cómo recibir notificaciones cuando reciba una nueva Cookie y cómo deshabilitar las Cookies existentes. Puede averiguar cómo hacer esto para su navegador en particular haciendo clic en “ayuda“ en el menú de su navegador o visitando <a href='https://www.allaboutcookies.org'>www.allaboutcookies.org</a>. Tenga en cuenta, sin embargo, que sin cookies es posible que no pueda aprovechar al máximo todas las funciones de nuestro sitio web. Además de los controles basados en navegador, puede gestionar cookies de terceros visitando <a href='https://www.aboutads.info/choices/'>www.aboutads.info/choices/</a>. Para tomar decisiones por separado para aplicaciones móviles en un dispositivo móvil, puede descargar la aplicación AppChoices de DAA desde la tienda de aplicaciones de su dispositivo. Como alternativa, para algunos dispositivos puede utilizar los controles de plataforma de su dispositivo en la configuración para ejercer la opción. Tenga en cuenta que debe darse de baja por separado en cada navegador y en cada dispositivo. Es posible que los anuncios en sitios web de terceros que contienen el enlace de AdChoices se hayan dirigido a usted en función de la información recopilada por los socios publicitarios a lo largo del tiempo y a través de sitios web. Estos anuncios proporcionan un mecanismo para excluirse del uso de esta información por parte de los socios publicitarios con fines publicitarios basados en intereses.</li>
        <li>Si se ha registrado para una cuenta, puede acceder, revisar y actualizar cierta información personal que nos ha proporcionado iniciando sesión en su cuenta y utilizando las funciones y funcionalidades disponibles.</li>
        <li>Usted tiene la opción en cualquier momento de impedir que le enviemos correos electrónicos con fines de marketing ajustando su configuración en la configuración de su cuenta. También tiene la opción de optar por no recibir ciertos otros correos electrónicos de nosotros ajustando su configuración en la configuración de su cuenta. También puede optar por no recibir mensajes de correo electrónico comerciales siguiendo el enlace “cancelar suscripción“ incluido en estos mensajes. Tenga en cuenta que, a pesar de las preferencias de marketing por correo electrónico indicadas, podemos seguir enviándole correos electrónicos administrativos con respecto a inBrain, incluyendo, por ejemplo, avisos de actualizaciones de nuestros Términos o esta Política de Privacidad, notificaciones de nuevas oportunidades de encuesta, si decidimos proporcionarle dichos avisos de esta manera.</li>
        <li>Algunos navegadores ofrecen una opción de “no rastrear“ (“DNT“ por sus siglas en inglés). Dado que ningún estándar común de la industria o legal para DNT ha sido adoptado por grupos de la industria, empresas tecnológicas o reguladores, no respondemos a las señales DNT. Haremos esfuerzos para seguir monitoreando los desarrollos en torno a la tecnología de navegador DNT y la implementación de un estándar.</li>
      </ol>
    </div>

    <div class='european-residents'>
      <li class='list-title'>
        <h3>Residentes europeos</h3>
      </li>
      <p>Si usted es residente del Espacio Económico Europeo, tiene ciertos derechos con respecto a la información personal que mantenemos sobre usted. Le ofrecemos ciertas opciones sobre qué información personal recopilamos de usted, cómo usamos esa información y cómo nos comunicamos con usted. Si en algún momento desea ejercer sus derechos, póngase en contacto con nosotros de acuerdo con la sección “Contáctenos“ a continuación:</p>
      <ol>
        <li><strong>Información y acceso</strong>. Puede solicitar ver qué información personal procesamos de usted. Podemos informarle sobre cómo y por qué procesamos estos datos a menos que estén restringidos por la ley, o si no podemos verificar su identificación.</li>
        <li><strong>Presentación de la información</strong>. Usted puede abstenerse de enviarnos información directamente, aunque hacerlo puede afectar nuestra capacidad para proporcionarle los servicios, y la información que solicita y/o afecta su capacidad para usar los Servicios.</li>
        <li><strong>Rectificación</strong>. Puede solicitar actualizar o corregir la información personal que mantenemos para usted.</li>
        <li><strong>Derecho a ser olvidado / solicitud de eliminación</strong>. Puede solicitar la eliminación de su información personal. Podemos pedirle información adicional para verificar su identidad y procesaremos su solicitud de acuerdo con la ley.</li>
        <li><strong>Restricción</strong>. Puede solicitar la limitación en el procesamiento de su información personal.
        </li>
        <li><strong>Las cookies de restricción</strong>. Puede optar por no tener un número de identificación de cookies único asignado a su computadora para evitar la agregación y el análisis de los datos recopilados en nuestros sitios web. Por lo general, si no desea recibir cookies, puede configurar su navegador para que niegue las cookies o para que le avise cuando se coloca una cookie en su ordenador.</li>
        <li><strong>Objeción</strong>. Puede eliminar su consentimiento para el procesamiento de su información personal.</li>
        <li><strong>Transferibilidad</strong>. Puede solicitar transferir su información personal.</li>
      </ol>
    </div>

    <div class='california-residents'>
      <li class='list-title'>
        <h3>Residentes de California</h3>
      </li>
      <ol>
        <li>Si usted es residente del Estado de California, tiene ciertos derechos que se le otorgan bajo la Ley de Privacidad del Consumidor de California o la “CCPA“, por sus siglas en inglés. Cuando decimos “Información personal“ en esta sección, nos referimos a información que identifica, relaciona con, describe, es razonablemente capaz de ser asociada con, o razonablemente podría estar vinculada, directa o indirectamente, con usted. La información personal no incluye información agregada o información que no pueda estar razonablemente vinculada a usted.</li>
        <li>Recopilamos información personal e información no personal para los fines comerciales y comerciales descritos en la sección anterior titulada “Cómo usamos su información“, y compartimos información personal con las categorías de terceros descritas en esta Política de Privacidad. No vendemos (como tal término se define en la CCPA) la Información Personal que recopilamos (y no la venderemos sin proporcionar el derecho de exclusión voluntaria).</li>
        <li>Sujeto a ciertas limitaciones, la CCPA proporciona a los residentes de California los siguientes derechos:</li>
        <ol>
          <li>Usted tiene derecho a solicitar que le divulguemos la Información Personal que recopilamos, usamos o divulgamos, e información sobre nuestras prácticas de datos;</li>
          <li>Usted tiene derecho a solicitar que eliminemos su Información Personal que hemos recopilado de usted;</li>
          <li>Usted tiene derecho a solicitar que ya no vendamos su Información Personal a terceros;</li>
          <li>No lo discriminaremos por ejercer ninguno de estos derechos.</li>
        </ol>
        <li>Los residentes de California pueden hacer una solicitud de conformidad con su “derecho a saber“, “derecho a solicitar la eliminación“ y/o “derecho a darse de baja“ bajo la CCPA poniéndose en contacto con nosotros de acuerdo con la sección “Contáctenos“ a continuación. Con el fin de verificar su solicitud de conocer o eliminar información, podemos requerir que nos proporcione cierta información para ser utilizada únicamente con el fin de verificar su identidad.</li>
        <li>Bajo la CCPA, usted puede ejercer estos derechos usted mismo o puede designar un agente autorizado para hacer estas solicitudes en su nombre. Podemos solicitarle permiso por escrito para realizar solicitudes en su nombre y es posible que deba verificar la identidad de su agente autorizado.</li>
      </ol>
    </div>

    <div class='information-retention'>
      <li class='list-title'>
        <h3>Retención de información</h3>
      </li>
      <p>Conservaremos la información necesaria para cumplir con las solicitudes de privacidad, gestionaremos cuentas activas, según lo exija la ley, para resolver disputas o hacer cumplir nuestros acuerdos. Conservaremos la información personal que procesamos en nombre de nuestros clientes según las instrucciones de ellos. También podremos conservar copias de su información con fines de recuperación ante desastres, para prevenir fraudes o abusos futuros, o para fines comerciales legítimos, como el análisis de información agregada/no identificada, recuperación de cuentas, auditoría de nuestros registros.</p>
    </div>

    <div class='data-processing'>
      <li class='list-title'>
        <h3>Procesamiento de datos y transferencias de datos</h3>
      </li>
      <p>Al acceder o utilizar los Servicios o proporcionarnos información de otro modo, usted acepta el procesamiento y transferencia de información en y hacia los EE. UU. y otros países y territorios, que pueden tener diferentes leyes de privacidad de su país de residencia. Cada vez que compartimos información de usuarios de la Unión Europea fuera de la Unión Europea, nos aseguramos de que haya un mecanismo de transferencia adecuado.</p>
    </div>
    <div class='security'>
      <li class='list-title'>
        <h3>Seguridad</h3>
      </li>
      <p>Hemos implementado una variedad de medidas de seguridad administrativas, técnicas y físicas para proteger contra el acceso no autorizado, la destrucción o la alteración de su información. Estas salvaguardias varían en función de la sensibilidad de la información que recopilamos, procesamos y almacenamos y el estado actual de la tecnología. Aunque tomamos las medidas apropiadas para protegernos contra las divulgaciones no autorizadas de información, no podemos garantizar que la información que recopilamos nunca se divulgue de una manera que sea incompatible con esta Política de Privacidad.</p>
    </div>
    <div class='third-party-websites'>
      <li class='list-title'>
        <h3>Enlaces a sitios web de terceros</h3>
      </li>
      <p>Los Servicios pueden proporcionar la capacidad de conectarse a otros sitios web. Estos sitios web pueden operar independientemente de nosotros y pueden tener sus propios avisos o políticas de privacidad, que le sugerimos encarecidamente que revise. Si algún sitio web vinculado no es propiedad no está controlado por nosotros, no nos hacemos responsables de su contenido, cualquier uso del sitio web o las prácticas de privacidad del operador del sitio web.</p>
    </div>
    <div class='children-privacy'>
      <li class='list-title'>
        <h3>Privacidad de los niños</h3>
      </li>
      <p>Los Servicios están dirigidos a personas mayores de trece (13) años. No recopilamos a sabiendas información personal de personas menores de trece años (13) (“Niños“). Si tenemos conocimiento de cualquier Niño que nos haya proporcionado información personal, tomaremos medidas para desactivar su cuenta y eliminar la información personal. Si tiene conocimiento de la información de Niños, que se nos ha proporcionado, póngase en contacto con nosotros en la sección “Contáctenos“ a continuación.</p>
    </div>
    <div class='contact-us'>
      <li class='list-title'>
        <h3>Contáctenos</h3>
      </li>
      <p>Si tiene alguna pregunta o inquietud sobre nuestra Política de Privacidad, póngase en contacto con nosotros al correo electrónico <a href='support@inbrain.ai'>support@inbrain.ai</a>.</p>
    </div>
  </ol>
    `,
};
