import { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import BaseCallbackPage, { CallbackStatuses } from "./BaseCallbackPage";
import useInBrainCallback from "../../hooks/useInBrainCallback";

function LucidCallbackPage() {
  const { type } = useParams();

  let callbackStatus =
    type === "completion" ? CallbackStatuses.Complete : CallbackStatuses.Disqualification;

  const {
    isLoading,
    reward,
    displayedReward,
    currencyName,
    isRewardVisibilityDisabled,
    isDisqualificationDisabled,
    showAnimatedCta,
    handleCallback,
    navigateNext
  } = useInBrainCallback(callbackStatus);

  const callbackUrl = location.href;
  const callbackPath = `/external-provider-callback/lucid-api-${
    callbackStatus === CallbackStatuses.Complete ? "complete" : "terminate"
  }-v2`;

  const firstRender = useRef(true);
  useEffect(() => {
    const url = `${process.env.API_URL}${callbackPath}`;

    if (firstRender.current) {
      handleCallback(url, callbackUrl);
      firstRender.current = false;
    }
  }, []);

  return (
    <BaseCallbackPage
    isLoading={isLoading}
    callbackStatus={callbackStatus}
    currencyName={currencyName!}
    reward={reward}
    displayedReward={displayedReward}
    isRewardVisibilityDisabled={isRewardVisibilityDisabled}
    isDisqualificationDisabled={isDisqualificationDisabled}
    showAnimatedCta={showAnimatedCta}
    closeView={navigateNext}
    />
  );
}

export default LucidCallbackPage;
