import { useState } from "react";

import * as c from "./TogglerButton.module.css";

interface TogglerButtonProps {
  toggled: boolean;
  onClick: (value: boolean) => void;
}

function TogglerButton({ toggled, onClick }: TogglerButtonProps) {
  const [isToggled, toggle] = useState(toggled);

  const callback = () => {
    toggle(!isToggled);
    onClick(!isToggled);
  };

  return (
    <label>
      <input
        className={c["input-class"]}
        type="checkbox"
        defaultChecked={isToggled}
        onClick={callback}
      />
      <span className={c["span-class"]} />
    </label>
  );
}

export default TogglerButton;
