import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getCurrentSurvey, getPublisherFlags, getCachedConfiguration } from "utils/functions/configurationUtil";
import { ProductTypes } from "utils/constants/enums";
import { notifyNativeSurveyClosed } from "utils/functions/systemNotificationUtil";
import Button from "components/ui/Button";

import ErrorImage from "../../assets/images/error-icon.png";
import * as c from "./ErrorPage.module.css";

function ErrorPage() {
  const location = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { message, redirectUrl } = location.state || {};
  const configuration = getCachedConfiguration();

  const redirectToSpecifiedUrl = () => {
    const { isNativeSurveysInAppRoutingEnabled } = getPublisherFlags() || {};

    if (isNativeSurveysInAppRoutingEnabled && configuration?.product_type == ProductTypes.NativeSurveys) {
      notifyNativeSurveyClosed();
      return;
    }

    redirectUrl ? navigate(redirectUrl) : navigate("/surveys");
  };

  return (
    <div>
      <div className={c["profiler-container"]}>
        <div className={c["inbrain-top-header"]}></div>
        <div className={c["error-wrapper"]}>
          <div className={c["error-content"]}>
            <span>{t("ERROR.ERROR_MESSAGE")}</span>
            <img src={ErrorImage} alt="errorImage" />
            <div>
              <div className={c["error-message"]}>{message}</div>
            </div>
          </div>
          <div className={c["button-container"]}>
            <Button
              text={t("ERROR.REFRESH")}
              onClick={redirectToSpecifiedUrl}
              disabled={true}
              isLoading={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ErrorPage;
