export enum PanelistStages {
  PreBoostSurvey = "Stage_1",
  BoostSurvey = "Stage_2",
  PostBoostSurvey = "Stage_3",
}

export const PanelistStageIndicator = {
  [PanelistStages.PreBoostSurvey]: "PRE_BOOST_",
  [PanelistStages.BoostSurvey]: "BOOST_",
  [PanelistStages.PostBoostSurvey]: "POST_BOOST_",
};
