// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mY2g9y0wu76VpIuT37fA {\n  display: inline-block;\n  width: 100%;\n  font-family: Poppins;\n  font-size: 18px !important;\n  font-weight: 600;\n  text-align: center;\n  margin-bottom: 20px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/profiler/questionTypes/Gender.module.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,WAAW;EACX,oBAAoB;EACpB,0BAA0B;EAC1B,gBAAgB;EAChB,kBAAkB;EAClB,mBAAmB;AACrB","sourcesContent":[".question-text {\n  display: inline-block;\n  width: 100%;\n  font-family: Poppins;\n  font-size: 18px !important;\n  font-weight: 600;\n  text-align: center;\n  margin-bottom: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
var _1 = "mY2g9y0wu76VpIuT37fA";
export { _1 as "question-text" };
export default ___CSS_LOADER_EXPORT___;
