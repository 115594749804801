declare global {
  interface Window {
    fwSettings?: {
      widget_id: string;
    };
    FreshworksWidget?: {
      (...args: any[]): void;
      q: any[];
    };
  }
}

import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

function UnitedSupportPage(): JSX.Element {
  const { userId, language } = useParams<{ userId: string; language: string }>();

  useEffect(() => {
    const widgetId = language === 'es' ? '73000004875' : '73000004874';

    window.fwSettings = {
      widget_id: widgetId,
    };

    if (typeof window.FreshworksWidget !== 'function') {
      const FreshworksWidget = function () {
        FreshworksWidget.q.push(arguments);
      };
      FreshworksWidget.q = [] as any[];
      window.FreshworksWidget = FreshworksWidget;
    }

    const script = document.createElement('script');
    script.src = `https://widget.freshworks.com/widgets/${widgetId}.js`;
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

    script.onload = () => {
      if (window.FreshworksWidget) {
        window.FreshworksWidget('open');
        window.FreshworksWidget('hide', 'launcher');
        window.FreshworksWidget('hide', 'ticketForm', [
          'type',
          'custom_fields.cf_offer_name',
          'custom_fields.cf_brand',
          'custom_fields.cf_country745149',
          'custom_fields.cf_language639845',
          'group_id',
        ]);

        const observer = new MutationObserver(() => {
          const frameWrapper = document.querySelector('#freshworks-frame-wrapper');
          if (frameWrapper) {
            frameWrapper.setAttribute(
              'style',
              'width: 100%; max-width: 800px !important; position: fixed !important; margin-left: auto; margin-right: auto; left: 0 !important; top: 120px; right: 0; text-align: center;',
            );
            observer.disconnect();
          }
        });

        observer.observe(document.body, { childList: true, subtree: true });
      }
    };

    return () => {
      document.body.removeChild(script);
    };
  }, [language]);

  return <div style={{ backgroundColor: 'white', minHeight: '100vh' }}></div>;
}

export default UnitedSupportPage;
