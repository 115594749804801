import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useErrorHandler } from "contexts/errorContext";
import { useTranslation } from "react-i18next";
import { transformCurrency } from "utils/functions/currencyModUtil";
import { completeProfiling } from "api/profiler";
import {
  getCachedConfiguration,
  setCacheConfiguration
} from "utils/functions/configurationUtil";
import { ProductTypes } from "utils/constants/enums";
import Button from "components/ui/Button";
import surveySuccessImage from "../../assets/images/callback-icon.png";
import * as confetti from "canvas-confetti";
import * as c from "./ProfilerSurveySuccess.module.css";

interface ProfilerSuccessProps{
  onFinish: () => void;
}

function ProfilerSurveySuccess({ onFinish }: ProfilerSuccessProps) {
  const handleError = useErrorHandler();
  const navigate = useNavigate();
  const location = useLocation();
  const [canContinue, setCanContinue] = useState(false);
  const [clientRedirectLink, setClientRedirectLink] = useState("");
  const { t } = useTranslation();
  const configuration = getCachedConfiguration();
  const profilerReward = configuration?.profiler_reward!;
  const showProfilerReward = profilerReward > 0;
  const amount = transformCurrency(configuration?.currency_name ?? "", profilerReward);

  const onSubmit = () => {
    if (configuration?.product_type === ProductTypes.SurveysWall) {
      onFinish();
      return;
    }

    if (configuration?.product_type === ProductTypes.NativeSurveys) {
      onFinish();
      return;
    }

    if (configuration?.product_type === ProductTypes.SupplierApi) {
      if (!clientRedirectLink){
        console.log("no client redirect link set");
      }else{
        window.location.href = clientRedirectLink;
      }
      return;
    }
  };

  useEffect(() => {
    confetti.create()({
      shapes: ["square"],
      particleCount: 100,
      spread: 90,
      origin: {
        y: 1,
        x: 0.5,
      },
    });

    completeProfiling(configuration?.hashed_publisher_app_uid ?? "", configuration?.session_uid ?? "", configuration?.publisher_mid ?? "", configuration?.product_type as number)
      .then((response) => {
        setClientRedirectLink(response.clientRedirectLink);
        setCanContinue(true);
      })
      .catch((error) => {
        handleError(error);
      });
  }, []);

  return (
    <>
      <div className={c["title-container"]}>
        <div className={c["header-wrapper"]}>
          <img src={surveySuccessImage} alt="Pre profile picture" />
          <div className={c["pre-profiler-title-wrapper"]}>
            {showProfilerReward &&
            <>
              <span className={c["pre-profiler-earn"]}>
                {t("INBRAIN_THEME.PROFILER_SUCCESS.REWARD")}
                <span>: {amount}</span>
              </span>
              <span className={c["pre-profiler-title"]}>
                {t("INBRAIN_THEME.PROFILER_SUCCESS.DESCRIPTION")}
              </span>
            </>
            }
          </div>
        </div>
      </div>
      <div className={c["button-container"]}>
        <Button
          text={t("INBRAIN_THEME.PROFILER_SUCCESS.BUTTON_TEXT")}
          onClick={onSubmit}
          disabled={canContinue}
          isLoading={false}
        />
      </div>
    </>
  );
}

export default ProfilerSurveySuccess;
