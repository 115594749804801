import { useState } from "react";
import * as c from "./Button.module.css";
import Spinner from "./Spinner";

interface ButtonProps {
  text: string;
  onClick: () => void;
  disabled: boolean;
  isLoading: boolean;
}

function Button({ text, onClick, disabled, isLoading }: ButtonProps) {
  const [isAnimating, setIsAnimating] = useState(false);

  const handleClick = () => {
    onClick();
    setIsAnimating(true);
    setTimeout(() => {
      setIsAnimating(false);
    }, 300);
  };
  return (
    <button
      className={`${c.button} ${!disabled || isLoading ? c["button-disabled"] : ""} ${
        isAnimating ? c["button-animated"] : ""
      }`}
      onClick={handleClick}
    >
      {isLoading && disabled ? <Spinner size="small" /> : text}
    </button>
  );
}

export default Button;
