import { useTranslation } from "react-i18next";
import * as c from "./RewardSnackbar.module.css";

function RewardSnackbar({
  rewardValue,
  currencyName,
}: {
  rewardValue: string;
  currencyName: string;
}) {
  const { t } = useTranslation();

  return (
    <div className={`${c["reward-snackbar"]}`}>
      <span className={c["checkmark"]}>
        <svg className={c["checkmark-svg"]} viewBox="0 0 24 24">
          <path d="M4.1 12.7L9 17.6 20.3 6.3" fill="none" />
        </svg>
      </span>
      <p>
        <span>
          {rewardValue} {currencyName}
        </span>{" "}
        {t("EXTERNAL_SURVEY.INBRAIN.REWARD_INDICATION")}
      </p>
    </div>
  );
}

export default RewardSnackbar;
