import trophyIcon from "assets/images/callback-icon.png";
import exclusiveText from "assets/images/exclusive.svg";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import * as c from "./GetMoreSurveysSuccess.module.css";

function GetMoreSurveysSuccess() {
  const { t } = useTranslation();
  const [animateButton, setAnimateButton] = useState(false);
  const navigate = useNavigate();

  function handleContinue() {
    setAnimateButton(true);
    setTimeout(() => {
      navigate("/surveys");
    }, 300);
  }

  return (
    <div className={c["get-more-surveys-page"]}>
      <div className={c["get-more-surveys-container"]}>
        <div className={`${c["get-more-surveys-content"]} ${c["animated"]}`}>
          <img src={trophyIcon} />
          <div className={c["title"]}>{t("MORE_SURVEYS_SUCCESS.TITLE")}</div>
          <div className={c["description"]}>{t("MORE_SURVEYS_SUCCESS.DESCRIPTION")}</div>
          <div className={c["sub-description"]}>
            {t("MORE_SURVEYS_SUCCESS.SUB_DESCRIPTION")}
            <img src={exclusiveText} alt="exclusive" />
            {t("MORE_SURVEYS_SUCCESS.BADGE")}
          </div>
        </div>
        <div className={c["get-more-surveys-cta-container"]}>
          <button
            className={`${c["get-more-surveys-cta"]}  ${animateButton ? c["animated"] : ""}`}
            onClick={() => handleContinue()}
          >
            {t("CONTINUE_MODULE.CONTINUE")}
          </button>
        </div>
      </div>
    </div>
  );
}

export default GetMoreSurveysSuccess;
