import { useState } from "react";
import { AxiosError } from "axios";
import { useErrorHandler } from "contexts/errorContext";
import { setPanelistState } from "api/panelist";
import { getCachedConfiguration, setCacheConfiguration } from "utils/functions/configurationUtil";
import { TiVendorApple } from "react-icons/ti";
import { TiVendorAndroid } from "react-icons/ti";
import { FaCheckCircle } from "react-icons/fa";
import blueArrow from "../../assets/images/qr-blue-arrow.png";
import * as c from "./OfferDeviceSelector.module.css";

type DeviceType = "iOS" | "Android";

interface OfferDeviceSelectorProps {
  onDeviceSelection: (filters: string[]) => void;
  onStartSpinner: () => void;
  setIsUpdatingOffers: (state: boolean) => void;
}

const OfferDeviceSelector = ({
  onDeviceSelection,
  onStartSpinner,
  setIsUpdatingOffers,
}: OfferDeviceSelectorProps) => {
  const handleError = useErrorHandler();
  const [selectedDevices, setSelectedDevices] = useState<DeviceType[]>([]);
  const configuration = getCachedConfiguration();

  const updateOffers = () => {
    onStartSpinner?.();
    setIsUpdatingOffers(true);
    const updatedDevices = [...selectedDevices, "Desktop"];
    setPanelistState(configuration!.hashed_publisher_app_uid!, {
      offerDesktopDeviceTypesFilter: updatedDevices,
    })
      .then((resp) => {
        configuration!.offerDesktopDeviceTypesFilter = updatedDevices;
        setCacheConfiguration(configuration!);
        onDeviceSelection?.(updatedDevices);
      })
      .catch((error: AxiosError) => {
        handleError(error);
        console.error("Failed to resolve geolocation: ", error);
      })
      .finally(() => {
        setIsUpdatingOffers(false);
      });
  };

  const toggleDevice = (device: DeviceType) => {
    setSelectedDevices((prev) =>
      prev.includes(device) ? prev.filter((d) => d !== device) : [...prev, device],
    );
  };

  const isDeviceSelected = (device: DeviceType) => selectedDevices.includes(device);

  return (
    <div className={c["device-selector"]}>
      <span>What device type(s) do you use?</span>
      <div className={c["device-buttons-wrapper"]}>
        <p>
          We’ll use this info to better filter offer for you, your filter can always be updated
          later!
        </p>

        <div className={c["device-buttons"]}>
          <button
            className={`${c["device-button"]} ${isDeviceSelected("iOS") ? c["selected"] : ""}`}
            onClick={() => toggleDevice("iOS")}
          >
            <TiVendorApple /> iOS (iPhone or iPad)
            <FaCheckCircle color={isDeviceSelected("iOS") ? "#f27536" : "white"} />
          </button>
          <button
            className={`${c["device-button"]} ${isDeviceSelected("Android") ? c["selected"] : ""}`}
            onClick={() => toggleDevice("Android")}
          >
            <TiVendorAndroid color="#A6D864" /> Android (phone or tablet){" "}
            <FaCheckCircle color={isDeviceSelected("Android") ? "#f27536" : "white"} />
          </button>
          <div className={c["qr-blue-arrow-wrapper"]}>
            <img src={blueArrow} alt="Blue Arrow" />
            <span>Choose 1 or both</span>
          </div>
        </div>
        <button
          className={c["update-offers-button"]}
          disabled={selectedDevices.length === 0}
          onClick={updateOffers}
        >
          Update Offers
        </button>
      </div>
    </div>
  );
};

export default OfferDeviceSelector;
