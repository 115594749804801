import { useState } from "react";
import SelectOneItem from "../questionItems/SelectOneItem";
import { PurifyText } from "utils/functions/purifyUtil";

import * as c from "./Gender.module.css";

type Option = {
  id: number;
  value: string;
  isRequired: boolean;
  groupId: number | null;
  groupName: string;
};

interface GenderProps {
  id: number;
  question: string;
  options: Option[];
  onSetValue: (id: number) => void;
}

function Gender({ id, question, options, onSetValue }: GenderProps) {
  const [selectedOption, setSelectedOption] = useState<string | null>(null);

  const handleOptionSelect = (value: string, id: number) => {
    setSelectedOption(value);
    onSetValue(id);
  };

  return (
    <div>
      <span className={c["question-text"]} dangerouslySetInnerHTML={{ __html: PurifyText(question) }}></span>
      {options.map((option) => (
        <SelectOneItem
          key={option.id}
          value={option.value}
          isSelected={selectedOption === option.value}
          onSelect={() => handleOptionSelect(option.value, option.id)}
        />
      ))}
    </div>
  );
}

export default Gender;
