import { AxiosError } from "axios";
import { useCallback, useEffect, useState } from "react";
import { getSummerizedTransactions, getSurveys, getTransactions } from "api/offers";
import { useErrorHandler } from "contexts/errorContext";
import { getCachedConfiguration } from "utils/functions/configurationUtil";
import { transformCurrency } from "utils/functions/currencyModUtil";
import { Offer, OfferStatuses } from "interfaces/Offer";
import { Reward } from "interfaces/reward";
import MainFooter from "pages/SurveyWall/MainFooter/MainFooter";
import Modal from "components/ui/Modal";
import QrModal from "components/ui/QrModal";
import RewardModalContent from "components/rewards/RewardModal/RewardModalContent";
import RewardOfferCardItem from "components/rewards/RewardOfferCardItem";
import RewardSurveyCardItem from "components/rewards/RewardSurveyCardItem";
import Spinner from "components/ui/Spinner";
import * as c from "./RewardsPage.module.css";

function RewardsPage() {
  const handleError = useErrorHandler();
  const [rewardsOffer, setRewardsOffer] = useState<any>([]);
  const [rewardsSurvey, setRewardsSurvey] = useState<any>([]);
  const [fetchDisabled, setFetchDisabled] = useState(false);
  const [hasFetchedSurveys, setHasFetchedSurveys] = useState(false);
  const [hasFetchedOffers, setHasFetchedOffers] = useState(false);
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [sumAmountsToday, setSumAmountsToday] = useState<number>(0);
  const [sumAmounts30, setSumAmounts30] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [qrModalOpen, setQrModalOpen] = useState(false);
  const [qrCode, setQrCode] = useState("");
  const [modalContent, setModalContent] = useState<{
    attemptedAt?: string;
    goals?: Offer["goals"];
    isCompleted?: boolean;
    description?: string[];
    platforms: string[];
    operatingSystems?: string[];
    title: string;
    rewardIac: number;
    thumbnailUrl?: string;
    offerId?: number;
    offerStatus?: OfferStatuses;
  }>({ platforms: [], title: "", rewardIac: 0 });

  const configuration = getCachedConfiguration();

  const initialTab = configuration?.surveys_enabled
    ? "Surveys"
    : configuration?.offers_enabled
    ? "Offers"
    : "Surveys";
  const [activeTab, setActiveTab] = useState(initialTab);
  const isSingleTab =
    (configuration?.surveys_enabled && !configuration?.offers_enabled) ||
    (!configuration?.surveys_enabled && configuration?.offers_enabled);

  const LIMIT = 30;

  const getModifiedGoals = (offer: Offer) => {
    let foundCompleted = false;

    const modifiedGoals = offer.goals.map((goal, index) => {
      if (index === 0 && !goal.isCompleted) {
        return { ...goal, isPending: true };
      }
      if (index === 1 && !offer.goals[0].isCompleted) {
        return { ...goal, isPending: true };
      }

      if (foundCompleted && !goal.isCompleted) {
        foundCompleted = false;
        return { ...goal, isPending: true };
      }

      if (goal.isCompleted) {
        foundCompleted = true;
      }

      if (offer.goals.every((g) => g.isCompleted)) {
        return { ...goal, isPending: false };
      }

      return goal;
    });

    return { ...offer, goals: modifiedGoals };
  };

  const openModal = (offer: Offer) => {
    document.body.style.overflow = "hidden";
    offer = getModifiedGoals(offer);
    setModalContent({
      attemptedAt: offer.attemptedAt,
      goals: offer.goals,
      isCompleted: offer.isCompleted,
      description: offer.description,
      platforms: offer.platforms,
      operatingSystems: offer.operatingSystems,
      title: offer.title,
      rewardIac: offer.rewardIac,
      thumbnailUrl: offer.thumbnailUrl,
      offerId: offer.id,
      offerStatus: offer.status,
    });
    setModalOpen(true);
  };
  const closeModal = () => {
    document.body.style.overflow = "";
    setModalOpen(false);
  };

  const closeQrModal = () => {
    document.body.style.overflow = '';
    setQrModalOpen(false);
    setModalOpen(true);
  };

  const openQrModal = (qrCode: string) => {
    setQrCode(qrCode);

    setModalOpen(false);
    setQrModalOpen(true);

  }

  const currency = (name: string, amount: number) => {
    return transformCurrency(name, amount);
  };

  useEffect(() => {
    getSummerizedTransactions(configuration!.hashed_publisher_app_uid!, configuration!.device_id!)
      .then((sumAmounts) => {
        setSumAmountsToday(sumAmounts.currentDayTotalAmount);
        setSumAmounts30(sumAmounts.last30DaysTotalAmount);
      })
      .catch((error) => {
        handleError(error);
      });
  }, []);

  useEffect(() => {
    if (fetchDisabled) return;

    setIsLoading(true);
    if (activeTab === "Surveys") {
      if (!hasFetchedSurveys) {
        getSurveys(configuration!.hashed_publisher_app_uid!, configuration!.device_id!)
          .then((surveys) => {
            setRewardsSurvey(surveys);
            setHasFetchedSurveys(true);
            setIsLoading(false);
          })
          .catch((error) => {
            handleError(error);
          });
      } else {
        setIsLoading(false);
      }
    } else {
      if (!hasFetchedOffers) {
        loadOffers();
        setHasFetchedOffers(true);
      } else {
        setIsLoading(false);
      }
    }

    if (hasFetchedSurveys && hasFetchedOffers) {
      setFetchDisabled(true);
      const timer = setTimeout(() => setFetchDisabled(false), 120000);

      return () => clearTimeout(timer);
    }
  }, [activeTab]);

  const loadOffers = useCallback(async () => {
    if (!hasMore) return;

    setIsLoading(true);
    var os = detectMobileOS();

    try {
      const newOffers = await getTransactions(
        configuration!.hashed_publisher_app_uid!,
        os,
        offset,
        LIMIT,
      );
      if (newOffers.length < LIMIT) setHasMore(false);
      setRewardsOffer((prevOffers: any) => [...prevOffers, ...newOffers]);
      setOffset((prevOffset) => prevOffset + LIMIT);
      setIsLoading(false);
    } catch (error) {
      handleError(error as AxiosError);
    }
  }, [offset, hasMore]);

  useEffect(() => {
    if (isLoading) {
      document.body.classList.add(c["no-scroll"]);
    } else {
      document.body.classList.remove(c["no-scroll"]);
    }
  }, [isLoading]);

  const handleScroll = () => {
    const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    const scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
    const clientHeight = document.documentElement.clientHeight || window.innerHeight;

    if (scrollHeight - scrollTop <= clientHeight + 100 && !isLoading && hasMore) {
      if (activeTab != "Surveys") loadOffers();
    }
  };

  function detectMobileOS() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // iOS detection
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }

    // Android detection
    if (/android/i.test(userAgent)) {
      return "Android";
    }

    // If neither iOS nor Android
    return "";
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isLoading, hasMore, offset]);

  return (
    <>
      {isLoading && (
        <div className={c["spinner-overlay"]}>
          <Spinner size="normal" />
        </div>
      )}
      <div className={c["container"]}>
        <div className={c["rewards-stats-and-tabs"]}>
          <div className={c["stats"]}>
            <div>
              <span className={c["stats-label"]}>Today</span>
              <span className={c["stats-value"]}>
                {sumAmountsToday && currency(configuration!.currency_name!, sumAmountsToday)}
              </span>
            </div>
            <div className={c["divider"]}></div>
            <div>
              <span className={c["stats-label"]}>Last 30 Days</span>
              <span className={c["stats-value"]}>
                {sumAmounts30 && currency(configuration!.currency_name!, sumAmounts30)}
              </span>
            </div>
          </div>
          {configuration?.surveys_enabled && configuration.offers_enabled && (
            <div className={c["tabs"]}>
              {configuration?.surveys_enabled && (
                <div
                  className={`${c["tab"]} ${activeTab === "Surveys" ? c["active"] : ""}`}
                  onClick={() => setActiveTab("Surveys")}
                >
                  Surveys
                </div>
              )}
              {configuration?.offers_enabled && configuration?.language == "en-us" && (
                <div
                  className={`${c["tab"]} ${activeTab === "Offers" ? c["active"] : ""}`}
                  onClick={() => setActiveTab("Offers")}
                >
                  Offers
                </div>
              )}
              <div
                className={c["tab-background"]}
                style={{
                  left: isSingleTab ? "2px" : activeTab === "Surveys" ? "2px" : "calc(50% + -2px)",
                  width:
                    configuration?.surveys_enabled &&
                    configuration?.offers_enabled &&
                    configuration?.language == "en-us"
                      ? "50%"
                      : "98.8%",
                }}
              ></div>
            </div>
          )}
        </div>
        <div className={c["rewards-page"]}>
          <div className={c["rewards-container"]}>
            {activeTab === "Surveys" &&
              (rewardsSurvey && rewardsSurvey.length > 0 ? (
                rewardsSurvey.map((reward: Reward, index: number) => (
                  <RewardSurveyCardItem
                    key={index}
                    reward={reward}
                    currencyName={configuration!.currency_name!}
                  />
                ))
              ) : (
                <p className={c["no-content-message"]}>No Survey Rewards</p>
              ))}{" "}
            {activeTab === "Offers" &&
              (rewardsOffer && rewardsOffer.length > 0 ? (
                rewardsOffer.map((offer: Offer) => (
                  <RewardOfferCardItem
                    key={offer.id}
                    onModalOpen={() => openModal(offer)}
                    offer={offer}
                    currencyName={configuration!.currency_name!}
                  />
                ))
              ) : (
                <p className={c["no-content-message"]}>No Offer Rewards</p>
              ))}
          </div>
        </div>
        <MainFooter style={{}} />
      </div>
      <Modal show={modalOpen} onClose={closeModal}>
        <RewardModalContent modalContent={modalContent} onOpenQrModal={openQrModal} />
      </Modal>
      <QrModal show={qrModalOpen} qrCode={qrCode} onClose={closeQrModal}/>
    </>
  );
}

export default RewardsPage;
