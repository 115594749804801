export function transformCurrency(
  currencyName: string,
  currencyValue: number,
  omitCurrencyName = false
) {
  if (currencyName === "cents") {
    const dollars = currencyValue / 100;

    if (omitCurrencyName) {
      return dollars;
    }

    return dollars.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  } else if (currencyName === "euros") {
    const euros = currencyValue / 100;

    if (omitCurrencyName) {
      return euros;
    }

    return (
      euros.toLocaleString("nl-NL", { minimumFractionDigits: 2 }) + "\u20AC"
    );
  }

  const value = !Number.isInteger(currencyValue)
    ? currencyValue.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    : currencyValue.toLocaleString();

  if (omitCurrencyName) {
    return value;
  }

  return value + " " + currencyName;
}
