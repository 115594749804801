import { useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import BaseCallbackPage, { CallbackStatuses } from "./BaseCallbackPage";
import useInBrainCallback from "../../hooks/useInBrainCallback";

function DynataEngageCallbackPage() {
  const [searchParams] = useSearchParams();

  let callbackStatus =
    searchParams.get("disposition") === "1"
      ? CallbackStatuses.Complete
      : CallbackStatuses.Disqualification;

  const {
    isLoading,
    reward,
    displayedReward,
    currencyName,
    isRewardVisibilityDisabled,
    isDisqualificationDisabled,
    showAnimatedCta,
    handleCallback,
    navigateNext
  } = useInBrainCallback(callbackStatus);

  const callbackUrl = location.href;
  const callbackPath = "/external-provider-callback/dynata-engage";

  const firstRender = useRef(true);
  useEffect(() => {
    const url = `${process.env.API_URL}${callbackPath}`;

    if (firstRender.current) {
      handleCallback(url, callbackUrl);
      firstRender.current = false;
    }
  }, []);

  return (
    <BaseCallbackPage
      isLoading={isLoading}
      callbackStatus={callbackStatus}
      currencyName={currencyName!}
      reward={reward}
      displayedReward={displayedReward}
      isRewardVisibilityDisabled={isRewardVisibilityDisabled}
      isDisqualificationDisabled={isDisqualificationDisabled}
      showAnimatedCta={showAnimatedCta}
      closeView={navigateNext}
    />
  );
}

export default DynataEngageCallbackPage;
