// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".eKYmBP8B5WPbSoqrx1fB {\n  display: -webkit-flex;\n  display: -moz-box;\n  display: flex;\n  -webkit-align-items: center;\n     -moz-box-align: center;\n          align-items: center;\n  width: 100%;\n  max-width: 400px;\n  margin: 20px auto;\n}\n\n.ZD3F45xcCmesAWlqS1Ia {\n  width: 100%;\n}\n\n.ZD3F45xcCmesAWlqS1Ia:hover {\n  opacity: 1;\n}\n\n.UhJUGf2ZIOElbGxkUtAt {\n  margin-left: 10px;\n  color: var(--primary-color);\n}\n", "",{"version":3,"sources":["webpack://./src/components/ui/Slider.module.css"],"names":[],"mappings":"AAAA;EACE,qBAAa;EAAb,iBAAa;EAAb,aAAa;EACb,2BAAmB;KAAnB,sBAAmB;UAAnB,mBAAmB;EACnB,WAAW;EACX,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,iBAAiB;EACjB,2BAA2B;AAC7B","sourcesContent":[".slider-container {\n  display: flex;\n  align-items: center;\n  width: 100%;\n  max-width: 400px;\n  margin: 20px auto;\n}\n\n.slider {\n  width: 100%;\n}\n\n.slider:hover {\n  opacity: 1;\n}\n\n.slider-percentage {\n  margin-left: 10px;\n  color: var(--primary-color);\n}\n"],"sourceRoot":""}]);
// Exports
var _1 = "eKYmBP8B5WPbSoqrx1fB";
export { _1 as "slider-container" };
export var slider = "ZD3F45xcCmesAWlqS1Ia";
var _2 = "UhJUGf2ZIOElbGxkUtAt";
export { _2 as "slider-percentage" };
export default ___CSS_LOADER_EXPORT___;
