import { requestSupplierApiToken } from "api/auth";
import { loadSingleSupplierSurvey } from "api/supplierSurveys";
import spinnerIcon from "assets/images/inbrain-spinner.png";
import { PublisherConfig } from "interfaces/PublisherConfig";
import { SupplierSurvey } from "interfaces/SupplierSurvey";
import { sha256 } from "js-sha256";
import { useEffect, useRef } from "react";
import { createSearchParams, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ProductTypes } from "utils/constants/enums";
import { DEFAULT_LANG } from "utils/constants/keys";
import {
  setCacheConfiguration,
  clearAllFromLocalStorage,
  saveColors,
  savePublisherFlags,
  setCurrentSurvey,
} from "utils/functions/configurationUtil";
import { initSupplierContext } from "utils/functions/supplierContextUtil";
import { loadTranslationResource } from "../../i18n";
import * as c from "./SupplierSurveyPage.module.css";
import { Configuration } from "interfaces/Configuration";
import { useColorContext } from "contexts/colorContext";

export enum LinkTypesEnum {
  Survey = "Survey",
  ClientRedirect = "ClientRedirect",
}

function SupplierSurveyPage() {
  const navigate = useNavigate();
  const { surveyId } = useParams();
  const [searchParams] = useSearchParams();
  const { setPrimaryColorCtx, setSecondaryColorCtx } = useColorContext();

  const configuration: Configuration = {
    hashed_publisher_app_uid: sha256.hex(searchParams.get("userId") || ""),
    publisher_app_uid: searchParams.get("userId") || "",
    referrer: window.location.href,
    product_type: ProductTypes.SupplierApi,
    publisher_mid: searchParams.get("mid") ?? undefined,
  };

  const entryLink = window.location.href;

  const firstRender = useRef(true);
  useEffect(() => {
    if (firstRender.current) {
      clearAllFromLocalStorage();

      Promise.all([getToken(entryLink), loadSurvey(surveyId!, entryLink)]).then(([_, response]) =>
        processRequest(response)
      );
    }

    firstRender.current = false;
  }, []);

  function getToken(entryLink: string) {
    return requestSupplierApiToken(entryLink);
  }

  function loadSurvey(surveyId: string, entryLink: string): Promise<SupplierSurvey> {
    initSupplierContext(entryLink);

    return loadSingleSupplierSurvey(surveyId, entryLink);
  }

  async function processRequest(supplierSurvey: SupplierSurvey) {

    if (!isSupplierSurveyValid(supplierSurvey)) {
      navigate({
        pathname: "/error",
        search: createSearchParams({
          status: "2001",
        }).toString(),
      });
      return;
    }

    const { linkType, link, reward } = supplierSurvey;

    if (linkType === LinkTypesEnum.ClientRedirect) {
      window.location.href = link;
      return;
    }
    if (linkType === LinkTypesEnum.Survey) {
      const {
        userLanguage,
        originalAppUserId,
        externalSurveyId,
        virtualSurveyId,
        surveyProviderId,
        publisherConfig,
        searchId,
        isProfiler,
        publisherMid,
        reward,
        deviceId,
        isProfilerSurveyStarted
      } = supplierSurvey;

      const { currency, colors, flags } = publisherConfig as PublisherConfig;

      configuration.currency_name = currency.name;
      configuration.currency_rate = currency.rate;
      configuration.device_id = deviceId;

      loadTranslationResource(userLanguage ?? DEFAULT_LANG);
      savePublisherFlags(flags);

      const customColors = colors ? colors : { primaryColor: "#00a5ed", secondaryColor: "#f27536" };

      savePublisherFlags(flags);
      saveColors(customColors);

      if (isProfiler){
        configuration.profiler_reward = reward;
        configuration.is_profiler_survey_started = isProfilerSurveyStarted;
      }

      setCacheConfiguration(configuration);
      
      setPrimaryColorCtx(customColors.primaryColor ?? "");
      setSecondaryColorCtx(customColors.secondaryColor ?? "");
      document.body.style.setProperty("--primary-color", customColors.primaryColor);
      document.body.style.setProperty("--secondary-color", customColors.secondaryColor);

      if (isProfiler) {
        navigate({
          pathname: "/profiler",
          search: createSearchParams({
            supplierSurveyId: externalSurveyId!
          }).toString(),
        });
      } else {

        setCurrentSurvey(
          externalSurveyId!,
          ProductTypes.SupplierApi,
          surveyProviderId!,
          virtualSurveyId,
          searchId,
          link
        );

        navigate({
          pathname: "/pre-survey",
          search: createSearchParams({
            link: link,
            externalId: externalSurveyId!,
            virtualId: virtualSurveyId || "",
            externalProviderId: surveyProviderId!.toString(),
          }).toString(),
        });
      }
    }
  }

  function isSupplierSurveyValid(supplierSurvey: SupplierSurvey): boolean {
    const { linkType, publisherConfig } = supplierSurvey;
    if (!supplierSurvey) {
      return false;
    }

    if (linkType === LinkTypesEnum.Survey && !publisherConfig) {
      return false;
    }

    return true;
  }

  return (
    <div className={c["supplier-survey-page"]}>
      <div className={c["spinner-container"]}>
        <img src={spinnerIcon} />
        <div className={c["spinner"]}></div>
        <div className={c["backdrop"]}>
          <div className={c["level-1"]}></div>
          <div className={c["level-2"]}></div>
        </div>
      </div>
    </div>
  );
}

export default SupplierSurveyPage;
