import { useEffect, useRef } from "react";
import { AxiosError } from "axios";
import { createSearchParams, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useErrorHandler } from "contexts/errorContext";
import { useTranslation } from "react-i18next";
import Spinner from "components/ui/Spinner";

import * as c from "./ConfigurationPage.module.css";

function InitialConfigurationPage() {
  const { t } = useTranslation();
  const params = useParams();
  const [searchParams] = useSearchParams();
  const handleError = useErrorHandler();
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate("/configuration");
    }, 1000);

    // Cleanup the timeout in case the component unmounts before the timeout is completed
    return () => clearTimeout(timer);
  }, [navigate]);


  return (
    <div className={c["configuration-page"]}>
      <Spinner size="normal" />
    </div>
  );
}

export default InitialConfigurationPage;
