import { useTranslation } from "react-i18next";
import * as c from "./Text.module.css";
import { PurifyText } from "utils/functions/purifyUtil";

interface TextProps {
  id: number;
  questionName: string;
  questionValidator: string;
  exampleLabel: string;
  onSetValue: (value: any) => void;
}

function Text({ id, questionName, questionValidator, exampleLabel, onSetValue }: TextProps) {
  const { t } = useTranslation();

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const regex = new RegExp(questionValidator);
    const { value } = event.target;

    if (regex.test(value)) {
      onSetValue(value);
    } else {
      onSetValue(null);
    }
  };

  return (
    <div className={c["question-type-container"]}>
      <span className={c["question-text"]} dangerouslySetInnerHTML={{ __html: PurifyText(questionName) }}></span>
      <input
        type="text"
        placeholder={`${t("INBRAIN_THEME.PROFILER_TEXT_QUESTION.EXAMPLE")}${exampleLabel}`}
        onChange={handleInputChange}
      />
    </div>
  );
}

export default Text;
