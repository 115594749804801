import { useEffect, useState, forwardRef, useImperativeHandle, useRef } from "react";
// import { motion } from "framer-motion";
import SelectOneItem from "components/profiler/questionItems/SelectOneItem";
import SelectMoreItem from "components/profiler/questionItems/SelectMoreItem";
import { PurifyText } from "utils/functions/purifyUtil";

import * as c from "./SelectOne.module.css";

type Option = {
  id: number;
  value: string;
  isRequired: boolean;
  groupId: number | null;
  groupName: string;
};

interface SelectOneProps {
  id: number;
  question: string;
  options: Option[];
  onSetValue: (id: number) => void;
}

const SelectOne = forwardRef(({ id, question, options, onSetValue }: SelectOneProps, ref) => {
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [openDropdown, setOpenDropdown] = useState<string | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleOptionSelect = (value: string, id: number) => {
    setSelectedOption(value);
    onSetValue(id);
    setOpenDropdown(null);
  };

  const handleDropdownToggle = (groupName: string) => {
    setOpenDropdown(openDropdown === groupName ? null : groupName);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
      setOpenDropdown(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useImperativeHandle(ref, () => ({
    resetSelection: () => setSelectedOption(null),
  }));

  const nonGroupedOptions = options.filter((option) => option.groupName === "");

  const groupedOptions = options.reduce((acc, option) => {
    if (option.groupName !== "") {
      acc[option.groupName] = acc[option.groupName] || [];
      acc[option.groupName].push(option);
    }
    return acc;
  }, {} as { [key: string]: Option[] });

  const itemVariants = {
    hidden: { opacity: 0, y: 300 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        stiffness: 50,
        damping: 10,
        duration: 0.1,
        ease: "easeOut",
      },
    },
  };

  return (
    <>
      <span className={c["question-text"]} dangerouslySetInnerHTML={{ __html: PurifyText(question) }}></span>
      {/* <motion.div ref={containerRef}> */}
        {nonGroupedOptions.map((option) => (
          <SelectOneItem
            key={option.id}
            value={option.value}
            isSelected={selectedOption === option.value}
            onSelect={() => handleOptionSelect(option.value, option.id)}
            usedInMore={false}
          />
        ))}
        {Object.entries(groupedOptions).map(([groupName, groupOptions]) => (
          <SelectMoreItem
            key={groupName}
            groupName={groupName}
            groupOptions={groupOptions}
            selectedOption={selectedOption}
            onSelect={handleOptionSelect}
            openDropdown={openDropdown}
            onDropdownToggle={handleDropdownToggle}
          />
        ))}
      {/* </motion.div> */}
    </>
  );
});

export default SelectOne;
