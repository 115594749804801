import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getCachedConfiguration } from "utils/functions/configurationUtil";
import { DEFAULT_LANG } from "utils/constants/keys";
import { JSONtext } from "../../../assets/locales/toc-en-us";
import { ES_ES_JSONtext } from "../../../assets/locales/toc-es-es";
import { PT_BR_JSONtext } from "../../../assets/locales/toc-pt-br";

import * as c from "./PrivacyTab.module.css";

export default function PrivacyTab() {
  const [ppTranslation, setPpTranslation] = useState<any>();
  const { t } = useTranslation();
  const configuration = getCachedConfiguration();
  const storedLanguage = configuration?.language || DEFAULT_LANG;

  const handlePpTranslation = () => {
    switch (storedLanguage) {
      case "es-es":
        return ES_ES_JSONtext;
      case "pt-br":
        return PT_BR_JSONtext;
      default:
        return JSONtext;
    }
  };

  useEffect(() => {
    setPpTranslation(handlePpTranslation());
  }, []);

  return (
    <div className={c["toc-animate"]}>
      <div className={c["toc-intoduction-header"]}>
        <h2>{t("INBRAIN_THEME.PROFILER_TOC_QUESTION.TABS.PP")}</h2>
        <span>{t("TOC_QUESTION.LAST_UPDATED_TEXT")}</span>
        <span>
          <strong>
            <u>{t("TOC_QUESTION.LAST_UPDATED_DATE")}</u>
          </strong>
        </span>
      </div>
      {ppTranslation && (
        <div
          className={c.section}
          dangerouslySetInnerHTML={{ __html: ppTranslation.PRIVACY_POLICY }}
        />
      )}
    </div>
  );
}
