import { useTranslation } from "react-i18next";

import * as c from "./TocTab.module.css";

export default function TocTab() {
  const { t } = useTranslation();
  return (
    <div className={c["toc-animate"]}>
      <div className={c["toc-intoduction-header"]}>
        <h2>{t("INBRAIN_THEME.PROFILER_TOC_QUESTION.TABS.TOC")}</h2>
        <span>{t("TOC_QUESTION.LAST_UPDATED_TEXT")}</span>
        <span>
          <strong>
            <u>{t("TOC_QUESTION.LAST_UPDATED_DATE")}</u>
          </strong>
        </span>
        <h3>Introduction</h3>
      </div>
      <div className={c.section}>
        <ol>
          <li>
            <p>
              <strong>inBrain and Our Services.</strong> Welcome to the inBrain Survey Platform.
              inBrain, LLC("<span>inBrain</span>", "<span>we</span>", "<span>us</span>", or "
              <span>our</span>") provides a website,{" "}
              <a href="https://inbrain.ai">https://inbrain.ai</a> (the "<span>Website</span>"or "
              <span>Site</span>"), a mobile application (the "<span>App</span>"),and various
              survey-and marketing-related tools, activities, information, and benefits(together
              with the Website and the App, the "<span>Services</span>") for our users ("
              <span>Users</span>"), including:
            </p>
            <ol className={c["alpha-order"]}>
              <li className={c["mb-2"]}>
                The creation and targeting of surveys and the management of costs associated there
                with("<span>Survey Creation</span>" by "<span>Survey Creator</span>" Users);
              </li>
              <li>
                The distribution and provision of surveys and the realization of benefits
                therefrom("<span>Survey Publishing</span>" by "<span>Survey Publisher</span>"
                Users); and
              </li>
              <li>
                The viewing, participation in, or otherwise interaction with surveys and
                survey-related content and the realization of benefits therefrom ("
                <span>SurveyTaking</span>" by "<span>Survey Taker</span>" Users).
              </li>
            </ol>
          </li>
          <li>
            <p>
              <strong>The Platform.</strong> We may provide you Services via our "
              <span>Platform</span>", which may variously comprise:
            </p>
            <ol className={c["alpha-order"]}>
              <li className={c["mb-2"]}>
                Our Website, servers, databases, storage media, and network and computing
                infrastructure;
              </li>
              <li>
                Our applications, application programming interfaces ("<span>APIs</span>"), software
                development kits ("<span>SDKs</span>"), programs, and software (collectively, the "
                <span>Software</span>"); and
              </li>
              <li>
                Third party websites, programs, applications, software, or platforms (together, "
                <span>Third Party Platforms"</span>).
              </li>
            </ol>
          </li>
        </ol>
      </div>
      <div className={c.section}>
        <h3>Terms and Conditions</h3>
        <ol>
          <li>
            <p>
              <strong>Generally.</strong> The following terms and conditions, together with any
              documents expressly incorporated herein by reference (collectively, these "
              <span>Terms and Conditions</span>"or "<span>Terms</span>"), govern your relationship
              with us, all your interactions with and uses of the Platform and the Services, your
              establishment and maintenance of an account with us (a "<span>User Account</span>"),
              and any communications, interactions, transmissions, transactions, or exchanges
              between you and us.These Terms and Conditions can be found at{" "}
              <a href="https://inbrain.ai/privacy">https://inbrain.ai/terms</a> and in the Terms
              page of the App (the "<span>Terms Page</span>").
            </p>
          </li>

          <li>
            <p>
              <strong>Accepting These Terms and Conditions.</strong> Please read these Terms and
              Conditions carefully. BY CLICKING THE "ACCEPT" BUTTON, CHECKING THE APPROPRIATE BOX TO
              ACCEPT THESE TERMS AND CONDITIONS, OR BY ACCESSING OR USING THE PLATFORM OR THE
              SERVICES YOU THEREBY ACCEPT THESE TERMS AND CONDITIONS AND AGREE THAT YOU ARE LEGALLY
              BOUND TO THE TERMS HEREOF. WITHOUT LIMITING THE FOREGOING, YOU ACKNOWLEDGE THAT YOUR
              USE OF THE PLATFORM AND/OR SERVICES CONSTITUTES AN ACCEPTANCE OF THESE TERMS AND
              CONDITIONS.IF YOU ACCEPT THESE TERMS AND CONDITIONS ON BEHALF OF A BUSINESS,
              ORGANIZATION, OR OTHER ENTITY, YOU REPRESENT AND WARRANT THAT YOU HAVE THE POWER AND
              AUTHORITY TO BIND SUCH BUSINESS, ORGANIZATION, OR OTHER ENTITY HERETO, AND YOUR
              ACCEPTANCE HEREOF WILL BE TREATED AS THE AGREEMENT HERETO OF SUCH BUSINESS,
              ORGANIZATION OR OTHER ENTITY.IF YOU ARE UNWILLING TO AGREE TO THESE TERMS AND
              CONDITIONS, OR YOU DO NOT HAVE THE RIGHT, POWER, AND AUTHORITY TO ACTON BEHALF OF AND
              BIND THE BUSINESS, ORGANIZATION, OR OTHER ENTITY YOU REPRESENT, DO NOT CLICK THE
              "ACCEPT" BUTTON AND DO NOT ACCESS OR OTHERWISE USE THE PLATFORM OR THE SERVICES.
            </p>
          </li>

          <li>
            <p>
              <strong>Privacy Policy.</strong> The inBrain Privacy Policy (the "
              <span>Privacy Policy</span>", and together with these Terms and Conditions, this "
              <span>Agreement</span>") is expressly incorporated herein by reference and is an
              integral part hereof. The Privacy Policy can be found at
              <a href="https://inbrain.ai/privacy">https://inbrain.ai/privacy</a> and in the Privacy
              page of the App (the "<span>Privacy Page</span>").
            </p>
          </li>

          <li>
            <p>
              <strong>Contacting Us.</strong> If you have any feedback, comments, questions,requests
              for technical support, or requests for information regarding these Terms, please
              direct them to the appropriate "<span>Correspondence Address</span>" below:
            </p>

            <ul className={c["ml-5"]}>
              <li>
                If by Mail:
                <ul className={c["ml-5"]}>
                  <li>
                    <span>inBrain, LLC</span>
                  </li>
                  <li>
                    <span>3423 Piedmont Road NE, Ste 560</span>
                  </li>
                  <li>
                    <span>Atlanta, GA 30305</span>
                  </li>
                </ul>
              </li>

              <li>
                If by E-Mail:
                <ul className={c["ml-5"]}>
                  <li>
                    Billing related questions: <span>billing@inbrain.ai</span>
                  </li>
                  <li>
                    Legal and Terms related questions: <span>legal@inbrain.ai</span>
                  </li>
                  <li>
                    Copyright related questions: <span>copyright@inbrain.ai</span>
                  </li>
                  <li>
                    Support related questions: <span>support@inbrain.ai</span>
                  </li>
                  <li>
                    Privacy related questions: <span>privacy@inbrain.ai</span>
                  </li>
                  <li>
                    Security related questions: <span>security@inbrain.ai</span>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ol>
      </div>
      <div className={c.section}>
        <h3>Changes to These Terms and Conditions</h3>
        <ol>
          <li>
            <p>
              <strong>Changes.</strong> We aim to continually improve the Platform and theServices,
              and may need to change these Terms from time to time.WE RESERVE THE EXCLUSIVE RIGHT TO
              MAKE CHANGES TO THESE TERMS AND CONDITIONS AT ANY TIME, IN OUR SOLE DISCRETION.Changes
              to these Terms will be posted to the TermsPage.
            </p>
          </li>

          <li>
            <p>
              <strong>Automatic Acceptance.</strong> Your access to and use of the Platform and the
              Services, at any time,constitutes your agreement to be bound by the terms of
              theseTerms and Conditions then in effect. YOU ACKNOWLEDGE AND AGREE THAT YOU ACCEPT
              THESE TERMS AND CONDITIONS(AS MAY BE AMENDED, UPDATED, OR MODIFIED FROM TIME TO TIME)
              EACH TIME YOU USE THE PLATFORM OR THE SERVICES IN ANY MANNER.Therefore, we encourage
              you to review these Terms regularly as you shall be bound by them.
            </p>
          </li>

          <li>
            <p>
              <strong>Withdrawal of Acceptance.</strong> If, within thirty (30) days of us posting
              any update hereto, you decide that you do not agree to theTerms as updated, you may
              withdraw your acceptance to the updatedTerms by providing us with written notice of
              your withdrawal to the Correspondence Address for Legal and Terms related questions.
              Upon providing us with such written notice, you shall no longer be authorized to
              access or use the Platform or the Services, and you must immediately cease in doing
              so.Withdrawal of acceptance of updated Terms in no way modifies your acceptance
              (whether affirmative or automatic under Section3.2hereof) of, or your obligations
              under, the terms of the Terms and Conditions that had prior been in effect.
            </p>
          </li>
        </ol>
      </div>
      <div className={c.section}>
        <h3>Eligibility</h3>

        <ol>
          <li>
            <p>
              <strong>Age.</strong> By accessing our using the Services, you represent and warrant
              that you are at least thirteen(13) years of age(the "<span>Minimum Age</span>"). IF
              YOU ARE UNDER THE MINIMUM AGE, PLEASE DO NOT ATTEMPT TO CREATE A USER ACCOUNT OR SEND
              ANY INFORMATION ABOUT YOURSELF TO US—YOU MAY NOT, UNDER ANY CIRCUMSTANCES OR FOR ANY
              REASON, USE THE SERVICES.
            </p>
          </li>

          <li>
            <p>
              <strong>Legality.</strong> You are solely responsible for ensuring that this
              Agreement, and your use of the Platform and the Services as governed hereby, is in
              compliance with all applicable laws, rules,and regulations. If your use of the
              Platform or the Services, or any functionality provided or activity enabled thereby,
              is prohibited or conflicts with any applicable law, rule,or regulation, you may not,
              under any circumstances or for any reason, use the Platform or the Services.
            </p>
          </li>

          <li>
            <p>
              <strong>Criteria.</strong> We may, in our sole discretion, refuse to offer the
              Services to any person or entity and change the eligibility criteria for use thereof
              at any time.
            </p>
          </li>
        </ol>
      </div>
      <div className={c.section}>
        <h3>User Accounts</h3>

        <ol>
          <li>
            <p>
              <strong>Generally.</strong> In order to use the Services, you must establish and
              maintain an account with us (a "<span>User Account</span>" or "<span>Account</span>").
              In the course of establishing and maintaining your Account, and in the course of using
              the Platform and the Services, you may submit information to us, and we may gather
              information from you or your device.Our Privacy Policy (available at the PrivacyPage)
              governs how we collect and handle that information. Please keep your the information
              in your Account current and correct at all times.
            </p>
          </li>
        </ol>
      </div>
      <div className={c.section}>
        <h3>Copyright</h3>

        <ol>
          <li>
            <p>
              <strong>Generally.</strong> We follow the framework provided by theDigital Millennium
              Copyright Act ("<span>DMCA</span>") for removal and restoration of content alleged to
              be infringingUnited States copyright law. Although this Section 11 provides a brief
              summary of the process, please refer to 17 U.S.C. § 512 for detailed information.
            </p>
          </li>

          <li>
            <p>
              <strong>Takedown Notice.</strong> If you are a copyright owner or an agent thereof and
              believe that any content on the Services infringes upon your copyrights, you may
              submit a notification pursuant to the DMCA by providing us with written notice,
              compliant with all the requirements of 17 U.S.C § 512(c)(3), to the Correspondence
              Address for Copyright related questions (a "<span>Takedown Notice</span>").Upon
              receipt of a valid Takedown Notice, we will investigate and remove material as
              necessary. You will be notified of the results of such investigation.
            </p>
          </li>

          <li>
            <p>
              <strong>User Recourse.</strong> If you are a User whose material has been removed from
              the Services pursuant to a Takedown Notice, we will notify you of the removal and
              provide you a copy of the Takedown Notice via the email address you provide us in your
              User Account. If you wish to dispute such removal, you may submit a notification
              pursuant to the DMCA by providing uswith written notice, compliant with all the
              requirements of 17 U.S.C § 512(g)(3),to the Correspondence Address for Copyright
              related questions (a "<span>Counter-Notice</span>"). Upon receipt of a valid
              Counter-Notice, we will restore the removed material after ten (10) business
              days,notify the copyright owner that submitted the Takedown Notice of such pending
              restoration, and provide them a copy of the Counter-Notice.
            </p>
          </li>
        </ol>
      </div>
      <div className={c.section}>
        <h3>Links</h3>

        <ol>
          <li>
            <p>
              <strong>Links to the Website.</strong> You may link to our homepage, provided you do
              so in a way that is fair and legal and does not damage our reputation or take
              advantage of it, but you must not establish a link in such a way as to suggest any
              form of association, approval, or endorsement on our part without our express written
              consent.You agree to cooperate with us in causing any unauthorized framing or linking
              immediately to cease. We reserve the right to withdraw linking permission or disable
              any links at any time without notice, in our sole discretion.
            </p>
          </li>

          <li>
            <p>
              <strong>Links from the Website.</strong> The Platform may contain links to sites and
              resources provided by third parties, these links are provided for your convenience
              only. This includes links contained in advertisements, including banner advertisements
              and sponsored links. We have no control over the contents of those sites or resources,
              and accept no responsibility for them or for any loss or damage that may arise from
              your use of them. If you decide to access any such links or content, you do so
              entirely at your own risk and subject to the terms and conditions set forth by the
              applicable third party.
            </p>
          </li>
        </ol>
      </div>
      <div className={c.section}>
        <h3>Warranties</h3>

        <ol>
          <li>
            <p>You represent, warrant,and covenant that:</p>

            <ol className={c["alpha-order"]}>
              <li className={c["mb-2"]}>
                You are, and shall at all times be, in full compliance with all applicable laws and
                regulations in your access and use of the Website and Services.
              </li>

              <li>
                You own, possess and/or control all of the rights in and to your materials that you
                transmit through the Services; and
              </li>

              <li>
                You have the right, title, and interest to all assets in connection with which you
                commence transactions through the use of the Services (or that you have all required
                permission from the rightful titleholder of the same).
              </li>
            </ol>
          </li>
        </ol>
      </div>
      <div className={c.section}>
        <h3>Disclaimer of Warranties</h3>

        <ol>
          <li>
            <p>
              YOU EXPRESSLY UNDERSTAND, ACKNOWLEDGE, AND AGREE THAT YOUR USE OF THE WEBSITE,
              CONTENT, AND SERVICES SHALL BE SOLELY AND EXCLUSIVELY AT YOUR OWN RISK. THE WEBSITE,
              ALL CONTENT, ALL SERVICES (INCLUDING WITHOUT LIMITATION ANY TRANSACTIONS THAT YOU MAY
              INITIATE, ENTER INTO, OR OTHERWISE PARTICIPATE IN) ARE PROVIDED TO YOU ON AN "AS IS"
              AND "AS AVAILABLE" BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR
              IMPLIED. NEITHER WE NOR ANY PERSON ASSOCIATED WITH US MAKES ANY WARRANTY OR
              REPRESENTATION WITH RESPECT TO THE SPEED, ACCURACY, TIMELINESS, COMPLETENESS,
              SECURITY, RELIABILITY, EFFECTIVENESS, AVAILABILITY, VALUE, OR ANY OTHER QUALITY OF THE
              WEBSITE, ANY CONTENT, OR THE SERVICES.
            </p>
          </li>

          <li>
            <p>
              WE HEREBY DISCLAIM ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY
              OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY,
              NON-INFRINGEMENT OF INTELLECTUAL PROPERTY RIGHTS, AND FITNESS FOR PARTICULAR PURPOSE.
            </p>
          </li>

          <li>
            <p>
              WITHOUT LIMITING THE FOREGOING, NEITHER WE NOR ANYONE ASSOCIATED WITH US REPRESENTS OR
              WARRANTS:
            </p>

            <ol className={c["alpha-order"]}>
              <li className={c["mb-2"]}>
                THAT THE WEBSITE, ANY CONTENT, THE SERVICES (INCLUDING WITHOUT LIMITATION ANY
                TRANSACTIONS THAT YOU MAY INITIATE, ENTER INTO, OR OTHERWISE PARTICIPATE IN) WILL BE
                ACCURATE, RELIABLE, SECURE, ERROR-FREE, EFFECTIVE, COMPLETE, OR UNINTERRUPTED, OR
                THAT THE WEBSITE OR SERVICES WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS;
              </li>

              <li>
                THE ACCURACY, COMPLETENESS, TIMELINESS, RELIABILITY, OR EFFECTIVENESS OF ANY CONTENT
                THAT IS PROVIDED OR OTHERWISE MADE AVAILABLE TO YOU ON OR VIA THE WEBSITE OR
                SERVICES; OR
              </li>

              <li>
                THE SPEED, ACCURACY,TIMELINESS, COMPLETENESS, SECURITY, RELIABILITY, EFFECTIVENESS,
                AVAILABILITY, VALUE, OR ANY OTHER QUALITY OF I) THE SERVICES; OR II)OF ANY
                TRANSACTIONS THAT YOU MAY INITIATE, ENTER INTO, OR PARTICIPATE IN THROUGH OR VIA THE
                SERVICES OR WEBSITE.
              </li>
            </ol>
          </li>
        </ol>
      </div>
      <div className={c.section}>
        <h3>Limitation on Liability; Remedies</h3>

        <ol>
          <li>
            <p>
              WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE
              ATTACK, INTRUSION, BREACH, VIRUS, MALWARE, SPYWARE, TROJAN, ROOTKIT, OR OTHER
              TECHNOLOGICALLY HARMFUL MATERIAL OR ACT THAT IMPACTS OR INTERACTS IN ANY WAY WITH YOUR
              COMPUTER EQUIPMENT, OPERATING SYSTEM, PROGRAMS, APPLICATIONS, DATABASES, OR DATA IN
              CONNECTION WITH OR AS A RESULT OF YOUR USE OF OR INTERACTION WITH THE WEBSITE OR
              SERVICES OR ANY MATERIAL POSTED THERETO OR LINKED THEREFROM, ANY OTHER WEBSITE,
              CHANNEL, PLATFORM, OR SOCIAL MEDIA ACCOUNT OPERATED BY INBRAIN, OR ANY COMMUNICATION
              FROM US.
            </p>
          </li>

          <li>
            <p>
              YOUR USE OF THE WEBSITE AND SERVICES IS STRICTLY AT YOUR OWN RISK, AND WE EXPRESSLY
              DISCLAIM ANY AND ALL LIABILITY AND RESPONSIBILITY ARISING FROM YOUR USE THEREOF
              (INCLUDING INABILITY TO USE), OR RELIANCE PLACED BY YOU THEREON.
            </p>
          </li>

          <li>
            <p>
              IN NO EVENT WILL INBRAIN, ITS EMPLOYEES, AGENTS, OFFICERS, DIRECTORS,OR AFFILIATES BE
              LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN
              CONNECTION WITH THE WEBSITE, SERVICES, OR SOFTWARE, INCLUDING WITHOUT LIMITATION, ANY
              DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, AND
              INCLUDING BUT NOT LIMITED TO, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE,
              LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF
              GOODWILL, BREACH OF PRIVACY, UNAUTHORIZED ACCESS OF YOUR DATA OR INFORMATION BY THIRD
              PARTIES, LOSS OF ASSETS, LOSS OF YOUR DATA, AND WHETHER CAUSED BY TORT (INCLUDING
              NEGLIGENCE), BREACH OF CONTRACT OR OTHERWISE, EVEN IF FORESEEABLE.
            </p>
          </li>

          <li>
            <p>
              IN CONNECTION WITH ANY CLAIM FOR DAMAGES IN CONNECTION WITH THE WEBSITE, CONTENT, OR
              SERVICES, OR OTHERWISE ARISING OUT OF OR IN CONNECTION WITH THESE TERMS AND
              CONDITIONS, IN NO EVENT SHALL OUR LIABILITY TO YOU OR TO ANY THIRD PARTY EXCEED: I) IF
              YOU ARE A SURVEY CREATOR, THE AMOUNT OF SURVEY COSTS THAT YOU PAID TO US DURING THE
              SIX (6) MONTH PERIOD IMMEDIATELY PRECEDING THE EVENT GIVING RISE TO THE CLAIM; II) IF
              YOU ARE A SURVEY PUBLISHER, THE AMOUNT OF PUBLISHING REVENUE YOUR SURVEY CHANNELS HAVE
              GENERATED DURING THE SIX (6) MONTH PERIOD IMMEDIATELY PRECEDING THE EVENT GIVING RISE
              TO THE CLAIM; OR III) IF YOU ARE A SURVEY TAKER, TWENTY US DOLLARS ($20.00).
            </p>
          </li>
        </ol>
      </div>
      <div className={c.section}>
        <h3>Indemnification</h3>

        <ol>
          <li>
            <p>
              You agree to defend, indemnify and hold harmless inBrain, its affiliates, licensors
              and service providers, and its and their respective officers, directors, employees,
              contractors, agents, licensors, suppliers, successors and assigns from and against any
              claims, liabilities, damages, judgments, awards, losses, costs, expenses or fees
              (including reasonable attorneys' fees) arising out of or relating to your violation of
              these Terms and Conditions,your use of the Platform or the Services, your interactions
              with other Users.
            </p>
          </li>

          <li>
            <p>
              Without limiting the foregoing, we have the right to fully cooperate with any law
              enforcement authorities or court order requesting or directing us to disclose the
              identity or other information of anyone uploading, transmitting, or downloading any
              materials on or through the Website. YOU AGREE TO HOLD HARMLESS INBRAIN AND ITS
              AFFILIATES, LICENSEES AND SERVICE PROVIDERS FROM ANY CLAIMS RESULTING FROM ANY ACTION
              TAKEN BY ANY SUCH AUTHORITIES DURING OR AS A RESULT OF THEIR INVESTIGATIONS AND FROM
              ANY ACTIONS TAKEN AS A CONSEQUENCE OF INVESTIGATIONS BY EITHER INBRAIN OR SUCH
              AUTHORITIES.
            </p>
          </li>
        </ol>
      </div>
      <div className={c.section}>
        <h3>Governing Law and Jurisdiction</h3>

        <ol>
          <li>
            <p>
              All matters relating to the Website, Services,and these Terms and Conditions and any
              dispute or claim arising therefrom or related thereto (in each case, including
              non-contractual disputes or claims), shall be governed by and construed in accordance
              with the laws of the State of Georgia without giving effect to any choice or conflict
              of law provision or rule (whether of the State of Georgia or any other jurisdiction).
            </p>
          </li>
        </ol>
      </div>
      <div className={c.section}>
        <h3>Limitation on Time to File Claims</h3>

        <ol>
          <li>
            <p>
              ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THIS AGREEMENT
              OR YOUR USE OF THE PLATFORM OR THE SERVICES MUST BE COMMENCED WITHIN ONE (1) YEAR
              AFTER THE CAUSE OF ACTION ACCRUES, OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS
              PERMANENTLY BARRED.
            </p>
          </li>
        </ol>
      </div>
      <div className={c.section}>
        <h3>General</h3>

        <ol>
          <li>
            <p>
              <strong>Waiver.</strong> No waiver of by us of any term or condition set forth in this
              Agreement shall be deemed a further or continuing waiver of such term or condition or
              a waiver of any other term or condition, and any failure of inBrain to assert a right
              or provision under these Terms and Conditions shall not constitute a waiver of such
              right or provision.
            </p>
          </li>

          <li>
            <p>
              <strong>Survival.</strong> Any provisions of this Agreement that by their nature
              should survive the expiration or termination of this Agreement shall so survive.
            </p>
          </li>

          <li>
            <p>
              <strong>Severability.</strong> If any provision of this Agreement is held by a court
              or other tribunal of competent jurisdiction to be invalid, illegal,or unenforceable
              for any reason, such provision shall be eliminated or limited to the minimum extent
              such that the remaining provisions of this Agreement will continue in full force and
              effect.
            </p>
          </li>

          <li>
            <p>
              <strong>Entire Agreement.</strong> This Agreement constitutes the sole and entire
              agreement between you and inBrain with respect to the Platform and the Services, and
              supersedes all prior and contemporaneous understandings, agreements, representations
              and warranties, both written and oral, with respect to the Website.
            </p>
          </li>

          <li>
            <p>
              <strong>Headings.</strong> The section and paragraph headings in these Terms and
              Conditions are for convenience only and shall not affect their interpretation.
            </p>
          </li>

          <li>
            <p>
              <strong>No Agency.</strong> No agency, partnership, joint venture, or employment
              relationship is created as a result of this Agreement, your use of the Platform or the
              Services, or your interaction with us,and neither party has any authority of any kind
              to bind the other in any respect.
            </p>
          </li>

          <li>
            <p>
              <strong>No Third-Party Beneficiaries.</strong> Nothing in this Agreement shall benefit
              or create any right or cause of action in or on behalf of any person or entity other
              than you and inBrain.
            </p>
          </li>

          <li>
            <p>
              <strong>Assignment.</strong> You may not assign, delegate, or transfer, including by
              operation of law, this Agreement or any of your obligations hereunder.
            </p>
          </li>

          <li>
            <p>
              <strong>Force Majeure.</strong> With the exception of your obligation to make payments
              tous, neither you nor inBrain will be considered to be in breach or default of this
              Agreement as a result of its delay or failure to perform obligations hereunder where
              such delay or failure arises out of causes beyond the reasonable control of the party
              whose performance has been affected.
            </p>
          </li>
        </ol>
      </div>
    </div>
  );
}
