import axiosTokenInterceptor from "interceptors/tokenInterceptor";

export function loadSingleSupplierSurvey(surveyId: string, url: string) {
  const queryString = location.href.substring(location.href.indexOf("?") + 1);

  return (
    axiosTokenInterceptor
      .get(`${process.env.SUPPLIER_API_URL}/surveys/${surveyId}?${queryString}`, {
        headers: {
          "x-inBrain-referrer": url,
        },
      })
      .then((response) => response.data)
  );
}
