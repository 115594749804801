// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dbmDuWE2dgFDA3fnvHMl {\n\tdisplay: -webkit-flex;\n\tdisplay: -moz-box;\n\tdisplay: flex;\n\tgap: 2px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/surveyWall/fiveStarRating/FiveStarRating.module.css"],"names":[],"mappings":"AAAA;CACC,qBAAa;CAAb,iBAAa;CAAb,aAAa;CACb,QAAQ;AACT","sourcesContent":[".star-container {\n\tdisplay: flex;\n\tgap: 2px;\n}\n"],"sourceRoot":""}]);
// Exports
var _1 = "dbmDuWE2dgFDA3fnvHMl";
export { _1 as "star-container" };
export default ___CSS_LOADER_EXPORT___;
