import MainHeader from "pages/SurveyWall/MainHeader/MainHeader";
import { Outlet } from "react-router-dom";

export default function MainHeaderLayout() {
  return (
    <>
      <MainHeader />
      <Outlet />
    </>
  );
}
