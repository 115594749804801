import { SurveyOutcomeEvent } from "interfaces/SurveyOutcomeEvent";

export function notifySurveyOpened() {
  try {
    if (window.webkit) {
      window.webkit.messageHandlers.surveyOpened.postMessage({
        message: "test",
      });
    }

    if (window.androidInterface) {
      window.androidInterface.surveyOpened();
    }
  } catch (error) {
    console.log(error);
  }
}

export function notifySurveyClosed() {
  try {
    if (window.webkit) {
      window.webkit.messageHandlers.surveyClosed.postMessage({
        message: "test",
      });
    }

    if (window.androidInterface) {
      window.androidInterface.surveyClosed();
    }
  } catch (error) {
    console.log(error);
  }
}

export function notifyNativeSurveyClosed() {
	console.log("Notify Close Web View");
	try {
		if (window.webkit) {
			window.webkit.messageHandlers.nativeSurveyClosed.postMessage({
				message: "msg",
			});
		}

		if (window.androidInterface) {
			window.androidInterface.nativeSurveyClosed();
		}
	} catch (error) {
		console.log(error);
	}
}

export function notifySurveyOutcome(event: SurveyOutcomeEvent) {
	var eventData = JSON.stringify(event);
	console.log("Notify Native Survey Outcome: ", event);

	try {
		if (window.webkit) {
			window.webkit.messageHandlers.surveyOutcome.postMessage(eventData);
		}

		if (window.androidInterface) {
			window.androidInterface.surveyOutcome(eventData);
		}
	} catch (error) {
		console.log(error);
	}
}
