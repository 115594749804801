import { useState } from "react";
import { useTranslation } from "react-i18next";
import { TiChevronLeft } from "react-icons/ti";
import TocTab from "./items/TocTab";
import PrivacyTab from "./items/PrivacyTab";

import * as c from "./Toc.module.css";

interface TocProps {
  page: number;
  goBack: (show: boolean, page: number) => void;
}

function Toc({ page, goBack }: TocProps) {
  const [activeTab, setActiveTab] = useState(page);
  const { t } = useTranslation();

  const returnHandler = () => {
    goBack(false, 0);
  };

  return (
    <div className={c["toc-container"]}>
      <div className={c["back-wrapper"]}>
        <button className={c["toc-button"]} onClick={returnHandler}>
          <TiChevronLeft /> <span>{t("INBRAIN_THEME.PROFILER_TOC_QUESTION.BACK_BTN")}</span>
        </button>
      </div>
      <div className={c["tabs-wrapper"]}>
        <div className={c.tabs}>
          <div
            className={`${c.tab} ${activeTab === 0 ? c["active-tab"] : ""}`}
            onClick={() => setActiveTab(0)}
          >
            {t("INBRAIN_THEME.PROFILER_TOC_QUESTION.TABS.TOC")}
          </div>
          <div
            className={`${c.tab} ${activeTab === 1 ? c["active-tab"] : ""}`}
            onClick={() => setActiveTab(1)}
          >
            {t("INBRAIN_THEME.PROFILER_TOC_QUESTION.TABS.PP")}
          </div>
        </div>
      </div>
      <div className={c["header-wrapper"]}>
        <span>{t("INBRAIN_THEME.PROFILER_TOC_QUESTION.HEADER")}</span>
      </div>
      <div className={c["content-wrapper"]}>{activeTab === 0 ? <TocTab /> : <PrivacyTab />}</div>
    </div>
  );
}

export default Toc;
