import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Button from "components/ui/Button";
import VpnBlocked from "../../assets/images/vpn_blocked.png";

import * as c from "./VpnBlockedPage.module.css";

function VpnBlockedPage() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const translatedString = t("INBRAIN_THEME.BLOCKED_VPN.DESCRIPTION");

  const onContinueClick = () => {
    navigate("/configuration", {
      state: { load_existing_config: true },
    });
  };

  return (
    <div className={c["profiler-container"]}>
      <div className={c["inbrain-top-header"]}></div>
      <div className={c["vpn-block-wrapper"]}>
        <div className={c["vpn-block-content"]}>
          <span>{t("INBRAIN_THEME.BLOCKED_VPN.TITLE")}</span>
          <img src={VpnBlocked} alt="vpn-blocked" />
          <div dangerouslySetInnerHTML={{ __html: translatedString }}></div>
        </div>
        <div className={c["button-container"]}>
          <Button
            text={t("INBRAIN_THEME.BLOCKED_VPN.BUTTON")}
            onClick={onContinueClick}
            disabled={true}
            isLoading={false}
          />
        </div>
      </div>
    </div>
  );
}

export default VpnBlockedPage;
