// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".nk79CJ7fXQIr2Jmjz3zN {\n  width: 100%;\n\theight: 100vh;\n\tdisplay: -webkit-flex;\n\tdisplay: -moz-box;\n\tdisplay: flex;\n\t-webkit-justify-content: center;\n\t   -moz-box-pack: center;\n\t        justify-content: center;\n\t-webkit-align-items: center;\n\t   -moz-box-align: center;\n\t        align-items: center;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/Configuration/ConfigurationPage.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;CACZ,aAAa;CACb,qBAAa;CAAb,iBAAa;CAAb,aAAa;CACb,+BAAuB;IAAvB,qBAAuB;SAAvB,uBAAuB;CACvB,2BAAmB;IAAnB,sBAAmB;SAAnB,mBAAmB;AACpB","sourcesContent":[".configuration-page {\n  width: 100%;\n\theight: 100vh;\n\tdisplay: flex;\n\tjustify-content: center;\n\talign-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
var _1 = "nk79CJ7fXQIr2Jmjz3zN";
export { _1 as "configuration-page" };
export default ___CSS_LOADER_EXPORT___;
