import { createContext, useState, useContext, ReactNode } from "react";

enum Tabs {
  Surveys,
  Offers,
  Rewards,
}

interface HeaderTabContextProps {
  selectedHeaderTab: Tabs | null;
  setSelectedHeaderTab: (tab: Tabs) => void;
}

const HeaderTabContext = createContext<HeaderTabContextProps | undefined>(undefined);

export const HeaderTabProvider = ({ children }: { children: ReactNode }) => {
  const [selectedHeaderTab, setSelectedHeaderTab] = useState<Tabs | null>(null);

  return (
    <HeaderTabContext.Provider value={{ selectedHeaderTab, setSelectedHeaderTab }}>
      {children}
    </HeaderTabContext.Provider>
  );
};

export const useHeaderTabContext = (): HeaderTabContextProps => {
  const context = useContext(HeaderTabContext);
  if (!context) {
    throw new Error("useHeaderTabContext must be used within a HeaderTabProvider");
  }
  return context;
};
