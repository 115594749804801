import { createProfilerAnswer, getPIIQuestions } from "api/profiler";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import GooglePlacesAutocompleteProps from "react-google-places-autocomplete/build/types";
import { getCachedConfiguration } from "utils/functions/configurationUtil";
import { ProfilerAnswer } from "interfaces/ProfilerAnswer";
import Spinner from "components/ui/Spinner";

import * as c from "./GetMoreSurveysQuestions.module.css";

function GetMoreSurveysQuestions({ onSuccess }: { onSuccess: () => void }) {
  const { t } = useTranslation();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState<any>("");

  const [questions, setQuestions] = useState<any[]>([]);
  const [isSendingAnswers, setIsSendingAnswers] = useState(false);

  const [animateButton, setAnimateButton] = useState(false);

  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      borderRadius: "7px",
      border: state.isFocused ? "2px solid #25c8dc" : "1px solid rgba(151, 151, 151, 0.5)",
      outline: "none",
      boxShadow: "none",
      color: "rgba(0, 0, 0, 0.6)",
      background: "#fafafa",
      height: "43px",
      fontSize: "14px",
      boxSizing: "border-box",
      overflow: "hidden",
      textAlign: "left",
      width: "100%",
    }),
    input: (provided: any) => ({
      ...provided,
      fontFamily: "Poppins",
      color: "rgba(0, 0, 0, 0.6)",
    }),
    singleValue: (provided: any) => ({
      ...provided,
      fontFamily: "Poppins",
      color: "rgba(0, 0, 0, 0.6)",
    }),
    placeholder: (provided: any) => ({
      ...provided,
      color: "rgba(151, 151, 151, 0.4)",
      margin: "0",
    }),
    menu: (provided: any) => ({
      ...provided,
      borderRadius: "7px",
    }),
    indicatorsContainer: (provided: any) => ({
      ...provided,
      display: "none",
    }),
  };

  useEffect(() => {
    getPIIQuestions().then((questions) => {
      setQuestions(questions);
    });
  }, []);

  function handleContinue() {
    setAnimateButton(true);
    setTimeout(() => {
      setIsSendingAnswers(true);

      const configuration = getCachedConfiguration();

      const promises = questions.map((question) => {
        const answer = {
          profilerQuestionId: question.id,
          value: getAnswer(question.name),
        } as ProfilerAnswer;

        return createProfilerAnswer(configuration?.hashed_publisher_app_uid ?? "", answer);
      });

      Promise.all(promises).then(() => onSuccess());
    }, 300);

    console.log(firstName, lastName, email, address);
  }

  function getAnswer(questionName: string) {
    switch (questionName) {
      case "first_name":
        return firstName;
      case "last_name":
        return lastName;
      case "email_address":
        return email;
      case "street_address":
        return address.label;
    }
  }

  return (
    <>
      {!!questions ? (
        <div className={c["get-more-surveys-page"]}>
          <div className={c["get-more-surveys-container"]}>
            <div className={`${c["get-more-surveys-content"]} ${c["animated"]}`}>
              <div
                className={c["title"]}
                dangerouslySetInnerHTML={{
                  __html: t("MORE_SURVEYS_QUESTION.QUESTION_DESCRIPTION"),
                }}
              ></div>
              <div className={c["name-inputs"]}>
                <input
                  type="text"
                  name="firstName"
                  placeholder="First Name"
                  onChange={(e) => setFirstName(e.target.value)}
                />
                <input
                  type="text"
                  name="lastName"
                  placeholder="Last Name"
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
              <div>
                <input
                  type="text"
                  name="email"
                  placeholder="Email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div>
                <GooglePlacesAutocomplete
                  apiKey={process.env.GOOGLE_MAPS_API_KEY}
                  selectProps={{
                    value: address,
                    onChange: setAddress,
                    styles: customStyles,
                    placeholder: "Address",
                  }}
                />
              </div>
            </div>
            <div className={c["get-more-surveys-cta-container"]}>
              <button
                className={`${c["get-more-surveys-cta"]} ${isSendingAnswers ? c["disabled"] : ""} ${
                  animateButton ? c["animated"] : ""
                }`}
                disabled={isSendingAnswers}
                onClick={() => handleContinue()}
              >
                {!isSendingAnswers ? t("CONTINUE_MODULE.CONTINUE") : <Spinner size="small" />}
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
}

export default GetMoreSurveysQuestions;
