import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as c from "./SimulationSurveyPage.module.css";
import { isSupplierSurveyMode } from "utils/functions/supplierContextUtil";

function SimulationSurveyPage() {
  const { t } = useTranslation();
  const isSupplierSurvey = isSupplierSurveyMode();

  function chooseResult(result: string) {
    const url = location.href;
    const queryString = url.substring(url.indexOf("?") + 1);

    window.location.href = `/callback/simulation-survey/${encodeURIComponent(result)}?${queryString}`;
  }

  return (
    <div className={c["simulation-survey-page"]}>
      <div className={c["simulation-survey-content"]}>
        <div>
          <div>
            <span>{t("SIMULATION_SURVEY.HEADER")}</span>
          </div>
        </div>

        <div>
          <button className={c["button"]} onClick={() => chooseResult("completion")}>
            {t("SIMULATION_SURVEY.COMPLETION")}
          </button>
        </div>
        <div>
          <button className={c["button"]} onClick={() => chooseResult("termination")}>
            {t("SIMULATION_SURVEY.TERMINATION")}
          </button>
        </div>
        <div className={c["footer"]}>
          {!isSupplierSurvey && <span>{t("SIMULATION_SURVEY.POWERED_BY")}</span>}
        </div>
      </div>
    </div>
  );
}

export default SimulationSurveyPage;
