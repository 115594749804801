import callbackIcon from "assets/images/callback-icon.png";
import surveyNotFitIcon from "assets/images/survey-not-fit.svg";
import Spinner from "components/ui/Spinner";
import { useTranslation } from "react-i18next";
import { transformCurrency } from "utils/functions/currencyModUtil";
import * as c from "./BaseCallbackPage.module.css";

export enum CallbackStatuses {
  Complete = 1,
  Disqualification = 2,
}

function BaseCallbackPage({
  isLoading,
  callbackStatus,
  currencyName,
  reward,
  displayedReward,
  isRewardVisibilityDisabled,
  isDisqualificationDisabled,
  showAnimatedCta,
  closeView,
}: {
  isLoading: boolean;
  callbackStatus: CallbackStatuses;
  currencyName: string;
  reward: number;
  displayedReward: number;
  isRewardVisibilityDisabled: boolean;
  isDisqualificationDisabled: boolean;
  showAnimatedCta: boolean;
  closeView: () => void;
}) {
  const { t } = useTranslation();

  function hasReward() {
    console.log(reward);
    return reward > 0;
  }

  function renderPage() {
    if (callbackStatus === CallbackStatuses.Complete) {
      // Completion Callback
      return (
        <div className={c["callback-page"]}>
          <div className={c["callback-container"]}>
            <div className={c["callback-content"]}>
              <img src={callbackIcon} className={c["animated"]} />
              {!isRewardVisibilityDisabled && (
                <div className={`${c["reward"]} ${c["animated"]}`}>
                  + {transformCurrency(currencyName, displayedReward)}
                </div>
              )}
              <div className={`${c["reward-copy"]} ${c["animated"]}`}>
                {t("EXTERNAL_SURVEY.INBRAIN.SURVEYS.COMPLETED_NEW")}
              </div>
            </div>
            <div className={c["callback-cta-container"]}>
              <button
                className={`${c["callback-cta"]} ${showAnimatedCta && c["animated"]}`}
                onClick={closeView}
              >
                {t("EXTERNAL_SURVEY.INBRAIN.SURVEYS.ANOTHER_SURVEY")}
              </button>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className={c["callback-page"]}>
          <div className={`${c["callback-container"]} ${c["callback-container--termination"]}`}>
            <div className={c["callback-content"]}>
                <img src={surveyNotFitIcon} />
                <div className={c["reward-copy"]}>
                  {t("EXTERNAL_SURVEY.INBRAIN.SURVEYS.DISQUALIFIED_BASIC")}
                </div>
              {!isRewardVisibilityDisabled && !isDisqualificationDisabled && hasReward() && (
                <div className={`${c["reward"]} ${c["animated"]}`}>
                  + {transformCurrency(currencyName, reward)}
                </div>
              )}
            </div>
            <div className={c["callback-cta-container"]}>
              <button className={c["callback-cta"]} onClick={closeView}>
                {t("EXTERNAL_SURVEY.INBRAIN.SURVEYS.BACK_TO_SURVEYS_BUTTON_REWARD")}
              </button>
            </div>
          </div>
        </div>
      );
    }
  }

  return (
    <>
      {!isLoading ? (
        renderPage()
      ) : (
        <div className={c["callback-page--loading"]}>
          <Spinner size="normal" />
        </div>
      )}
    </>
  );
}

export default BaseCallbackPage;
