export const JSONtext = {
  PRIVACY_POLICY: `
  <h2>INBRAIN, LLC</h2>
  <h2>PRIVACY POLICY</h2>
  <ol>
  <div className='terms-header' ngClass.lt-md='terms-header-mobile' fxLayoutAlign='center center'>
    <p>Last updated: <strong><u>January 04, 2021</u></strong></p>
  </div>
  <p class='introduction'>inBrain, LLC (“inBrain”, “we”, “us” or “our”) is committed to protecting your (“you” or “your”)
    privacy and empowering you with this privacy policy (this “Privacy Policy”). This Privacy Policy explains how we
    collect, use, disclose, and apply the information collected when you use our website (<a
      href='https://www.inbrain.ai/'>https://www.inbrain.ai/</a>) or use any of the other services that we may make
    available to you (collectively, “Services”). As applicable to you, our Survey Creator Terms of Services, our
    Publisher Terms of Service, and our Survey Taker Terms of Service (collectively, the “Terms”) are expressly
    incorporated herein by reference and are an integral part hereof. The Terms can be found here: <a
      href='https://www.inbrain.ai/terms-and-policies/terms'>https://www.inbrain.ai/terms-and-policies/terms</a>.<br />By
    using the Services, or interacting with us, you are agreeing to this Privacy Policy. If you do not agree with the
    terms of this Privacy Policy, do not access or use the Services. Please read the following carefully to understand
    how we collect, use, and maintain your personal information. In addition, it describes your choices for use, access,
    and correction of your personal information.</p>
  <div class='changes-to-this-privacy-policy'>
    <li class='list-title'>
      <h3>Changes to this Privacy Policy</h3>
    </li>
    <p>We may change this Privacy Policy, in our sole discretion in accordance with applicable law, from time to time.
      In the event we make changes, we will notify you by revising the “Last Updated” date at the top of this Privacy
      Policy. If there are significant changes to this Privacy Policy, we will attempt to notify you directly by email
      or provide information via our home page prior to any such changes becoming effective. We encourage you to review
      our Privacy Policy whenever you use the Services to stay informed of ways you can protect your privacy.</p>
  </div>

  <div class='informations-we-collect-and-receive'>
    <li class='list-title'>
      <h3>Information We Collect and Receive</h3>
    </li>
    <p>We collect information to deliver the Services you request, to help improve your experience, and to support our
      business functions. We collect information from you in a variety of ways when you interact with the Services. More
      information about the categories and sources of information is provided below.</p>
    <ol>
      <li><strong>Information You Provided to Us</strong>. We collect information that you decide to share with us. At
        times, we may require you to provide certain information in order to use certain parts of our Site, fulfill your
        requests, or provide you with certain Services.</li>
      <ol class='info-provided-to-us'>
        <li><strong>Registering for an Account</strong>. If you establish an account with us, we may require you to
          provide your name, email address, gender, zip code, relationship status, employment status, income, race and
          ethnicity, birthday, and a password to create your account. We may also ask if you are registered to vote, the
          number of people who live with you, the approximate revenue of your organizations, industries those in your
          household work in, who in your household makes certain decisions, and the highest level of education you have
          complete.</li>
        <li><strong>Survey Information</strong>. When you participate in a survey, we may collect the information that
          you submit in a survey and in connection with the receipt and redemption of rewards and incentives.</li>
        <li><strong>Content Information</strong>. You also may choose to send us personal information in an email
          containing inquiries about the Services and we use this information in order to help us respond to your
          inquiry. You may also choose to submit information to us via other methods, including: (i) in response to
          marketing or other communications, (ii) through social media or online forums, (iii) through participation in
          an offer, program or promotion, or (iv) in connection with an actual or potential business relationship with
          us.</li>
      </ol>
      <li class='no-list-style'><strong>2.2. Information We Obtain from Others</strong>. We may obtain information about
        you from third parties who help us provide our Services, publicly available information systems, and commercial
        information services. We may also obtain some information from our marketing partners in order to inform you
        about products, new surveys, or Services that we think you might be interested in. Finally, we may obtain
        information that does not identify you directly, but which is later used in a way that may later directly
        identify you. We may combine that general information with your information to provide related services to you.
      </li>
      <li class='no-list-style'><strong>2.3. Information We Automatically Collect</strong>. We automatically collect
        certain information from you when you use the Services, including internet or other network activity information
        (as described below), unique device identifiers, browsing and search history (including content you have viewed
        in the Services), and cookies and other technologies (as defined in Section 3). Information collected
        automatically includes.</li>
      <ol>
        <li class='no-list-style'><strong>2.3.1. Device information</strong>. We collect technical information when you
          visit our Services. This includes information such as the type of device or browser you use, your device’s
          operating system, your internet service provider, your device’s regional and language settings, and device
          identifiers such as IP address and Ad Id. When you visit and interact with the Services, we may collect
          certain information automatically through cookies or other technologies, including, but not limited to, the
          type of computer or mobile device you use, your mobile device’s unique device ID, the IP address of your
          computer or mobile device, your operating system, the type(s) of internet browser(s) you use, and information
          about the way you use the Services.</li>
        <li class='no-list-style'><strong>2.3.2. Usage Information</strong>. We collect information regarding your use
          of the Services including data about features you use, pages you visit, emails and advertisements you view,
          portions of the Services that you view and interact with, the time of day you browse, and your referring and
          exiting pages.</li>
        <li class='no-list-style'><strong>2.3.3. Crash and Error Information</strong>. If any of the Services crash or
          return an error, we may collect data to determine the cause of the error. The crash or error information
          collected may include the following: IP address, device name, operating system version, application
          configurations(s), the time and date, and other statistics.</li>
      </ol>
    </ol>
  </div>

  <div class='cookies-and-other-technologies'>
    <li class='list-title'>
      <h3>Cookies and Other Technologies</h3>
    </li>
    <p>
      Like many other websites, our Services may use “Cookies” or “Other Technologies” (such as “pixel tags”, “web
      beacons”, “clear GIFs”, links in emails, JavaScript, device IDs assigned by Google or Apple, or similar
      technologies) to collect information and support certain features of our Services. Cookies and Other Technologies
      allow us and third parties to obtain information about your visits to our Services, including analyzing your
      visiting patterns. Although you are not required to accept Cookies when you visit our Services, you may be unable
      to use all of the functionality of our Services if your browser restricts our Cookies. We use this information to
      process your requests and to deliver online and mobile advertisements, messages and content for us and others that
      are specific to your interests. The information we collect from your web browser and from Cookies and Other
      Technologies does not identify you personally but in some cases, we may link it to personally identifiable
      information.
    </p>
    <ol>
      <li><strong>Cookies</strong>. Below, we explain the different types of Cookies that may be used on the Services.
      </li>
      <ol>
        <li><strong>Necessary Cookies</strong>. Necessary Cookies are essential to enable your use of the Services and
          to identify and prevent security risks, such as storing your session information to prevent others from
          changing your password without your username and password.</li>
        <li><strong>Preference Cookies</strong>. We use these Cookies to remember your settings and preferences and to
          improve your experience on our Services. For example, we may use these Cookies to remember your language
          preferences.</li>
        <li><strong>Session Cookies</strong>. These types of Cookies are stored only temporarily during a browsing
          session and are deleted from your device when you close the browser. We use session Cookies to support the
          functionality of the Services and to understand your use of the Services—that is, which pages you visit, which
          links you use, and how long you stay on each page.</li>
        <li><strong>Statistics Cookies</strong>. Statistics Cookies help collect information about your use of the
          Services and enable us to improve the way it works. These Cookies give us aggregated information that we use
          to monitor the Services’s performance, count page visits, spot technical errors, see how users reach the
          Services, and measure the effectiveness of marketing (including emails).</li>
        <li><strong>Marketing Cookies</strong>. Marketing Cookies are used to show users display advertisements across
          websites. The intention is to display ads that are relevant and engaging for the individual user and thereby
          more valuable for publishers and third-party advertisers. We do not display ads for any services besides our
          own.</li>
        <li><strong>Unclassified Cookies</strong>. Unclassified Cookies are Cookies that we are in the process of
          classifying, together with the providers of individual Cookies.</li>
      </ol>
      <li class="no-list-style"><strong>3.2. Other Technologies</strong>. In addition to Cookies, we may use Other
        Technologies, like pixel tags (also known as web beacons and clear GIFs), to, among other things, track the
        actions of users of the Services (about usage including email recipients), measure the success of our marketing
        campaigns, and compile statistics of the Services and response rates.</li>
    </ol>
  </div>

  <div class='how-we-use-your-information'>
    <li class='list-title'>
      <h3>How We Use Your Information</h3>
    </li>
    <ol>
      <li>
        We may use the information collected for the limited purpose of providing the Services and related functionality
        and services, as described in the Terms and in this Privacy Policy, and as permitted by applicable laws. These
        limited purposes include circumstances where it is necessary to provide or fulfill the Services requested by or
        for you or where you have given us your express consent. We may use the information we obtain about you to:
      </li>
      <ol>
        <li>Provide the information, products, and Services you request;</li>
        <li>Service your account and provide you, if you are a registered user, with effective customer service;</li>
        <li>Better understand your needs and interests, and provide you with a personalized experience when you use our
          Services;</li>
        <li>Contact you with special offers and other information we believe will be of interest to you (in accordance
          with any privacy preferences you have expressed to us);</li>
        <li>Contact you with information and notices related to your use of our Services, including our Services;</li>
        <li>Invite you to participate in surveys and provide feedback to us (in accordance with any privacy preferences
          you have expressed to us);</li>
        <li>Improve our products and Services and develop new products and Services, improve our marketing and
          promotional efforts, and improve the content, functionality, and usability of the Services;</li>
        <li>Enforce our Terms;</li>
        <li>Promote security and protect against and prevent fraud, claims, and other liabilities;</li>
        <li>Verify the information you provide to us as well as the representations and warranties you make to us in the
          Terms or on the Services;</li>
        <li>Meet our internal and external audit obligations;</li>
        <li>Tailor survey opportunities to you and notify you of these opportunities;</li>
        <li>Administer and manage our reward and incentive programs and fulfill your requests for rewards and
          incentives; and</li>
        <li>Use for any other purpose for which we provide notice at the time of collection.</li>
      </ol>
      <li>We may aggregate and/or de-identify information collected through the Services and from other sources so that
        such information can no longer be linked to you or your device (“Aggregate/De-Identified Information”). We may
        use Aggregate/De-Identified Information for any purpose, including, without limitation, for research and
        marketing purposes.</li>
    </ol>
  </div>

  <div class='how-we-use-and-disclose-info'>
    <li class='list-title'>
      <h3>How We Share and Disclose Information</h3>
    </li>
    <ol>
      <li><strong>We Do Not Sell Personal Information</strong>. We do not sell or otherwise disclose personal
        information we collect about you, except as described herein or otherwise disclosed to you at the time the data
        is collected. We share information to perform activities that you request.</li>
      <li><strong>Service Providers and Business Partners</strong>. We also share information with vendors with whom we
        have a contractual relationship and who are providing services, including, without limitation, data append
        services, data validation services, fraud detection and/or prevention services, database-matching services,
        coding services, data segmentation services, and reward, and incentive related services, as well as market
        research partners that provide surveys.</li>
      <li><strong>Your Consent</strong>. We may share information for other purposes pursuant to your consent or with
        your further direction.</li>
      <li><strong>Aggregate/De-Identified Data</strong>. We may share Aggregate/De-Identified Information with any third
        parties, including advertisers, promotional partners, business and channel partners, sponsors, and/or others.
      </li>
      <li><strong>Legal Reasons</strong>. We may also disclose information about you when we, in good faith, believe
        disclosure is appropriate to comply with the law, a court order, or a subpoena. We may also disclose information
        about you to prevent or investigate a possible crime, such as fraud or identity theft; to protect the security
        of our Services; to enforce or apply our Terms or other agreements; or to protect our own rights or property or
        the rights, property or safety of our users or others. We will attempt to notify our users about legal demands
        for their personal information when appropriate in our judgment unless prohibited by law or court order or when
        the request is an emergency. We may dispute such demands when we believe, in our discretion, that the requests
        are overbroad, vague, or lack proper authority.</li>
      <li><strong>Sale, Merger, or Other Business Transfer</strong>. We also reserve the right to transfer information
        about you to a successor organization if we transfer the ownership or operation of one of our websites to
        another organization or if we merge with another organization. If such a transfer occurs, we will use reasonable
        efforts to direct the transferee to use personal information you have provided to us in a manner consistent with
        this Privacy Policy. Following such a sale or transfer, you may contact the entity to which we transferred your
        personal information with any inquiries concerning the processing of that information.</li>
    </ol>
  </div>

  <div class='your-choices'>
    <li class='list-title'>
      <h3>Your Choices</h3>
    </li>
    <ol>
      <li>You can manage Cookies through your web browser. Most browsers will tell you how to stop accepting new
        Cookies, how to be notified when you receive a new Cookie, and how to disable existing Cookies. You can find out
        how to do this for your particular browser by clicking “help” on your browser’s menu or by visiting <a
          href='https://www.allaboutcookies.org/'>www.allaboutcookies.org</a>. Please note, however, that without
        Cookies you may not be able to take full advantage of all our website features. In addition to the browser-based
        controls, you can manage third party Cookies by visiting <a
          href='https://www.aboutads.info/choices/'>www.aboutads.info/choices/</a>. To separately make choices for
        mobile apps on a mobile device, you can download DAA’s AppChoices application from your device’s app store.
        Alternatively, for some devices you may use your device’s platform controls in your settings to exercise choice.
        Please note you must separately opt out in each browser and on each device. Advertisements on third party
        websites that contain the AdChoices link may have been directed to you based on information collected by
        advertising partners over time and across websites. These advertisements provide a mechanism to opt out of the
        advertising partners’ use of this information for interest-based advertising purposes.</li>
      <li>If you have registered for an account, you may access, review, and update certain personal information that
        you have provided to us by logging into your account and using available features and functionalities.</li>
      <li>You have a choice at any time to stop us from sending you emails for marketing purposes by adjusting your
        settings in your account settings. You also have the option to opt out of receiving certain other emails from us
        by adjusting your settings in your account settings. You can also opt out of commercial email messages by
        following the “unsubscribe” link included in these messages. Please note that despite any indicated email
        marketing preferences, we may continue to send you administrative emails regarding inBrain, including, for
        example, notices of updates to our Terms or this Privacy Policy, notifications of new survey opportunities, if
        we choose to provide such notices to you in this manner.</li>
      <li>Some browsers offer a “do not track” (“DNT”) option. Since no common industry or legal standard for DNT has
        been adopted by industry groups, technology companies, or regulators, we do not respond to DNT signals. We will
        make efforts to continue to monitor developments around DNT browser technology and the implementation of a
        standard.</li>
    </ol>
  </div>

  <div class='european-residents'>
    <li class='list-title'>
      <h3>European Residents</h3>
    </li>
    <p>If you are a resident of the European Economic Area, you have certain rights regarding the personal information
      we maintain about you. We offer you certain choices about what personal information we collect from you, how we
      use that information, and how we communicate with you. If at any time you wish to exercise your rights, please
      reach out to us in accordance with the “Contact Us” section below:</p>
    <ol>
      <li><strong>Information and Access</strong>. You can request to see which personal information we process from
        you. We can inform you on how and why we process this data unless restricted by law, or if we are unable to
        verify your identify.</li>
      <li><strong>Submitting information</strong>. You may refrain from submitting information directly to us, although
        doing so may impact our ability to provide the services, and information you request and/or affect your ability
        to use the Services.</li>
      <li><strong>Rectification</strong>. You may request to update or correct the personal information we maintain for
        you.</li>
      <li><strong>Right to be forgotten/request deletion</strong>. You may request deletion of your personal
        information. We may ask you for additional information to verify your identity and will process your request
        subject to and in accordance with the law.</li>
      <li><strong>Restriction</strong>. You may request the limitation on the processing of your personal information.
      </li>
      <li><strong>Restricting cookies</strong>. You may elect not to have a unique cookie identification number assigned
        to your computer to avoid aggregation and analysis of data collected on our websites. Generally, if you do not
        wish to receive cookies, you may set your browser to deny cookies or to alert you when a cookie is placed on
        your computer.</li>
      <li><strong>Objection</strong>. You may remove your consent for the processing of your personal information.</li>
      <li><strong>Transferability</strong>. You may request to transfer your personal information.</li>
    </ol>
  </div>

  <div class='california-residents'>
    <li class='list-title'>
      <h3>California Residents</h3>
    </li>
    <ol>
      <li>If you are a resident of the State of California, you have certain rights afforded to you under the California
        Consumer Privacy Act or the “CCPA”. When we say “Personal Information” in this section, we mean information that
        identifies, relates to, describes, is reasonably capable of being associated with, or could reasonably be
        linked, directly or indirectly, with you. Personal Information does not include information that is aggregated
        or information that cannot be reasonably linked to you.</li>
      <li>We collect Personal Information and Non-Personal Information for the business and commercial purposes
        described in the section above titled “How We Use Your Information”, and we share Personal Information with the
        categories of third parties described in this Privacy Policy. We do not sell (as such term is defined in the
        CCPA) the Personal Information we collect (and will not sell it without providing a right to opt out).</li>
      <li>Subject to certain limitations, the CCPA provides California residents the following rights:</li>
      <ol>
        <li>You have the right to request that we disclose to you the Personal Information we collect, use, or disclose,
          and information about our data practices;</li>
        <li>You have the right to request that we delete your Personal Information that we have collected from you;</li>
        <li>You have the right to request that we no longer sell your Personal Information to third parties;</li>
        <li>We will not discriminate against you for exercising any of these rights.</li>
      </ol>
      <li>California residents may make a request pursuant to their “right to know”, “right to request deletion”, and/or
        “right to opt-out” under the CCPA by contacting us in accordance with the “Contact Us” section below. In order
        to verify your request to know or delete information, we may require you to provide us with certain information
        to be used solely for the purpose of verifying your identity.</li>
      <li>Under the CCPA, you may exercise these rights yourself or you may designate an authorized agent to make these
        requests on your behalf. We may request that your authorized agent have written permission from you to make
        requests on your behalf and may need to verify your authorized agent’s identity.</li>
    </ol>
  </div>

  <div class='information-retention'>
    <li class='list-title'>
      <h3>Information Retention</h3>
    </li>
    <p>We will retain information required to comply with privacy requests, manage active accounts, as required by law,
      in order to resolve disputes or enforce our agreements. We will retain personal information we process on behalf
      of our clients as directed by them. We may also retain copies of your information for disaster recovery purposes,
      to prevent fraud or future abuse, or for legitimate business purposes, such as analysis of Aggregate/De-Identified
      Information, account recovery, auditing our records.</p>
  </div>

  <div class='data-processing'>
    <li class='list-title'>
      <h3>Data Processing and Data Transfers</h3>
    </li>
    <p>By accessing or using the Services or otherwise providing information to us, you consent to the processing and
      transfer of information in and to the U.S. and other countries and territories, which may have different privacy
      laws from your country of residence. Whenever we share information of European Union users outside the European
      Union, we make sure an adequate transfer mechanism is in place.</p>
  </div>
  <div class='security'>
    <li class='list-title'>
      <h3>Security</h3>
    </li>
    <p>We have implemented a variety of administrative, technical, and physical security measures to protect against the
      unauthorized access, destruction, or alteration of your information. These safeguards vary based on the
      sensitivity of the information that we collect, process, and store and the current state of technology. Although
      we take appropriate measures to safeguard against unauthorized disclosures of information, we cannot guarantee
      that information that we collect will never be disclosed in a manner that is inconsistent with this Privacy
      Policy.</p>
  </div>
  <div class='third-party-websites'>
    <li class='list-title'>
      <h3>Links to Third-Party Websites</h3>
    </li>
    <p>The Services may provide the ability to connect to other websites. These websites may operate independently from
      us and may have their own privacy notices or policies, which we strongly suggest you review. If any linked website
      is not owned or controlled by us, we are not responsible for its content, any use of the website, or the privacy
      practices of the operator of the website.</p>
  </div>
  <div class='children-privacy'>
    <li class='list-title'>
      <h3>Children’s Privacy</h3>
    </li>
    <p>The Services are directed to individuals over the age of thirteen (13). We do not knowingly collect personal
      information from individuals under the age of thirteen (13) (“Children”). If we become aware of any Children who
      have provided us with personal information, we will take steps to deactivate their account and delete the personal
      information. If you become aware of information from Children, which has been provided to us, please contact us in
      accordance with the “Contact Us” section below.</p>
  </div>
  <div class='contact-us'>
    <li class='list-title'>
      <h3>Contact Us</h3>
    </li>
    <p>If you have any questions or concerns about our Privacy Policy, please contact us via email at <a
        href='support@inbrain.ai'>support@inbrain.ai</a>.</p>
  </div>
</ol>
  `,
};
