import { Reward } from "interfaces/reward";
import { transformCurrency } from "utils/functions/currencyModUtil";
import * as c from "./RewardSurveyCardItem.module.css";
import { useTranslation } from "react-i18next";

interface RewardSurveyCardItemProps {
  reward: Reward;
  currencyName: string;
}

function RewardSurveyCardItem({ reward, currencyName }: RewardSurveyCardItemProps) {
  const { t } = useTranslation();
  const { amount, createdAt, type, category } = reward;
  const upperCaseCategory = category.toUpperCase();
  const currency = transformCurrency(currencyName, amount);
  const date = new Date(createdAt);
  const month = date.toLocaleString("en-US", { month: "long" });
  const day = date.getDate();
  const year = date.getFullYear();

  const getOrdinalSuffix = (day: any) => {
    if (day > 3 && day < 21) return "th";
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  const formattedDate = `${month} ${day}${getOrdinalSuffix(day)}, ${year}`;

  return (
    <div className={c.card}>
      <div className={c["card-content"]}>
        <div className={c["text-content-wrapper"]}>
          <div className={c["text-content"]}>
            <p className={c["card-date"]}>{formattedDate}</p>
            <p className={c["card-reward"]}>{t(`RESPONDENT_REWARD.TYPES.${upperCaseCategory}`)}</p>
          </div>
        </div>
        <div className={c["currency-wrapper"]}>
          <div className={c["currency"]}>
            <span>{currency}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RewardSurveyCardItem;
