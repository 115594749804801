import axiosTokenInterceptor from "interceptors/tokenInterceptor";
import { DeviceTypes } from "interfaces/DeviceTypes";
import { SurveyWallSurvey } from "interfaces/SurveyWallSurvey";
import { ProductTypes } from "utils/constants/enums";
import { getCachedConfiguration } from "utils/functions/configurationUtil";

export function getSurveys(
  currencyRate?: number,
  deviceType?: DeviceTypes
): Promise<SurveyWallSurvey[]> {
  const configuration = getCachedConfiguration();
  const hashedAppUserId = configuration?.hashed_publisher_app_uid ?? "";
  const deviceId = configuration?.device_id ?? "";

  const url = `${process.env.API_URL}/external-panelist/${hashedAppUserId}/${deviceId}/surveywall-surveys`;

  return axiosTokenInterceptor
    .get(url, {
      params: {
        deviceType: deviceType,
        currencyRate: currencyRate,
      },
    })
    .then((response) =>
      response.data.surveys.map(
        (survey: any) =>
          ({
            ...survey,
            searchId: response.data.searchId,
          } as SurveyWallSurvey)
      )
    );
}

export function setSurveyAsAttempted(
  externalSurveyId: string,
  virtualSurveyId: string,
  productType: ProductTypes,
  currencyRate?: number,
  searchId?: string,
  isBoostedSurvey = true // TODO: Revisit this field. It's always been passed as default value.
): Promise<any> {
  const configuration = getCachedConfiguration();
  const appUserId = configuration?.hashed_publisher_app_uid ?? "";
  const deviceId = configuration?.device_id ?? "";
  var placementId = configuration?.placement_id;
  var publisherMid = configuration?.publisher_mid;

  const url = `${process.env.API_URL}/external-panelist/${appUserId}/${deviceId}/attempt-survey-v2`;

  return axiosTokenInterceptor
    .post(url, null, {
      params: {
        externalSurveyId,
        productType: ProductTypes[productType],
        isBoostedSurvey,
        virtualSurveyId,
        currencyRate,
        searchId,
        placementId,
        publisherMid: publisherMid
      },
    })
    .then((response) => response.data);
}
