import React, { useState } from "react";
import * as c from "./Slider.module.css";

interface SliderComponentProps {
  min: number;
  max: number;
  step: number;
  ariaLabel: string;
  onChange: (value: number) => void;
}

const SliderComponent: React.FC<SliderComponentProps> = ({
  min,
  max,
  step,
  ariaLabel,
  onChange,
}) => {
  const [value, setValue] = useState<number>(min);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = Number(event.target.value);
    setValue(newValue);
    onChange(newValue);
  };

  const calculatePercentage = (value: number) => {
    return Math.round(((value - min) / (max - min)) * 100);
  };

  return (
    <div className={c["slider-container"]}>
      <input
        type="range"
        min={min}
        max={max}
        step={step}
        value={value}
        aria-label={ariaLabel}
        onChange={handleChange}
        className={c.slider}
      />
      <div className={c["slider-percentage"]}>{calculatePercentage(value).toFixed()}%</div>
    </div>
  );
};

export default SliderComponent;
