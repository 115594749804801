import { Configuration } from "interfaces/Configuration";
import { CurrentSurvey } from "interfaces/CurrentSurvey";
import { PublisherColors } from "interfaces/PublisherColors";
import { PublisherCurrencySale } from "interfaces/PublisherCurrencySale";
import { PublisherFlags } from "interfaces/PublisherFlags";
import { SurveyLink } from "interfaces/SurveyLink";
import { JwtToken } from "interfaces/Token";
import { SECONDS_TO_MILLISECONDS_MULTIPLIER } from "utils/constants/configs";
import { ProductTypes } from "utils/constants/enums";
import {
  CONFIGURATION_KEY,
  TOKEN_KEY,
  PUBLISHER_FLAGS_KEY,
  COLORS_KEY,
  CURRENCYSALES_KEY,
  SURVEY_LINK_KEY,
  CURRENT_SURVEY_KEY,
} from "utils/constants/keys";

export const getToken = () => {
  const tokenString = localStorage.getItem(TOKEN_KEY);
  const token: JwtToken | null = tokenString ? JSON.parse(tokenString) : null;
  if (token && token.expires_at <= Date.now()) {
    console.log("The token should be invalidated.");
    destroyToken();
    return null;
  }
  return token;
};

export const saveToken = (token: JwtToken) => {
  //set to expire in 24 hrs.
  token.expires_at = Date.now() + token.expires_in * SECONDS_TO_MILLISECONDS_MULTIPLIER;
  window.localStorage.setItem(TOKEN_KEY, JSON.stringify(token));
};

export const destroyToken = () => {
  window.localStorage.removeItem("tokenKey");
};

export const savePublisherFlags = (flags: PublisherFlags) => {
  window.localStorage.setItem(PUBLISHER_FLAGS_KEY, JSON.stringify(flags));
};

export const saveColors = (colors: PublisherColors) => {
  window.localStorage.setItem(COLORS_KEY, JSON.stringify(colors));
};

export const getColors = (): PublisherColors => {
  const colors = window.localStorage.getItem(COLORS_KEY);
  if (colors) {
    return JSON.parse(colors) as PublisherColors;
  } else {
    return {
      primaryColor: null,
      secondaryColor: null,
    };
  }
};

export const saveCurrencySales = (currencySales: PublisherCurrencySale) => {
  window.localStorage.setItem(CURRENCYSALES_KEY, JSON.stringify(currencySales));
};

export const setCurrentSurvey = (
  externalId: string,
  productType: ProductTypes,
  surveyProviderId: number,
  virtualId?: string,
  searchId?: string,
  link?: string
) => {
  const currentSurvey = {
    id: externalId,
    externalId: externalId,
    virtualId: virtualId,
    productType,
    link,
    startTime: Date.now(),
    surveyProviderId,
    searchId,
  } as CurrentSurvey;

  window.localStorage.setItem(CURRENT_SURVEY_KEY, JSON.stringify(currentSurvey));
};

export const getCurrencySale = () => {
  const currencySaleJson = window.localStorage.getItem(CURRENCYSALES_KEY);

  if (currencySaleJson === null) {
    return null;
  }

  return JSON.parse(currencySaleJson) as PublisherCurrencySale;
};

export const getCurrentSurvey = () => {
  const storedSurveyJson = window.localStorage.getItem(CURRENT_SURVEY_KEY);

  if (storedSurveyJson === null) {
    return null;
  }

  const storedSurvey = JSON.parse(storedSurveyJson) as CurrentSurvey;

  return storedSurvey;
};

export const getPublisherFlags = () => {
  const flagsJson = window.localStorage.getItem(PUBLISHER_FLAGS_KEY);

  if (flagsJson === null) {
    return null;
  }

  return JSON.parse(flagsJson) as PublisherFlags;
};

export const serializeParams = (payload: any): string => {
  let str = "";
  for (const key in payload) {
    if (payload.hasOwnProperty(key)) {
      if (str !== "") {
        str += "&";
      }
      str += key + "=" + encodeURIComponent(payload[key]);
    }
  }
  return str;
};

export const setCacheConfiguration = (configuration: Configuration) => {
  window.localStorage.setItem(CONFIGURATION_KEY, JSON.stringify(configuration));
};

export const getCachedConfiguration = () => {
  const cachedConfigurationJson = window.localStorage.getItem(CONFIGURATION_KEY);

  if (cachedConfigurationJson === null) {
    return null;
  }

  return JSON.parse(cachedConfigurationJson) as Configuration;
};

export const persistentLocalStorageKeys: string[] = [];

export const clearAllFromLocalStorage = () => {
  Object.keys(window.localStorage).forEach((key) => {
    if (!persistentLocalStorageKeys.includes(key)) {
      window.localStorage.removeItem(key);
    }
  });
};

export const isGetMoreSurveysEnabled = () => {
  const configuration = getCachedConfiguration();

  return configuration && !configuration.pii_questions_answered && process.env.PII_CLIENT_IDS?.split(",").includes(configuration.client_id ?? "");
}
