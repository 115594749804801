import axiosTokenInterceptor from "interceptors/tokenInterceptor";
import { PanelistState } from "interfaces/PanelistState";
import { SetPanelistStateRequest } from "interfaces/SetPanelistStateRequest";

export const getPanelistConfiguration = async (
  hashedAppUserId: string,
  originalAppUserId: string,
  deviceId: string,
  sessionId: string,
  languagePreference?: string | undefined
) => {
  const params = new URLSearchParams();

  if (languagePreference) {
    params.append("languageString", encodeURIComponent(languagePreference));
  }

  params.append("originalAppUserId", encodeURIComponent(originalAppUserId));
  params.append("deviceId", deviceId);
  params.append("sessionId", sessionId);


  const request = `${process.env.API_URL}/panelists/${hashedAppUserId}/configuration`;
  const response = await axiosTokenInterceptor.get(request, { params });

  return response.data;
};

export const setPanelistState = async (
  hashedAppUserId: string,
  requestData: SetPanelistStateRequest
) => {
  const request = `${process.env.API_URL}/panelists/${hashedAppUserId}/state`;
  const response = await axiosTokenInterceptor.patch(request, requestData);

  return response.data as PanelistState;
};