import axiosTokenInterceptor from "interceptors/tokenInterceptor";
import { ProfilerAnswer } from "interfaces/ProfilerAnswer";
import { ProductTypes } from "utils/constants/enums";
import { getCachedConfiguration, serializeParams } from "utils/functions/configurationUtil";

export const getGeolocation = async (
  hashedAppUserId: string,
  languagePreference?: string | undefined
) => {
  const params = new URLSearchParams();

  if (languagePreference) {
    params.append("languagePreference", encodeURIComponent(languagePreference));
  }

  const request = `${process.env.API_URL}/profiling/${hashedAppUserId}/geolocation`;
  const response = await axiosTokenInterceptor.get(request, { params });

  return response.data;
};

export const getProfilingQuestions = async (
  hashedAppUserId: string
) => {
  const configuration = getCachedConfiguration();
  const request = `${process.env.API_URL}/profiling/${hashedAppUserId}/questions`;

  const response = await axiosTokenInterceptor.get(request);

  return response.data;
};

export const getPIIQuestions = async () => {
  const configuration = getCachedConfiguration();

  const hashedAppUserId = configuration?.hashed_publisher_app_uid

  if (!hashedAppUserId) {
    throw new Error("User not found");
  }

  const response = await axiosTokenInterceptor.get(
    `${process.env.API_URL}/profiling/${hashedAppUserId}/pii`
  );

  return response.data;
};

export const createProfilerAnswer = async (
  hashedAppUserId: string,
  profilerAnswer: ProfilerAnswer
) => {
  const response = await axiosTokenInterceptor.post(
    `${process.env.API_URL}/profiling/${hashedAppUserId}/answer`,
    profilerAnswer
  );

  return response.data;
};

export const startProfiling = async (
  hashedAppUserId: string,
  productType: ProductTypes
) => {
  const params = { productType };

  const request = `${process.env.API_URL}/profiling/${hashedAppUserId}/start?${serializeParams(params)}`;

  const response = await axiosTokenInterceptor.post(request);

  return response.data;
};

export const completeProfiling = async (
  hashedAppUserId: string,
  sessionId: string,
  publisherMid: string,
  productType: number
) => {
  const params = {
    sessionId,
    productType,
    ...(publisherMid && { publisherMid }),
  };

  const request = `${process.env.API_URL}/profiling/${hashedAppUserId}/complete?${serializeParams(params)}`;

  const response = await axiosTokenInterceptor.post(request);

  return response.data;
};