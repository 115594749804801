import { getSurveys } from "api/surveys";
import thumbsUpIcon from "assets/images/thumbs-up.svg";
import CurrencySaleIndicator from "components/surveyWall/currencySaleIndicator/CurrencySaleIndicator";
import FiveStarRating from "components/surveyWall/fiveStarRating/FiveStarRating";
import { useColorContext } from "contexts/colorContext";
import { PanelistStages } from "interfaces/PanelistStages";
import { SurveyWallSurvey } from "interfaces/SurveyWallSurvey";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { MdChevronRight } from "react-icons/md";
import { TbClock } from "react-icons/tb";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import { ProductTypes } from "utils/constants/enums";
import {
  getCachedConfiguration,
  setCacheConfiguration,
  getCurrencySale,
  isGetMoreSurveysEnabled,
  setCurrentSurvey,
} from "utils/functions/configurationUtil";
import { transformCurrency } from "utils/functions/currencyModUtil";
import { getCurrencyMultiplierLabel } from "utils/functions/currencySaleUtil";
import RewardSnackbar from "./RewardSnackbar/RewardSnackbar";
import MainFooter from "./MainFooter/MainFooter";
import * as c from "./SurveyWallPage.module.css";
import { useErrorHandler } from "contexts/errorContext";
import ProfilerPage from "pages/Profiler/ProfilerPage";

function SurveyWallPage() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [sortedSurveys, setSortedSurveys] = useState<SurveyWallSurvey[]>([]);
  const [topRatedSurvey, setTopRatedSurvey] = useState<SurveyWallSurvey | null>(null);
  const [loadingSurveyId, setLoadingSurveyId] = useState<string | null>(null);
  const [surveysLoaded, setSurveysLoaded] = useState(false);
  const [profilerCompleted, setProfilerCompleted] = useState(false);
  const [showReloadPrompt, setShowReloadPrompt] = useState(false);
  const [panelistStage, setPanelistStage] = useState<PanelistStages>(PanelistStages.PreBoostSurvey);
  const [snackbarReward, setSnackbarReward] = useState<string | null>(null);
  const [hideRewardSnackbar, setHideRewardSnackbar] = useState(true);
  const [reloadKey, setReloadKey] = useState(0);
  const { primaryColorCtx, secondaryColorCtx } = useColorContext();
  const surveyReloadInterval = useRef<any>(null);
  const configuration = getCachedConfiguration();
  const currencySale = getCurrencySale();
  const getMoreSurveysEnabled = isGetMoreSurveysEnabled();
  const handleError = useErrorHandler();

  useEffect(() => {
    setupSnackbar();

    const stage = configuration?.panelist_stage || PanelistStages.PreBoostSurvey;
    setPanelistStage(stage);

    if (configuration?.profiler_questions_required){ return; }

    setProfilerCompleted(true);

      // Now call loadSurveys after fetchUserConfig has completed
    loadSurveys().then((surveys) => {
        sortAndSetSurveys(surveys, stage);
      }).catch((error) => {
        console.error("Error loading surveys:", error);
        handleError(error);
      }).finally(() => setSurveysLoaded(true));

      //surveyReloadInterval.current = setInterval(() => promptSurveyReload(), 60000);//TODO will need to add the prompt button. Everything else wired up and ready
    surveyReloadInterval.current = setInterval(() => reloadSurveysFromPrompt(), 300000);//5 minutes

    return () => {
      surveyReloadInterval.current && clearInterval(surveyReloadInterval.current);
    };
  }, [reloadKey]);

  function promptSurveyReload(){
    setSurveysLoaded(false);
    setShowReloadPrompt(true);
  }

  function reloadSurveysFromPrompt(){
    setSurveysLoaded(false);
    loadSurveys().then((surveys) => {
      sortAndSetSurveys(surveys, panelistStage);
      }).catch((error) => {
      console.error("Error loading surveys:", error);
      handleError(error);
      }).finally(() => {
      setSurveysLoaded(true);
      setShowReloadPrompt(false);
    });
  }

  function setupSnackbar() {
    const reward = searchParams.get("reward");
    setSnackbarReward(reward);

    if (!!reward) {
      setHideRewardSnackbar(false);

      setTimeout(() => {
        setHideRewardSnackbar(true);
      }, 2700);
    }
  }

  function loadSurveys() {
    return getSurveys(configuration?.currency_rate, configuration?.device_type);
  }

  function profilerFinished(){
    setSurveysLoaded(false);
    setProfilerCompleted(true);
    loadSurveys().then((surveys) => {
      sortAndSetSurveys(surveys, panelistStage);
      }).catch((error) => {
      console.error("Error loading surveys:", error);
      handleError(error);
      }).finally(() => {
        if (configuration){
        configuration.profiler_questions_required = false;
        setCacheConfiguration(configuration);
      }
      setSurveysLoaded(true);
    });
  }

  function sortAndSetSurveys(surveys: SurveyWallSurvey[], panelistStage: PanelistStages) {
    if (!surveys?.length) {
      return;
    }

    let sortedSurveys = [...surveys].sort((a, b) => b.score - a.score);

    if (panelistStage == PanelistStages.PreBoostSurvey) {
      setTopRatedSurvey(sortedSurveys.shift()!);
    }

    setSortedSurveys(sortedSurveys);
  }

  function openSurvey(survey: SurveyWallSurvey) {
    setLoadingSurveyId(survey.id);

    setTimeout(() => {
      const {
        externalId,
        virtualId,
        link,
        reward,
        externalProviderId: surveyProviderId,
        searchId,
      } = survey;

      setCurrentSurvey(
        externalId,
        ProductTypes.SurveysWall,
        surveyProviderId,
        virtualId,
        searchId,
        link
      );

      navigate({
        pathname: "/pre-survey",
        search: createSearchParams({
          link: link,
          externalId: externalId,
          virtualId: virtualId || "",
          externalProviderId: surveyProviderId.toString(),
        }).toString(),
      });
    }, 300);
  }

  function isSurveyLoading(surveyId: string) {
    return loadingSurveyId === surveyId;
  }

  function isPanelistBoostStage() {
    return panelistStage === PanelistStages.BoostSurvey;
  }

  return (
    <div className={c["container"]}>
      <div className={c["surveywall"]}>
        {!!snackbarReward && !hideRewardSnackbar && (
          <RewardSnackbar
            rewardValue={snackbarReward}
            currencyName={configuration?.currency_name ?? ""}
          />
        )}
        <div className={c["surveywall-body"]}>
          {surveysLoaded && (
            <div className={c["survey-grid-margin"]}></div>
          )}
          {surveysLoaded && sortedSurveys.length > 0 && !!currencySale?.multiplier && (
            <CurrencySaleIndicator
              multiplier={currencySale.multiplier}
              endOn={currencySale.endOn}
              description={currencySale.description}
            />
          )}

          {surveysLoaded && getMoreSurveysEnabled && (
            <div
              onClick={() => navigate("/get-more-surveys")}
              className={c["get-more-surveys-container"]}
            >
              <div className={c["get-more-surveys-copy"]}>
                <img src={thumbsUpIcon} alt="thumbs-up" />
                <span>Get More Surveys</span>
              </div>
              <MdChevronRight size={28} />
            </div>
          )}

          {/* Shimmers */}
          {!surveysLoaded && profilerCompleted && (
            <div>
              <div className={`${c["top-rated-survey-card"]} ${c["shimmer-animation"]}`}></div>
              <div className={c["survey-grid"]}>
                {[1, 2, 3, 4].map((_, index) => (
                  <div
                    key={index}
                    className={`${c["survey-card"]} ${c["shimmer-animation"]}`}
                  ></div>
                ))}
              </div>
            </div>
          )}

          {/* --------- */}
          {surveysLoaded && !!topRatedSurvey && (
            <div className={`${c["top-rated-survey-card"]} ${c["boost-stage"]}`}>
              <div className={`${c["top-rated-survey-badge"]} ${c["boost-badge"]}`}>
                {t("EXTERNAL_SURVEY.INBRAIN.SURVEYS.BOOSTED")}
              </div>
              <div className={c["top-rated-survey-card-content"]}>
                <div className={c["survey-card-reward"]}>
                  <div>
                    <span className={c["crossed-out"]}>
                      {transformCurrency(
                        configuration?.currency_name ?? "",
                        Math.floor(topRatedSurvey.reward * 0.5),
                        true,
                      )}{" "}
                    </span>
                    {transformCurrency(configuration?.currency_name ?? "", topRatedSurvey.reward)}
                  </div>
                </div>
                <div className={c["survey-card-rating-and-loi"]}>
                  <FiveStarRating rating={5} starSize={15} starColor={secondaryColorCtx} />
                  <div className={c["survey-card-loi"]}>
                    <TbClock />
                    {topRatedSurvey.lengthOfInterview} {t("EXTERNAL_SURVEY.INBRAIN.SURVEYS.ITEM.TIME")}
                  </div>
                </div>
                <button
                  className={`${c["survey-card-cta"]} ${
                    isSurveyLoading(topRatedSurvey.id) ? c["animate"] : ""
                  }`}
                  onClick={() => openSurvey(topRatedSurvey)}
                >
                  {!isSurveyLoading(topRatedSurvey.id)
                    ? t("EXTERNAL_SURVEY.INBRAIN.SURVEYS.BUTTON_TEXT")
                    : t("EXTERNAL_SURVEY.INBRAIN.SURVEYS.LOADING")}
                </button>
              </div>
            </div>
          )}

          {surveysLoaded && sortedSurveys.length > 0 &&  (
            <>
            <div className={c["header-text"]}>
              <div className={c["heading"]}>
                {t(`EXTERNAL_SURVEY.INBRAIN.HEADER.POST_BOOST_TITLE`)}
              </div>
              <div className={c["copy"]}>
                {t(`EXTERNAL_SURVEY.INBRAIN.HEADER.POST_BOOST_INFO`, {
                  currency: configuration?.currency_name,
                })}
              </div>
            </div>
            <div className={c["survey-grid"]}>
              {sortedSurveys.map((survey, surveyIndex) => (
                <div
                  key={survey.id}
                  className={`${c["survey-card"]} ${
                    !!currencySale?.multiplier ? c["survey-card--active-currency-sale"] : ""
                  }`}
                >
                  <div className={c["survey-card-reward"]}>
                    <div>
                      {transformCurrency(configuration?.currency_name ?? "", survey.reward)}
                    </div>
                    {!!currencySale?.multiplier && (
                      <div className={c["currency-sale-container"]}>
                        <div
                          className={c["currency-sale-badge"]}
                          style={{
                            backgroundColor:
                                currencySale.multiplier < 1.2 ? primaryColorCtx : secondaryColorCtx,
                          }}
                        >
                          {getCurrencyMultiplierLabel(currencySale.multiplier)}
                        </div>
                        <div className={c["currency-sale-amount"]}>
                          {transformCurrency(
                            configuration?.currency_name ?? "",
                            currencySale?.multiplier
                              ? survey.reward / currencySale.multiplier
                                : survey.reward
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className={c["survey-card-rating-and-loi"]}>
                    <FiveStarRating
                      rating={surveyIndex !== 2 ? 5 : 4}
                      starColor={secondaryColorCtx}
                    />
                    <div className={c["survey-card-loi"]}>
                      <TbClock />
                        {survey.lengthOfInterview} {t("EXTERNAL_SURVEY.INBRAIN.SURVEYS.ITEM.TIME")}
                    </div>
                  </div>
                  <button
                    className={`${c["survey-card-cta"]} ${
                      isSurveyLoading(survey.id) ? c["animate"] : ""
                    }`}
                    onClick={() => openSurvey(survey)}
                  >
                    {!isSurveyLoading(survey.id)
                      ? t("EXTERNAL_SURVEY.INBRAIN.SURVEYS.BUTTON_TEXT")
                      : t("EXTERNAL_SURVEY.INBRAIN.SURVEYS.LOADING")}
                  </button>
                </div>
              ))}
            </div>
            </>
          )}

          {configuration?.profiler_questions_required && (
            <>
              <ProfilerPage onFinish={profilerFinished} />
            </>
          )}

          {surveysLoaded && sortedSurveys.length == 0 && (
            <div className={c["header-text"]}>
              <div className={c["heading"]}>
                {t(`EXTERNAL_SURVEY.INBRAIN.SURVEY_ERROR_EXTENDED`)}
              </div>
            </div>
          )}
        </div>
      </div>
      <MainFooter style={{}} />
    </div>
  );
}

export default SurveyWallPage;
