import { useEffect, useRef, useState } from "react";
import { useErrorHandler } from "contexts/errorContext";
import { useBreakpoint } from "hooks/useBreakpoint";
import { postAttempt, getLink } from "api/offers";
import { Goal } from "interfaces/Goal";
import { getCachedConfiguration } from "utils/functions/configurationUtil";
import { transformCurrency } from "utils/functions/currencyModUtil";
import { TiDeviceDesktop, TiVendorAndroid } from "react-icons/ti";
import { TiVendorApple } from "react-icons/ti";
import { IoIosArrowRoundDown } from "react-icons/io";
import OfferModalGoalTask from "./OfferModalGoalTask";
import OfferModalDescriptionTask from "./OfferModalDescriptionTask";
import blueArrow from "../../../assets/images/qr-blue-arrow.png";
import * as c from "./OfferModalContent.module.css";

interface OfferModalContentProps {
  modalContent: {
    goals?: Goal[];
    description?: string[];
    platforms: string[];
    operatingSystems?: string[];
    rewardIac: number;
    title: string;
    thumbnailUrl: string;
    offerId: number;
  };
  onOpenQrModal: (qrCode: string) => void;
  onOfferAttempt?: () => void;
}

type Platform = "Android" | "iOS" | "Windows";

const platformIcons: Record<Platform, JSX.Element> = {
  Android: <TiVendorAndroid color="#A6D864" />,
  iOS: <TiVendorApple />,
  Windows: <TiDeviceDesktop size={19} color="#00a5ed" />,
};

const OfferModalContent = ({
  modalContent,
  onOpenQrModal,
  onOfferAttempt,
}: OfferModalContentProps) => {
  const handleError = useErrorHandler();
  const { isMobile, isTablet, isDesktop } = useBreakpoint();
  const {
    goals,
    description,
    platforms,
    operatingSystems,
    rewardIac,
    title,
    thumbnailUrl,
    offerId,
  } = modalContent;
  const configuration = getCachedConfiguration();
  const currency = transformCurrency(configuration!.currency_name!, rewardIac);
  const [url, setUrl] = useState("");
  const [qrCode, setQrCode] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const modalContentRef = useRef<HTMLDivElement>(null);
  const SCROLL_THRESHOLD = 100;

  useEffect(() => {
    setIsLoading(true);
    getLink(offerId, configuration!.hashed_publisher_app_uid!)
      .then((data) => {
        setUrl(data.link);
        setQrCode(data.qrCode);
        setIsLoading(false);
      })
      .catch((error) => {
        handleError(error);
      });
  }, []);

  useEffect(() => {
    const modal = modalContentRef.current;
    if (modal) {
      modal.addEventListener("scroll", handleModalScroll);
    }
    return () => {
      if (modal) {
        modal.removeEventListener("scroll", handleModalScroll);
      }
    };
  }, []);

  const handleSendAttempt = () => {
    postAttempt(offerId, configuration!.hashed_publisher_app_uid!)
      .then(() => {
        onOfferAttempt?.();
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const handleModalScroll = () => {
    const modal = modalContentRef.current;
    if (modal) {
      const scrollPosition = modal.scrollTop;
      const maxScrollHeight = modal.scrollHeight - modal.clientHeight;
      const thresholdPosition = maxScrollHeight - SCROLL_THRESHOLD;

      if (scrollPosition < thresholdPosition) {
        setIsButtonVisible(true);
      } else {
        setIsButtonVisible(false);
      }
    }
  };

  const scrollModalContent = () => {
    const modal = modalContentRef.current;
    if (modal) {
      modal.scrollBy({ top: 400, behavior: "smooth" });
    }
  };

  const detectMobileOS = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) return "iOS";
    if (/android/i.test(userAgent)) return "Android";
    return "";
  };

  const mobileOS = detectMobileOS();

  const isWebAvailable =
    operatingSystems?.includes("Windows") || operatingSystems?.includes("MacOS");

  return (
    <div className={c["modal-content"]}>
      <div
        className={`${c["modal-content-task-wrapper"]} ${
          detectMobileOS() != "" && c["no-web-style"]
        }`}
        ref={modalContentRef}
      >
        <div className={c.header}>
          <img src={thumbnailUrl} alt="Game Icon" className={c.icon} />
          <div className={c["header-text"]}>
            <span className={c["title"]}>{title}</span>
            <span className={c["sub-title"]}>Earn up to {currency} with this offer</span>
            <div className={c["platform-icons"]}>
              {operatingSystems !== null &&
                operatingSystems?.length !== 0 &&
                operatingSystems?.map((platform) => (
                  <span key={platform}>{platformIcons[platform as Platform] || null}</span>
                ))}
            </div>
          </div>
        </div>

        {!isMobile && qrCode !== "" && (
          <div
            className={`${c["qr-content-container"]} ${
              !isWebAvailable ? c["no-web-style"] : c["web-style"]
            }`}
          >
            {isWebAvailable && (
              <div className={c["get-started-button-wrapper"]}>
                <a
                  href={url}
                  onClick={handleSendAttempt}
                  target="_blank"
                  className={c["sign-up-button"]}
                  style={{ fontSize: "14px" }}
                >
                  Get started on web
                </a>
              </div>
            )}
            {isWebAvailable && <span className={c["qr-separator"]}>OR</span>}
            <div className={c["qr-code-container"]}>
              <div className={c["qr-code-text-wrapper"]}>
                <span>Click here to start on your phone</span>
              </div>
              <div className={c["qr-blue-arrow-wrapper"]}>
                <img src={blueArrow} alt="Blue Arrow" />
              </div>
              <div
                className={c["qr-platform-icons"]}
                onClick={(e) => {
                  onOpenQrModal(qrCode);
                  handleSendAttempt();
                }}
              >
                {operatingSystems !== null &&
                  operatingSystems?.length !== 0 &&
                  operatingSystems
                    ?.filter((platform) => platform === "Android" || platform === "iOS")
                    .map((platform) => (
                      <span key={platform}>{platformIcons[platform as Platform] || null}</span>
                    ))}
              </div>
            </div>
          </div>
        )}

        {description && description.length > 0 && (
          <div className={c.tasks}>
            <OfferModalDescriptionTask description={description} isMobileOs={mobileOS} />
          </div>
        )}
        {goals && goals.length > 0 && (
          <div className={c.tasks}>
            {goals.map((goal, index) => (
              <OfferModalGoalTask
                key={goal.id}
                goal={goal}
                currencyName={configuration!.currency_name!}
                index={index}
              />
            ))}
          </div>
        )}
      </div>
      <div className={c["button-wrapper"]}>
        {isButtonVisible && goals && goals.length > 3 && (
          <div className={c["scroll-down-button-wrapper"]}>
            <button className={c["scroll-down-button"]} onClick={scrollModalContent}>
              <IoIosArrowRoundDown size={"18px"} style={{ marginRight: "2px" }} /> More goals
            </button>
          </div>
        )}

        {!isWebAvailable && !isMobile && (
          <a
            onClick={(e) => {
              e.preventDefault();
              onOpenQrModal(qrCode);
              handleSendAttempt();
            }}
            className={`${c["sign-up-button"]} ${!isMobile && isLoading && c["disabled"]}`}
          >
            Get started and earn up to <span className={c.points}>&nbsp;{currency}</span>
          </a>
        )}

        {(isMobile || isWebAvailable) && (
          <a
            href={url}
            onClick={(e) => {
              handleSendAttempt();
            }}
            target="_blank"
            className={`${c["sign-up-button"]} ${!isMobile && isLoading && c["disabled"]}`}
          >
            Get started and earn up to <span className={c.points}>&nbsp;{currency}</span>
          </a>
        )}
      </div>
    </div>
  );
};

export default OfferModalContent;
