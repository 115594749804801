import { useEffect, useState } from "react";
import { AxiosError } from "axios";
import { useErrorHandler } from "contexts/errorContext";
import { setPanelistState } from "api/panelist";
import { getCachedConfiguration, setCacheConfiguration } from "utils/functions/configurationUtil";
import { FaCheck } from "react-icons/fa";
import { RiEqualizerFill } from "react-icons/ri";
import { FiChevronDown } from "react-icons/fi";
import { FiChevronUp } from "react-icons/fi";
import * as c from "./OfferFilter.module.css";

const filterOptions = ["iOS", "Android", "Desktop"];

interface OfferFilterProps {
  onDeviceSelection: (filters: string[]) => void;
  onStartSpinner: () => void;
}

const OfferFilter = ({ onDeviceSelection, onStartSpinner }: OfferFilterProps) => {
  const handleError = useErrorHandler();
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);
  const [prevFilters, setPrevFilters] = useState<string[]>([]);
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const displayOption = (option: string) => `${option} Offers`;

  useEffect(() => {
    const configuration = getCachedConfiguration();
    const configFilters = configuration?.offerDesktopDeviceTypesFilter || [];

    const initial = filterOptions.filter((option) =>
      configFilters.some((configFilter: string) =>
        option.toLowerCase().includes(configFilter.toLowerCase()),
      ),
    );

    setSelectedFilters(initial);
    setPrevFilters(initial);
  }, []);

  const arraysEqual = (arr1: string[], arr2: string[]) =>
    arr1.length === arr2.length && arr1.sort().join(",") === arr2.sort().join(",");

  const hasChanges = !arraysEqual(selectedFilters, prevFilters);

  const applyFilters = () => {
    onStartSpinner?.();
    const configuration = getCachedConfiguration();
    setPrevFilters(selectedFilters);
    setDropdownVisible(false);
    setPanelistState(configuration!.hashed_publisher_app_uid!, {
      offerDesktopDeviceTypesFilter: selectedFilters,
    })
      .then((resp) => {
        configuration!.offerDesktopDeviceTypesFilter = selectedFilters;
        setCacheConfiguration(configuration!);
        onDeviceSelection?.(selectedFilters);
      })
      .catch((error: AxiosError) => {
        handleError(error);
        console.error("Failed to resolve geolocation: ", error);
      });
  };

  const toggleFilter = (filter: string) => {
    setSelectedFilters((prevFilters) =>
      prevFilters.includes(filter)
        ? prevFilters.filter((f) => f !== filter)
        : [...prevFilters, filter],
    );
  };

  const isFilterSelected = (filter: string) => selectedFilters.includes(filter);

  return (
    <div className={c["filter-component"]}>
      <div className={c["dropdown-container"]}>
        <button
          className={`${c["dropdown-button"]} ${dropdownVisible ? c["dropdown-open"] : ""}`}
          onClick={() => setDropdownVisible(!dropdownVisible)}
        >
          <RiEqualizerFill style={{ marginRight: "10px" }} />
          Filters
          {!dropdownVisible ? (
            <FiChevronUp style={{ marginLeft: "50px" }} />
          ) : (
            <FiChevronDown style={{ marginLeft: "50px" }} />
          )}
        </button>
        {dropdownVisible && (
          <div className={c["dropdown-menu"]}>
            <div className={c["dropdown-section"]}>
              <div className={c["dropdown-items"]}>
                {filterOptions.map((option) => (
                  <div
                    key={option}
                    className={`${c["dropdown-item"]} ${
                      isFilterSelected(option) ? c["selected"] : ""
                    }`}
                    onClick={() => toggleFilter(option)}
                  >
                    {displayOption(option)}
                    {isFilterSelected(option) && (
                      <span className={c["task-status"]}>
                        <FaCheck />
                      </span>
                    )}
                  </div>
                ))}
                <button
                  className={c["update-filters-button"]}
                  disabled={!hasChanges || selectedFilters.length === 0}
                  onClick={applyFilters}
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default OfferFilter;
