import * as c from "./SelectOneItem.module.css";

interface SelectOneItemProps {
  value: string;
  isSelected: boolean;
  onSelect: () => void;
  usedInMore: boolean;
}

function SelectOneItem({ value, isSelected, onSelect, usedInMore }: SelectOneItemProps) {
  return (
    <div
      className={`${usedInMore ? c["select-one-container-more"] : c["select-one-container"]} ${
        isSelected ? (usedInMore ? c["selected-more"] : c["selected"]) : ""
      } `}
    >
      <input
        type="radio"
        id={`checkbox_for_${value}`}
        value={value}
        checked={isSelected}
        onChange={onSelect}
      />
      <label htmlFor={`checkbox_for_${value}`}>{value}</label>
    </div>
  );
}

export default SelectOneItem;
