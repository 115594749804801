import { useEffect, useState } from "react";
import { changeOpacity } from "utils/functions/colorUtil";
import { getCurrencyMultiplierLabel } from "utils/functions/currencySaleUtil";
import * as c from "./CurrencySaleIndicator.module.css";
import { getColors } from "utils/functions/configurationUtil";

function CurrencySaleIndicator({
	multiplier,
	description,
	endOn,
}: {
	multiplier: number;
	description?: string;
	endOn: string;
}) {
	const secondaryColor = getColors()!.secondaryColor;

	useEffect(() => {
		calculateRemainingSaleTime();
	}, []);

	const [currencySaleRemainingTime, setCurrencySaleRemainingTime] = useState({
		hours: 0,
		minutes: 0,
	});

	function calculateRemainingSaleTime(): void {
		const endMs = new Date(endOn).getTime();
		const nowMs = new Date().getTime();
		if (nowMs < endMs) {
			const diff = endMs - nowMs;
			const diffInSeconds = Math.floor(diff / 1000);

			setCurrencySaleRemainingTime({
				hours: Math.floor(diffInSeconds / 3600),
				minutes: Math.floor((diffInSeconds % 3600) / 60),
			});
			setTimeout(() => {
				calculateRemainingSaleTime();
			}, 60 * 1000);
		} else {
			setCurrencySaleRemainingTime({
				hours: 0,
				minutes: 0,
			});
		}
	}

	return (
		<div
			className={c["currency-sale-indicator"]}
			style={{borderColor: secondaryColor}}
		>
			<div
				className={c["multiplier-badge"]}
				style={{ backgroundColor: secondaryColor }}
			>
				{getCurrencyMultiplierLabel(multiplier)}
			</div>
			<div className={c["sale-info"]}>
				<div className={c["sale-info-description"]}>{description}</div>
				<div className={c["sale-info-time"]}>
					<span className={c["time-badge"]}>
						{currencySaleRemainingTime.hours}h
					</span>
					<span className={c["colon-separator"]}>:</span>
					<span className={c["time-badge"]}>
						{currencySaleRemainingTime.minutes}m
					</span>
				</div>
			</div>
		</div>
	);
}

export default CurrencySaleIndicator;
