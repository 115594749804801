import { useTranslation } from "react-i18next";
import CountryBlocked from "../../assets/images/country_blocked.png";
import * as c from "./CountryBlockedPage.module.css";

function CountryBlockedPage() {
  const { t } = useTranslation();
  const translatedString = t("INBRAIN_THEME.BLOCKED_COUNTRY.DESCRIPTION");
  return (
    <div className={c["profiler-container"]}>
      <div className={c["inbrain-top-header"]}></div>
      <div className={c["country-block-wrapper"]}>
        <div className={c["country-block-content"]}>
          <span>{t("INBRAIN_THEME.BLOCKED_COUNTRY.TITLE")}</span>
          <img src={CountryBlocked} alt="vpn-blocked" />
          <div dangerouslySetInnerHTML={{ __html: translatedString }}></div>
        </div>
      </div>
    </div>
  );
}

export default CountryBlockedPage;
