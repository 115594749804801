import { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import BaseCallbackPage, { CallbackStatuses } from "./BaseCallbackPage";
import useInBrainCallback from "../../hooks/useInBrainCallback";

function SimulationCallbackPage() {
  const { type } = useParams();

  let callbackStatus =
    type === "completion" ? CallbackStatuses.Complete : CallbackStatuses.Disqualification;

  const {
    isLoading,
    reward,
    displayedReward,
    currencyName,
    isRewardVisibilityDisabled,
    isDisqualificationDisabled,
    showAnimatedCta,
    handleCallback,
    navigateNext
  } = useInBrainCallback(callbackStatus);

  const queryString = location.href.substring(location.href.indexOf("?") + 1);
  const callbackPath = `/external-provider-callback/simulation-survey-${
    callbackStatus === CallbackStatuses.Complete ? "success" : "fail"
  }?${queryString}`;

  const firstRender = useRef(true);
  useEffect(() => {
    const url = `${process.env.API_URL}${callbackPath}`;

    if (firstRender.current) {
      handleCallback(url);
      firstRender.current = false;
    }
  }, []);

  return (
    <BaseCallbackPage
    isLoading={isLoading}
    callbackStatus={callbackStatus}
    currencyName={currencyName!}
    reward={reward}
    displayedReward={displayedReward}
    isRewardVisibilityDisabled={isRewardVisibilityDisabled}
    isDisqualificationDisabled={isDisqualificationDisabled}
    showAnimatedCta={showAnimatedCta}
    closeView={navigateNext}
    />
  );
}

export default SimulationCallbackPage;
