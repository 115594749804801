export const PT_BR_JSONtext = {
  PRIVACY_POLICY: `
    <h2>INBRAIN, LLC</h2>
    <h2>POLÍTICA DE PRIVACIDADE</h2>
    <ol>
    <div class='terms-header' ngClass.lt-md='terms-header-mobile' fxLayoutAlign='center center'>
      <p>Última atualização: <strong><u>4 de janeiro de 2021</u></strong></p>
    </div>
    <p class='introduction'>A inBrain, LLC (“inBrain”, “nós”, “nós” ou “nossos”) está empenhada em proteger a sua (“sua” ou “suas”) privacidade e capacitação com esta política de privacidade (esta “Política de Privacidade”). Esta Política de Privacidade explica como coletamos, usamos, divulgamos e aplicamos informações coletadas durante a utilização do nosso site (<a href='https://www.inbrain.ai/'>https://www.inbrain.ai/</a>) ou de quaisquer dos outros serviços que disponibilizamos (coletivamente, “Serviços“). Como aplicável a você, nossos Termos de Serviços do Criador de Pesquisa, Termos de Serviço do Editor e Termos de Serviço do Pesquisador (coletivamente, os “ Termos“) são expressamente incorporados aqui por referência e são parte integrante deste documento. Os Termos podem ser encontrados aqui: <a href='https://www.inbrain.ai/terms-and-policies/terms'>https://www.inbrain.ai/terms-and-policies/terms</a>.<br/>Ao usar nossos Serviços ou interagir conosco, você concorda com esta Política de Privacidade. Caso não concorde com os termos desta Política de Privacidade, não acesse ou use os Serviços. Por favor, leia com atenção para entender como coletamos, usamos e mantemos suas informações pessoais. Além disso, o termo descreve suas opções de uso, acesso e correção de suas informações pessoais.</p>
    <div class='changes-to-this-privacy-policy'>
      <li class='list-title'>
        <h3>Alterações nesta Política de Privacidade.</h3>
      </li>
      <p>Nós podemos alterar esta Política de Privacidade, a nosso exclusivo critério, de acordo com a lei, com o passar do tempo. Se houver alterações, avisaremos e alteraremos a “Última data de Atualização“ no topo desta Política de Privacidade. Caso haja mudanças significativas nesta Política de Privacidade, tentaremos notificá-lo diretamente por e-mail ou através de nossa página inicial antes que tais alterações tenham efeito. Nós encorajamos você a revisitar nossa Política de Privacidade sempre que usar os Serviços para se informar sobre as formas de proteger sua privacidade.</p>
    </div>

    <div class='informations-we-collect-and-receive'>
      <li class='list-title'>
        <h3>Informações coletadas e recebidas</h3>
      </li>
      <p>Nós coletamos informações para fornecer os Serviços solicitados, ajudar a melhorar sua experiência e auxiliar nossas funções de negócios. Coletamos suas informações de várias formas durante a interação com nossos serviços. Mais informações sobre as categorias e fontes de informação são fornecidas abaixo.</p>
      <ol>
        <li><strong>As Informações Fornecidas por Você</strong>. Nós coletamos as informações que você decide compartilhar conosco. Em alguns casos, podemos exigir que você forneça certas informações para usar certas partes do nosso Site, atender às suas solicitações ou fornecer determinados Serviços.</li>
        <ol class='info-provided-to-us'>
          <li><strong>Registrando uma conta</strong>. Caso tenha uma conta conosco, podemos exigir que você forneça seu nome, e-mail, sexo, CEP, status de relacionamento, status de emprego, renda, raça e etnia, aniversário e uma senha para criar sua conta. Também podemos perguntar se você possui o direito de votar, o número de pessoas que vivem com você, a receita aproximada de suas organizações, indústrias em que trabalham em sua casa, quem em sua casa toma certas decisões e seu nível de escolaridade.</li>
          <li><strong>Informações de pesquisa</strong>. Quando você faz uma pesquisa, podemos coletar as informações que você envia em uma pesquisa e informações sobre o recebimento e resgate de recompensas e incentivos.</li>
          <li><strong>Informações de conteúdo</strong>. Você também pode optar por nos enviar informações pessoais em um e-mail com perguntas sobre os Serviços. Nós usamos essas informações para nos ajudar a responder ao seu suas perguntas. Você também pode optar por enviar informações através de outros métodos, incluindo: (i) resposta ao marketing ou outras comunicações, (ii) redes sociais ou fóruns online, (iii) participação em uma oferta, programa ou promoção, ou (iv) em conexão com uma relação comercial real ou potencial conosco.</li>
        </ol>
        <li class='no-list-style'><strong>2.2.  As informações que recebemos de terceiros</strong>. Podemos obter informações de terceiros que nos ajudam a fornecer nossos Serviços, sendo os outros sistemas de informação disponíveis publicamente e serviços de informações comerciais. Também podemos obter informações de nossos parceiros de marketing, a fim de informá-lo sobre produtos, novas pesquisas ou Serviços que achamos que você possa estar interessado. Finalmente, podemos obter informações que não o identifiquem diretamente, mas são usadas posteriormente de uma forma que possam identificá-lo no futuro. Podemos combinar essas informações gerais com suas informações para fornecer serviços relacionados a você.
        </li>
        <li class='no-list-style'><strong>2.3.  Informações Coletadas Automaticamente</strong>. Coletamos automaticamente certas informações quando você usa os Serviços, incluindo informações de atividades na internet ou outras redes (conforme descrito abaixo), identificadores exclusivos de dispositivos, histórico de navegação e pesquisa (incluindo conteúdo que você visualizou nos Serviços), cookies e outras tecnologias (conforme definido em Seção 3). As informações coletadas automaticamente incluem:</li>
        <ol>
          <li class='no-list-style'><strong>2.3.1  Informações de dispositivo</strong>. Coletamos informações técnicas quando você visita nossos Serviços. Isso inclui informações sobre o tipo de dispositivo ou navegador utilizado, o sistema operacional do seu dispositivo, seu provedor de serviços de internet, as configurações regionais e de idiomas do seu dispositivo e identificadores de dispositivos como endereço IP e ID de anúncios. Quando você visita e interage com os nossos Serviços, podemos coletar certas informações automaticamente através de cookies ou outras tecnologias, incluindo, mas não se limitando a, o tipo de computador ou dispositivo móvel utilizado, ID exclusivo do dispositivo móvel, endereço IP do seu computador ou dispositivo móvel, sistema operacional, tipo (s) de navegador de internet que você usa e informações sobre sua utilização dos Serviços.</li>
          <li class='no-list-style'><strong>2.3.2  Informações de uso</strong>. Coletamos informações sobre a utilização dos Serviços em questão, incluindo dados sobre recursos que você usa, páginas que acessa, e-mails e anúncios que vê, partes dos Serviços com os quais você interage e utiliza, a hora do dia em que você navega e as suas referências e as páginas de saída.</li>
          <li class='no-list-style'><strong>2.3.3.  Travamento e Informações de Erro.</strong>.Caso algum dos Serviços falhe ou resulte em erro, poderemos coletar dados para determinar a causa do erro. As informações de erro coletadas podem incluir o seguinte: endereço IP, nome do dispositivo, nome da versão do sistema operacional, configuração(ões) do aplicativo, hora, data e outras estatísticas.</li>
        </ol>
      </ol>
    </div>

    <div class='cookies-and-other-technologies'>
      <li class='list-title'>
        <h3>Cookies e Outras Tecnologias</h3>
      </li>
      <p>
      Como muitos outros sites, nossos Serviços podem usar “Cookies“ ou “Outras Tecnologias“ (como “pixel tags“, “web beacons“, “GIFs claros“, links em e-mails, JavaScript, IDs de dispositivo atribuídos pelo Google ou Apple, ou tecnologias similares) para coletar informações e dar suporte a certos recursos de nossos Serviços. Os cookies e outras tecnologias permitem que nós e terceiros coletemos informações sobre suas visitas aos nossos Serviços, incluindo a análise de seus padrões de visita. Embora não seja obrigado a aceitar os Cookies quando visitar nossos Serviços, você pode ser incapaz de usar todas as funcionalidades de nossos Serviços caso não o faça. Nós utilizamos essas informações para processar suas solicitações e para entregar anúncios on-line, mensagens e conteúdo para nós e outras partes interessadas. As informações que coletamos do seu navegador da Web, Cookies e de outras tecnologias não o identificam pessoalmente, mas, em alguns casos podemos vinculá-lo a informações que o façam.
      </p>
      <ol>
        <li><strong>Cookies</strong>. Abaixo, explicamos os diferentes tipos de Cookies que podem ser utilizados em nossos Serviços.
        </li>
        <ol>
          <li><strong>Cookies necessários</strong>. Os cookies necessários são essenciais para permitir sua utilização dos Serviços e para identificar e prevenir brechas de segurança, como guardar as informações da sessão para impedir que outras pessoas alterem sua senha, nome de usuário ou ambos.</li>
          <li><strong>Cookies Preferenciais</strong>. Esses tipos de Cookies guardam suas configurações e preferências para melhorar sua experiência em nossos Serviços. Por exemplo, podemos usar esses Cookies para nos lembrar de suas preferências de idioma.</li>
          <li><strong> Cookies de Sessão</strong>. Esses tipos de Cookies são armazenados apenas temporariamente durante uma sessão de navegação e são excluídos do seu dispositivo assim que o navegador é fechado. Nós utilizamos cookies de sessão para suporte funcional dos Serviços e para entender os Serviços são utilizados — ou seja, quais páginas você visita, links você usa e por quanto tempo fica em cada página.</li>
          <li><strong>Cookies de estatísticas</strong>. Os Cookies de estatística ajudam a coletar informações sobre o uso dos Serviços e nos permitem melhorar a forma como ele funciona. Esses Cookies fornecem informações agregadas que usamos para monitorar o desempenho dos Serviços, contar visitas de página, detectar erros técnicos, ver como os usuários chegam aos Serviços e medir a eficiência das ações de marketing (incluindo e-mails).</li>
          <li><strong>Cookies de Marketing</strong>. Cookies de marketing são usados para mostrar anúncios em sites para os usuários. A intenção é exibir anúncios relevantes e envolventes para o usuário individual e, portanto, mais valiosos para editores e anunciantes terceirizados. Nós não exibimos anúncios para nenhum serviço além do nosso.</li>
          <li><<strong>Cookies não classificados</strong>. Cookies não classificados são os Cookies que ainda estão em processo de classificação, juntamente com os provedores de Cookies individuais.</li>
        </ol>
        <li class="no-list-style"><strong>3.2.  Outras tecnologias</strong>. Além dos Cookies, podemos usar outras tecnologias como pixel tags (também conhecidas como web beacons e GIFs claros) para, entre outras coisas, acompanhar as ações dos usuários dos Serviços (sobre o uso, incluindo destinatários de e-mail), medir o sucesso de nossas campanhas de marketing e compilar estatísticas dos Serviços e taxas de resposta.</li>
      </ol>
    </div>

    <div class='how-we-use-your-information'>
      <li class='list-title'>
        <h3>Como Usamos suas informações</h3>
      </li>
      <ol>
        <li>
        Podemos utilizar as informações coletadas com a finalidade limitada de fornecer os Serviços, funcionalidades e serviços relacionados, conforme descrito nos Termos e nesta Política de Privacidade, e conforme permitido pelas leis vigentes. Esses propósitos limitados incluem circunstâncias nas quais é necessário fornecer ou cumprir os Serviços solicitados por ou para você ou nos quais seu consentimento expresso foi oferecido. Podemos usar as informações que obtemos sobre você para:
        </li>
        <ol>
          <li>Fornecer informações, produtos e Serviços solicitados;</li>
          <li>Cuidar da sua conta e fornecer, caso você seja um usuário registrado, atendimento mais eficiente ao cliente;</li>
          <li>Entender melhor suas necessidades e interesses e oferecer uma experiência personalizada ao usar nossos Serviços;</li>
          <li>Entrar em contato com ofertas especiais e outras informações que acreditamos ser de seu interesse (de acordo com quaisquer preferências de privacidade que você tenha expressado);</li>
          <li>Entrar em contato com informações e avisos relacionados ao uso de nossos Serviços, incluindo nossos Serviços;</li>
          <li>Convidá-lo a participar de pesquisas e nos fornecer comentários (de acordo com quaisquer preferências de privacidade que você tenha expressado;</li>
          <li>Melhorar nossos produtos e Serviços e desenvolver novos produtos e Serviços, melhorar nosso marketing, promoções, conteúdo, funcionalidade e usabilidade dos Serviços;</li>
          <li>Aplicar nossos Termos;</li>
          <li>Promover a segurança, proteger contra e prevenir fraudes, sinistros e outros passivos;</li>
          <li>Verifique as informações que você nos fornece, bem como as representações e garantias que você nos oferece nos Termos ou Serviços;</li>
          <li>Atender a nossas obrigações de auditoria internas e externas;</li>
          <li>Criar oportunidades de pesquisa para você e notificar sobre essas oportunidades;</li>
          <li>Administrar e gerenciar nossos programas de recompensa e incentivo, atender pedidos de recompensas e incentivos; e</li>
          <li>Usar para qualquer outra finalidade para a qual fornecemos aviso no momento da coleta.</li>
        </ol>
        <li>Podemos agregar e/ou desidentificar informações coletadas através dos Serviços e de outras fontes para que essas informações não possam mais ser vinculadas a você ou ao seu dispositivo (“Informações Agregadas/Identificadas“). Podemos usar Informações Agregadas/Desidentificadas para qualquer propósito, mas não limitado a, fins de pesquisa e marketing.</li>
      </ol>
    </div>

    <div class='how-we-use-and-disclose-info'>
      <li class='list-title'>
        <h3>Como compartilhamos e divulgamos informações?.</h3>
      </li>
      <ol>
        <li><strong>Nós não vendemos informações pessoais</strong>. Nós não vendemos ou divulgamos informações pessoais que coletamos sobre você, exceto conforme descrito aqui ou divulgado a você no momento em que os dados são coletados. Compartilhamos informações para executar atividades solicitadas por você.</li>
        <li><strong>Prestadores de serviços e parceiros de negócios</strong>. Também compartilhamos informações com fornecedores com os quais temos uma relação contratual e que estão prestando serviços, incluindo, mas não limitado a, serviços de apêndice de dados, de validação de dados, de detecção e/ou prevenção de fraudes, de correspondência de banco de dados, de codificação, de segmentação de dados e serviços relacionados a recompensas e incentivos, bem como parceiros de pesquisa de mercado que fornecem pesquisas.</li>
        <li><strong>O Seu Consentimento</strong>. Podemos compartilhar informações para outros fins, de acordo com seu consentimento ou orientação posterior. </li>
        <li><strong>Dados agregados / desidentificados</strong>. Podemos compartilhar informações agregadas/desidentificadas com terceiros, incluindo anunciantes, parceiros promocionais, parceiros de negócios e canais, patrocinadores e/ou outros.</li>
        <li><strong>Motivos legais</strong>. Também podemos divulgar informações sobre você quando nós, de boa fé, acreditarmos que isso é necessário para cumprir a lei, uma ordem judicial ou uma imposição. Também podemos divulgar informações sobre você para prevenir ou investigar um possível crime, como fraude ou roubo de identidade; para proteger a segurança de nossos Serviços; para aplicar nossos Termos ou outros acordos; proteger nossos próprios direitos ou propriedade, bem como a segurança de outros usuários. Tentaremos notificar nossos usuários sobre demandas legais por suas informações pessoais quando julgarmos apropriado, a menos que seja proibido por lei ou ordem judicial ou quando a solicitação for uma emergência. Podemos contestar tais exigências quando acreditamos, a nosso critério, que os pedidos são super-revogais, vagos ou sem autoridade adequada.</li>
        <li><strong>Venda, Fusão ou Outra Transferência de Negócios</strong>. Nós nos reservamos o direito de transferir informações sobre você para uma organização sucessora se transferirmos a propriedade ou operação de um de nossos sites para outra organização ou se nos fundirmos com outra organização. Caso tal transferência ocorra, faremos todos os esforços razoáveis para orientar o novo dono a usar as informações pessoais que você nos forneceu de forma consistente com esta Política de Privacidade. Após tal venda ou transferência, você pode entrar em contato com a entidade para a qual transferimos suas informações pessoais com quaisquer perguntas sobre o processamento dessas informações.</li>
      </ol>
    </div>

    <div class='your-choices'>
      <li class='list-title'>
        <h3>suas escolhas</h3>
      </li>
      <ol>
        <li>Você pode gerenciar nossos cookies através do seu navegador. A maioria dos navegadores dirá como interromper a aceitação novos Cookies, como ser notificado quando receber um novo Cookie e como desativar cookies existentes. Você pode descobrir como fazer isso em seu navegador em particular clicando em “ajuda“ no menu do seu navegador ou visitando <a href='https://www.allaboutcookies.org'>www.allaboutcookies.org</a>. No entanto, note que, sem cookies você pode não ser capaz de utilizar todos os recursos de nosso site. Além dos controles baseados no navegador, você pode gerenciar cookies de terceiros visitando <a href='https://www.aboutads.info/choices/'>www.aboutads.info/choices/</a>. Para escolher separadamente em cada um de seus aplicativos móveis em um dispositivo móvel, você pode baixar o aplicativo AppChoices da DAA na loja de aplicativos do seu dispositivo. Como alternativa, para alguns dispositivos, você pode usar os controles de plataforma do seu dispositivo em suas configurações para exercer a escolha. Por favor, note que você deve fazer suas opções separadamente em cada navegador e em cada dispositivo. Anúncios em sites de terceiros que contêm o link AdChoices podem ter sido direcionados a você com base em informações coletadas por parceiros de publicidade ao longo do tempo. Esses anúncios oferecem um mecanismo de opção pelo uso dessas informações pelos parceiros de publicidade para fins publicitários baseados em interesse.</li>
        <li>Caso tenha criado uma conta, você pode acessar, revisar e atualizar certas informações pessoais que nos forneceu fazendo login em sua conta e usando recursos e funcionalidades disponíveis.</li>
        <li>A qualquer momento, você pode nos impedir de enviar e-mails para fins de marketing, ajustando suas configurações de conta. Você também pode não receber alguns outros e-mails vindos de nós ajustando suas configurações de conta. Você também pode optar por não enviar mensagens de e-mail comerciais seguindo o link “cancelar a inscrição“ incluído nessas mensagens. Observe que, apesar de quaisquer preferências de e-mail marketing indicadas, podemos continuar a enviar e-mails administrativos sobre o InBrain, incluindo, por exemplo, avisos de atualizações de nossos Termos ou dessa Política de Privacidade, notificações sobre novas oportunidades de pesquisa, se optarmos por fornecer tais avisos a você desta maneira.</li>
        <li>Alguns navegadores oferecem a opção “não rastrear“ (“DNT“). Como nenhuma indústria comum ou padrão legal para DNT foi adotada por grupos industriais, empresas de tecnologia ou reguladores, não respondemos aos sinais de DNT. Nos esforçaremos para continuar monitorando os desenvolvimentos em torno da tecnologia de navegador DNT e a implementação de um padrão.</li>
      </ol>
    </div>

    <div class='european-residents'>
      <li class='list-title'>
        <h3>Cidadãos Europeus</h3>
      </li>
      <p>Caso seja um morador da Área Econômica Europeia, você tem certos direitos sobre as informações pessoais que mantemos sobre você. Oferecemos certas escolhas sobre quais informações pessoais coletamos, como usamos essas informações e como nos comunicamos com você. Caso queira exercer seus direitos em algum momento, entre em contato conosco de acordo com a seção “Fale conosco“ abaixo:</p>
      <ol>
        <li><strong>Informações e Acesso</strong>. Você pode pedir para conferir suas informações pessoais mantidas por nós. Podemos informá-lo sobre como e por que processamos esses dados, a menos que isso seja restrito por lei, ou caso não possamos autentificar sua identidade.</li>
        <li><strong>Enviando informações</strong>. Você pode se abster de enviar informações diretamente para nós, embora isso possa afetar nossa capacidade de fornecer os serviços e informações solicitados e/ou afetar sua capacidade de usar os Serviços.</li>
        <li><strong>Retificação</strong>. Você pode pedir para atualizar ou corrigir as informações pessoais que mantemos para você.</li>
        <li><strong>Direito de exclusão/solicitação</strong>. Você pode solicitar a exclusão de suas informações pessoais. Podemos solicitar informações adicionais para verificar sua identidade e processar sua solicitação, de acordo com a lei.</li>
        <li><strong>Restrição</strong>. Você pode solicitar a limitação no processamento de suas informações pessoais.
        </li>
        <li><strong>Restringindo cookies</strong>. Você pode optar por não ter um número único de identificação de cookies atribuído ao seu computador para evitar agregação e análise de dados coletados em nossos sites. Geralmente, caso não queira receber cookies, você pode configurar seu navegador para negar cookies ou alertá-lo quando um cookie é colocado em seu computador.</li>
        <li><strong>Objeção</strong>. Você pode negar consentimento para o processamento de suas informações pessoais.</li>
        <li><strong>Transferência</strong>. Você pode solicitar a transferência de suas informações pessoais.</li>
      </ol>
    </div>

    <div class='california-residents'>
      <li class='list-title'>
        <h3>Moradores da Califórnia</h3>
      </li>
      <ol>
        <li>Caso more no Estado da Califórnia, você tem certos direitos concedidos pela Lei de Privacidade do Consumidor da Califórnia ou o “CCPA“. Quando dizemos “Informações Pessoais“ nesta seção, queremos dizer informações que identifiquem, se relacionem, descrevam, sejam razoavelmente capazes de ser associadas a você ou possam estar razoavelmente ligadas, direta ou indiretamente, com você. As Informações Pessoais não incluem informações agregadas ou informações que não possam ser razoavelmente vinculadas a você.</li>
        <li>Coletamos Informações Pessoais e Informações Não Pessoais para fins comerciais e comerciais descritos na seção acima intitulada “Como usamos suas informações“, e compartilhamos Informações Pessoais com as categorias de terceiros descritas nesta Política de Privacidade. Nós vendemos (como definido pela CCPA) as Informações Pessoais que coletamos (e não as venderemos sem permitir que você opte pela não participação do ato).</li>
        <li>Sujeito a certas limitações, a CCPA garante aos residentes da Califórnia os seguintes direitos:</li>
        <ol>
          <li>Você tem o direito de solicitar que divulguemos as Informações Pessoais que coletamos, usamos ou divulgamos sobre nossas práticas de dados;</li>
          <li>Você tem o direito de solicitar a exclusão de suas Informações Pessoais coletadas;</li>
          <li>Você tem o direito de solicitar que não vendamos mais suas Informações Pessoais a terceiros;</li>
          <li>Não vamos discriminá-lo por exercer nenhum desses direitos.</li>
        </ol>
        <li>Moradores da Califórnia podem fazer uma solicitação de acordo com seu “direito de saber“, “direito de solicitar exclusão“ e/ou “direito de cancelamento“ no âmbito do CCPA, entrando em contato conosco de acordo com a seção “Fale conosco“ abaixo. Para verificar sua solicitação de saber ou excluir informações, podemos exigir que você nos forneça certas informações que são usadas exclusivamente com o propósito de verificar sua identidade.</li>
        <li>De acordo com a CCPA, você mesmo pode exercer esses direitos ou designar um agente autorizado para fazer essas solicitações em seu nome. Podemos solicitar que seu agente autorizado tenha permissão por escrito para fazer solicitações em seu nome e talvez precisemos verificar a identidade do seu agente autorizado.</li>
      </ol>
    </div>

    <div class='information-retention'>
      <li class='list-title'>
        <h3>Retenção de informações</h3>
      </li>
      <p>Reteremos informações necessárias para atender às solicitações de privacidade e gerir contas ativas, conforme exigido por lei, de modo a resolver disputas ou fazer cumprir nossos acordos. Vamos reter informações pessoais que processamos em nome de nossos clientes conforme orientado por eles. Também podem ser retidas cópias de suas informações para fins de recuperação de desastres, evitar fraudes ou abusos futuros e para fins comerciais legítimos, como análise de Informações Agregadas/Identificadas, recuperação de contas, auditoria de nossos registros.</p>
    </div>

    <div class='data-processing'>
      <li class='list-title'>
        <h3>Processamento e Transferência de dados</h3>
      </li>
      <p>Ao acessar, utilizar os Serviços ou fornecer informações para nós, você concorda com o processamento e transferência de informações dentro e fora dos EUA e outros países e territórios que possam ter leis de privacidade diferentes do seu país de residência. Sempre que compartilhamos informações de usuários da União Europeia fora da União Europeia, nos certificamos de que haja um mecanismo de transferência adequado.</p>
    </div>
    <div class='security'>
      <li class='list-title'>
        <h3>Segurança</h3>
      </li>
      <p>Nós implementamos uma variedade de medidas administrativas, técnicas e de segurança física para proteger suas informações contra o acesso, destruição ou alteração não autorizados. Essas ações variam de acordo com a sensibilidade das informações que coletamos, processamos e armazenamos e o estado atual da tecnologia. Embora tomemos as medidas apropriadas para proteger as informações contra divulgações não autorizadas, não podemos garantir que elas nunca sejam divulgadas de forma inconsistente com esta Política de Privacidade.</p>
    </div>
    <div class='third-party-websites'>
      <li class='list-title'>
        <h3>Links para sites de terceiros</h3>
      </li>
      <p>Os Serviços podem fornecer a capacidade de se conectar a outros sites. Esses sites podem operar independentemente de nós e podem ter seus próprios avisos ou políticas de privacidade, que sugerimos fortemente que você leia. Se qualquer site vinculado não é de propriedade ou controlado por nós, então não somos responsáveis por seu conteúdo, uso de informações e práticas externas.</p>
    </div>
    <div class='children-privacy'>
      <li class='list-title'>
        <h3>Privacidade Infantil</h3>
      </li>
      <p>Nossos Serviços são direcionados a maiores de treze (13) anos. Não coletamos conscientemente informações pessoais de indivíduos menores de treze (13) (“Crianças“). Caso tomemos conhecimento de quaisquer crianças que nos forneceram informações pessoais, desativaremos sua conta e excluiremos as informações pessoais. Caso tome conhecimento das informações de crianças sendo fornecidas a nós, entre em contato conosco de acordo com a seção “Fale Conosco“ abaixo.</p>
    </div>
    <div class='contact-us'>
      <li class='list-title'>
        <h3>Fale conosco</h3>
      </li>
      <p>Em caso de dúvidas ou preocupações sobre nossa Política de Privacidade, entre em contato conosco por <a
          href='support@inbrain.ai'>support@inbrain.ai</a>.</p>
    </div>
  </ol>
    `,
};
